import React, { useEffect } from 'react';
import { Layout, Menu, Popover, Button, Row, Avatar } from 'antd';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import styles from './header.module.scss';
import LeftSlider from '../LeftSlider';
import SynchronizeModal from '../SynchronizeModal';
import { leftMenuDrawerStore } from '../../stores';
import { LEFT_MENU_TYPES } from '../../constants';
import { loginStore, userStore } from '../../../authentication/stores';
import { storage } from '../../utils';
import { PAYLOAD_NAME, STORAGE_TYPE } from '../../../authentication/constants';
import RefreshKiosksModal from '../RefreshKiosksModal';
import ChangeMenuOfLocationSelect from '../ChangeMenuOfLocationSelect';
import { pitstopStore } from '../../../pitstop/stores';

const popoverCreate = (
    <div className={styles.popoverItem}>
        <div>
            <Link
                to="/dishes/create"
                onClick={() => leftMenuDrawerStore.setMenuType(null)}
            >
                <Button className={styles.popoverButton} type="primary">
                    страву
                </Button>
            </Link>
        </div>
        <div>
            <Link
                to="/categories/create"
                onClick={() => leftMenuDrawerStore.setMenuType(null)}
            >
                <Button className={styles.popoverButton} type="primary">
                    категорію
                </Button>
            </Link>
        </div>
    </div>
);

const Header = () => {
    useEffect(() => {
        userStore.getUser(Number(storage(STORAGE_TYPE).get(PAYLOAD_NAME)));
    }, []);

    useEffect(() => {
        if (userStore.user && userStore.user.organizationId) {
            pitstopStore.load(userStore.user && userStore.user.organizationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.user && userStore.user.organizationId]);
    return (
        <div className={styles.header}>
            <Layout.Header className={styles.header}>
                <Row type="flex" justify="space-between">
                    <Menu mode="horizontal" className={styles.menu}>
                        <Menu.Item
                            key="1"
                            onClick={() =>
                                leftMenuDrawerStore.setMenuType(
                                    LEFT_MENU_TYPES.Menu
                                )
                            }
                        >
                            <Link to="/">
                                <span className={styles.linkName}>Меню</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="2"
                            onClick={() =>
                                leftMenuDrawerStore.setMenuType(
                                    LEFT_MENU_TYPES.View
                                )
                            }
                        >
                            <Link to="/">
                                <span className={styles.linkName}>Вигляд</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="3">
                            <Popover
                                placement="bottom"
                                trigger="click"
                                content={popoverCreate}
                            >
                                <Link to="/">
                                    <span className={styles.linkName}>
                                        Створити
                                    </span>
                                </Link>
                            </Popover>
                        </Menu.Item>
                        <Menu.Item key="4">
                            <Link
                                to="/statistic"
                                onClick={() =>
                                    leftMenuDrawerStore.setMenuType(null)
                                }
                            >
                                <span className={styles.linkName}>
                                    Статистика
                                </span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="5"
                            onClick={() =>
                                leftMenuDrawerStore.setMenuType(null)
                            }
                        >
                            <Link to="/feedback">
                                <span className={styles.linkName}>Відгуки</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="6"
                            onClick={() =>
                                leftMenuDrawerStore.setMenuType(null)
                            }
                        >
                            <Link to="/pitstop">
                                <span className={styles.linkName}>
                                    Вигляд Pitstop
                                </span>
                            </Link>
                        </Menu.Item>
                    </Menu>
                    <div className={styles.locationSelectWrap}>
                        <ChangeMenuOfLocationSelect
                            loading={userStore.loading}
                            options={
                                (userStore.user && userStore.user.locations) ||
                                []
                            }
                            organizationId={userStore.user?.organizationId}
                        />
                    </div>
                    <Row
                        className={styles.rightMenu}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <RefreshKiosksModal />
                        <SynchronizeModal />
                        <Popover
                            placement="bottomRight"
                            trigger="click"
                            content={
                                <div>
                                    <div>
                                        <b>Користувач:</b>{' '}
                                        {userStore.user &&
                                            userStore.user.fullName}
                                    </div>
                                    <div>
                                        <b>E-mail:</b>{' '}
                                        {userStore.user && userStore.user.email}
                                    </div>
                                    <Button
                                        className={styles.popoverButton}
                                        onClick={() => {
                                            loginStore.logout(
                                                Number(
                                                    storage(STORAGE_TYPE).get(
                                                        PAYLOAD_NAME
                                                    )
                                                )
                                            );
                                        }}
                                        type="primary"
                                    >
                                        Вийти
                                    </Button>
                                </div>
                            }
                        >
                            <Avatar
                                size={44}
                                icon="user"
                                className={styles.userAvatar}
                            />
                        </Popover>
                    </Row>
                </Row>

                <div>
                    <LeftSlider />
                </div>
            </Layout.Header>
        </div>
    );
};

export default observer(Header);
