import React, { useState } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

import Storage from '../../utils/Storage';

import styles from './changeMenuOfLocationSelect.module.scss';
import { InputFieldTitle } from '..';
import { Location } from '../../../authentication/types';

type SelectFieldProps = {
  title?: string;
  defaultValue?: string;
  wrapperStyle?: React.CSSProperties;
  options: Location[];
  organizationId?:number | null;
  errorMessage?: string | boolean;
  loading?: boolean;
  required?: boolean;
} & SelectProps;

const ChangeMenuOfLocationSelect = ({
  title,
  defaultValue,
  required,
  errorMessage,
  options,
  organizationId,
  loading,
  wrapperStyle,
  ...props
}: SelectFieldProps) => {
  const [selectedCity, setSelectedCity] = useState('Всі міста');
  const cities: string[] = ['Всі міста'];
  let renderOptions =  selectedCity !== 'Всі міста' ? options.filter(opt=>opt.address===selectedCity) : options;
  
  options.map(location=>{
    if(!cities.includes(location.address)){
      cities.push(location.address)
    }
  });

  const filterOptions = (input: string, option: any) => {
    let value = input.trim().toLowerCase();
    const optionValue = option.props.children.props.children.toLowerCase();
    return optionValue.indexOf(value) >= 0 ? true : false;
  }

  return (
    <>
    <InputFieldTitle required={required} title={title || ''} errorMessage={errorMessage} style={wrapperStyle}>
      <Select
        {...props}
        size={'small'}
        className={styles.selectShort}
        loading={loading}
        defaultValue={selectedCity}
      >
        {cities.map((option: string) =>
          option ? (
            <Select.Option
              key={option}
              value={option}
              title={option}
              className={styles.option}
            >
              <div
                className={styles.optionName}
                onClick={() => setSelectedCity(option)}
              >
                {option}
              </div>
            </Select.Option>
          ) : null
        )}
      </Select>    
      <Select
        {...props}
        size={'small'}
        className={styles.select}
        loading={loading}
        defaultValue={Storage('sessionStorage').get('menu').name || ''}
        showSearch
        filterOption={filterOptions}
      >
        {renderOptions.map((option: Location) =>
          option ? (
            <Select.Option
              key={option.name + option.id}
              value={option.id}
              title={option.name}
              className={styles.option}
            >
              <div
                className={styles.optionName}
                onClick={() => {
                  Storage('sessionStorage').set('menu', {
                    id: option.menuId,
                    locationId: option.id,
                    organizationId: organizationId,
                    name: option.name                  
                });
              }}
              >
                {option.name}
              </div>
            </Select.Option>
          ) : null
        )}
      </Select>
    </InputFieldTitle>
    </>
  );
};

export default ChangeMenuOfLocationSelect;
