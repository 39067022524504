import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import Storage from '../../../common/utils/Storage';
import { InputField } from '../../../common/components';
import { UpdateCountdown } from '../../types';
import { screenStore } from '../../stores';
import MenuLang from '../MenuLang';

import styles from './countdownForm.module.scss';
import SharedBgImage from '../SharedBgImage';
// import OptionButton from '../../../common/components/OptionsButton';

const CountdownForm = (props: FormikProps<UpdateCountdown>) => {
  const [activeLang, setActiveLang] = useState(String(Storage('sessionStorage').get('language')).toLowerCase());
  const { handleSubmit, setFieldTouched, touched, errors, setFieldValue, values } = props;

  const handleLangChange = (language: string) => {
    setActiveLang(language.toLowerCase());
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Відлік</h3>
      <div className={styles.wrap}>
        <div className={styles.fieldsWrap}>
          <InputField
            title={'Повідомлення'}
            placeholder={'Ви ще тут?'}
            name="message"
            value={values.message[activeLang]}
            onBlur={() => setFieldTouched(`message.${activeLang}`)}
            errorMessage={
              touched.message && touched.message[activeLang] && errors.message && errors.message[activeLang]
            }
            onChange={event => {
              setFieldValue(`message.${activeLang}`, event.target.value);
              setFieldTouched(`message.${activeLang}`);
            }}
          />
          <InputField
            title={'Кнопка (Так!)'}
            placeholder={'Так!'}
            name="button"
            value={values.button[activeLang]}
            onBlur={() => setFieldTouched(`button.${activeLang}`)}
            errorMessage={touched.button && touched.button[activeLang] && errors.button && errors.button[activeLang]}
            onChange={event => {
              setFieldValue(`button.${activeLang}`, event.target.value);
              setFieldTouched(`button.${activeLang}`);
            }}
          />
          <SharedBgImage screenType="countdown" />
        </div>
        <div className={styles.buttonsWrap}>
          <div className={styles.wrapTopButtons}>
            <MenuLang onChange={handleLangChange} />
            {/* TODO:here must be OptionButton */}
            {/* <OptionButton /> */}
          </div>
          <div className={styles.wrapBottomButtons}>
            <div />
            <Button className={styles.saveButton} type="primary" htmlType="submit">
              Зберегти
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const CountdownFormWithFormik = withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    imageUrl:
      (screenStore.screens && screenStore.screens.countdown && screenStore.screens.countdown.background?.imageUrl) ||
      null,
    message: {
      en: screenStore.screens && screenStore.screens.countdown && screenStore.screens.countdown.message.en,
      pl: screenStore.screens && screenStore.screens.countdown && screenStore.screens.countdown.message.pl,
      ru: screenStore.screens && screenStore.screens.countdown && screenStore.screens.countdown.message.ru,
      uk: screenStore.screens && screenStore.screens.countdown && screenStore.screens.countdown.message.uk
    },
    button: {
      en: screenStore.screens && screenStore.screens.countdown && screenStore.screens.countdown.button.en,
      pl: screenStore.screens && screenStore.screens.countdown && screenStore.screens.countdown.button.pl,
      ru: screenStore.screens && screenStore.screens.countdown && screenStore.screens.countdown.button.ru,
      uk: screenStore.screens && screenStore.screens.countdown && screenStore.screens.countdown.button.uk
    }
  }),
  handleSubmit: ({ message, button, imageUrl }) => {
    screenStore.update(
      {
        message: {
          en: message.en,
          pl: message.pl,
          ru: message.ru,
          uk: message.uk
        },
        button: {
          en: button.en,
          pl: button.pl,
          ru: button.ru,
          uk: button.uk
        },
        background: {
          imageUrl
        }
      },
      'countdown'
    );
  },
  validationSchema: yup.object().shape<any>({
    message: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    button: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    })
  }),
  displayName: 'CountdownForm'
})(observer(CountdownForm));

export default CountdownFormWithFormik;
