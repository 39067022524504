import React, { ReactNode } from 'react';
import { Popover, Icon } from 'antd';

import styles from './optionsButton.module.scss';

interface OptionButtonProps {
  content?: ReactNode;
  style?: string;
}

const OptionButton = ({ content, style }: OptionButtonProps) => {
  return (
    <Popover placement="bottomRight" trigger="click" content={content} className={style}>
      <div className={styles.button}>
        <Icon type="ellipsis" />
      </div>
    </Popover>
  );
};

export default OptionButton;
