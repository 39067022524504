import React, { useState } from 'react';
import { Icon, Button } from 'antd';

import OptionsButton from '../../../common/components/OptionsButton';
import OptionButtonMenu from '../../../common/components/OptionsButtonMenu';
import history from '../../../../history';

import AddArchiveData from '../../../common/components/AddArchiveData';
import styles from './dishOptions.module.scss';
import { dishStore } from '../../../dish/stores';
import { categoryStore } from '../../stores';

const DishOptions = ({ dish }: any) => {
  const [topSeller, setTopSeller] = useState(dish.topSeller);

  return (
    <OptionsButton
      content={
        <div className={styles.optionsMenuWrap}>
          <Button
            className={styles.optionsMenuButton}
            type="primary"
            onClick={() => history.push(`/dishes/edit/${dish.id}`)}
          >
            перейти
          </Button>
          <Button
            className={styles.optionsMenuButton}
            type="primary"
            onClick={() => {
              dish.topSeller = !dish.topSeller;
              setTopSeller(dish.topSeller);
              dishStore.updateWithoutRedirect(dish.id, dish);
            }}
          >
            {topSeller ? 'видалити з обраних' : 'до обраних'}
          </Button>
          {dish && !dishStore.stopList?.includes(dish.id) ? (
            <OptionButtonMenu
              name="екстрена зупинка"
              placement="left"
              trigger="hover"
              icon={<Icon className={styles.icon} type="caret-left" />}
              content={
                <div className={styles.arhivationWrap}>
                  <Button
                    className={styles.optionsMenuButton}
                    type="primary"
                    onClick={() => dishStore.addToArhiv(Date.now(), 0, dish)}
                  >
                    По всім локаціям
                  </Button>
                  <Button
                    className={styles.optionsMenuButton}
                    type="primary"
                    onClick={() => dishStore.addToStopList(dish)}
                  >
                    В Стоп-лист локації
                  </Button>
                  {/* <AddArchiveData title={'В Стоп-лист локації'} dish={dishStore.dish} type="dish" /> */}
                </div>
              }
            />
          ) : (
            <Button
              className={styles.optionsMenuButton}
              type="primary"
              onClick={() => dishStore.removeFromStopList(dish)}
            >
              Відновити зі стоп-ліста
            </Button>
          )}
          {!dish.archived ? (
            <div>
              {/* <Button
                className={styles.optionsMenuButton}
                type="primary"
                onClick={() => dishStore.addToArhiv(Date.now(), 0, dish)}
              >
                екстрена зупинка
              </Button> */}
              <OptionButtonMenu
                name="архівувати"
                placement="left"
                trigger="hover"
                icon={<Icon className={styles.icon} type="caret-left" />}
                content={
                  <div className={styles.arhivationWrap}>
                    <AddArchiveData title={'З вибраної дати'} type="dish" dish={dish} onlyFrom={true} />
                    <AddArchiveData title={'На вибраний період часу'} dish={dish} type="dish" />
                  </div>
                }
              />
            </div>
          ) : (
            <Button
              className={styles.optionsMenuButton}
              type="primary"
              onClick={() => dishStore.extractFromArchive(dish)}
            >
              Відновити
            </Button>
          )}
          <div className={styles.moveControl}>
            <Button
              className={styles.controlButton}
              type="primary"
              onClick={() => categoryStore.changeDishOrder('UP', dish)}
            >
              <Icon type="caret-up" />
            </Button>
            <Button
              className={styles.controlButton}
              type="primary"
              onClick={() => categoryStore.changeDishOrder('DOWN', dish)}
            >
              <Icon type="caret-down" />
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default DishOptions;
