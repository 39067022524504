import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

import styles from './selectField.module.scss';
import { InputFieldTitle } from '../index';

type SelectFieldProps = {
  title?: string;
  defaultValue?: string;
  wrapperStyle?: React.CSSProperties;
  options: Array<{ id?: string | number; name: string; description?: string }>;
  errorMessage?: string | boolean;
  loading?: boolean;
  required?: boolean;
  size?: string;
} & SelectProps;

const SelectField = ({
  title,
  defaultValue,
  required,
  errorMessage,
  options,
  loading,
  wrapperStyle,
  size,
  ...props
}: SelectFieldProps) => {
  return (
    <InputFieldTitle required={required} title={title || ''} errorMessage={errorMessage} style={wrapperStyle}>
      <Select
        {...props}
        size={size || 'large'}
        className={styles.select}
        loading={loading}
        defaultValue={defaultValue ? defaultValue : '+ Додати'}
      >
        {options.map(option => (
          <Select.Option
            key={option && option.name + option && option.id}
            value={option && option.id}
            title={option && option.description}
          >
            {option && option.name}
          </Select.Option>
        ))}
      </Select>
    </InputFieldTitle>
  );
};

export default SelectField;
