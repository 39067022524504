import React, { useEffect } from 'react';
import { Checkbox, Icon } from 'antd';
import { observer } from 'mobx-react-lite';

import styles from './showCategoryList.module.scss';
import DishOptions from '../DishOptions';
import { Category, ShowCategoryListProps } from '../../types';
import { dishStore } from '../../../dish/stores';

const ShowCategoryList = ({ data }: ShowCategoryListProps) => {
  useEffect(() => {
    if (!dishStore.stopList) {
      dishStore.loadStopList();
    }
  }, [data]);
  const createEditMenu = (item: Category) => {
    if (item.menuItems || item.menuItems === null) {
      return (
        <div className={styles.subSubcategory} key={item.id}>
          <div className={styles.subSubCategoryTitle}>
            <Icon className={styles.subSubCategoryIcon} type="pushpin" /> {item.name}
          </div>
          {item.menuItems !== null
            ? item.menuItems.map((value: any) => {
                return createEditMenu(value);
              })
            : null}
        </div>
      );
    } else {
      return (
        <div key={item.id} className={styles.checkDish}>
          <Checkbox>{item.name}</Checkbox>
          <div className={styles.checkDishTitle}>
            <div>{item.archived ? '[архівовано]' : null}</div>
            <div>{dishStore.stopList?.includes(item.id) ? '[в стоп-лісті]' : null}</div>
            <div className={styles.dishButton}>{<DishOptions dish={item} />}</div>
          </div>
        </div>
      );
    }
  };
  return <div className={styles.editWindowWrap}>{data ? createEditMenu(data) : null}</div>;
};

export default observer(ShowCategoryList);
