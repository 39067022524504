import React, { useState } from 'react';
import { Radio } from 'antd';

import Storage from '../../../common/utils/Storage';
import styles from './menuLang.module.scss';

const LANGUAGES = ['UK', 'EN', 'PL', 'RU'];

interface LanguagePanelProps {
  onChange?: (language: string) => void;
}

const LanguagePanel = ({ onChange }: LanguagePanelProps) => {
  const [lang, setLanguage] = useState(Storage('sessionStorage').get('language'));

  const handleChange = (event: any) => {
    const language = event.target.value;
    Storage('sessionStorage').set('language', language);

    if (onChange) {
      onChange(language);
    }
    setLanguage(language);
  };

  return (
    <div className={styles.langMenu}>
      <Radio.Group size="small" value={lang} onChange={handleChange}>
        {LANGUAGES.map((value, index) => (
          <Radio.Button value={value} key={index}>
            {value}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default LanguagePanel;
