import { notification, message } from 'antd';
import axios from 'axios';

import settings from '../../../settings';
import { TokenPayload, User } from '../types';

class LoginService {
  public login = async (values: {
    email: string;
    password: string;
  }): Promise<{ user: User; userToken: TokenPayload } | null> => {
    try {
      const { data } = await axios.post(
        `${settings.domain}/api/admin/user/login?email=${values.email}&password=${values.password}`
      );
      if (data.message === 'Password is wrong for user: donskoymihail@gmail.com') {
        message.error('Не вірний email або пароль!');
        return null;
      } else {
        return data.data;
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return null;
      }
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося залогінитися. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public logout = async (userId: number) => {
    try {
      const { data } = await axios.post(`${settings.domain}/api/admin/user/logout/${userId}`);
      return data.data;
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return null;
      }
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося разлогінитися. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new LoginService();
