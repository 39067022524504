import * as React from 'react';

import styles from './title.module.scss';

interface TitleProps {
  title: string;
}

const Title = ({ title, ...props }: TitleProps & React.HTMLProps<HTMLDivElement>) => {
  return (
    <div {...props} className={styles.titleWrapper}>
      <h2 className={styles.title}>{title}</h2>
    </div>
  );
};

export default Title;
