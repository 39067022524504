import React, { useEffect } from 'react';
import { Button, Form } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';

import { dishStore } from '../../stores';
import { categoryStore } from '../../../category/stores';

import SelectField from '../../../common/components/SelectField';
import MenuLang from '../../../common/components/MenuLang';
import InputField from '../../../common/components/InputField';
import OptionButton from '../../../common/components/OptionsButton';
import { UpdateDishValues } from '../../types';

import styles from './create.module.scss';
import './create.global.scss';

const Create = (props: FormikProps<UpdateDishValues>) => {
  useEffect(() => {
    // categoryStore.loadListWithSubcategories();
    categoryStore.loadList();
  }, []);
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <div className="create-dish-wrap">
        <div className={styles.wrapTopField}>
          <div className={styles.wrapWithoutImg}>
            <div className={styles.wrapLangMenu}>
              <div className={styles.langMenu}>
                <MenuLang />
                <OptionButton />
              </div>
              <div className={styles.buttonWrap}>
                <Button type="primary" htmlType="submit">
                  Зберегти
                </Button>
              </div>
            </div>
            <InputField
              title={'Назва страви'}
              placeholder={'Мінімум 2, максимум 60 символів'}
              name="name"
              defaultValue={''}
              onBlur={() => setFieldTouched('name')}
              errorMessage={touched.name && errors.name}
              onChange={event => setFieldValue('name', event.target.value)}
            />
            <InputField
              title={'Опис страви'}
              placeholder={'Мінімум 2, максимум 500 символів'}
              name="description"
              defaultValue={''}
              onBlur={() => setFieldTouched('description')}
              errorMessage={touched.description && errors.description}
              onChange={event => setFieldValue('description', event.target.value)}
            />
            <InputField
              title={'Вартість страви'}
              placeholder={'Вартість'}
              name="price"
              defaultValue={''}
              onBlur={() => setFieldTouched('price')}
              errorMessage={touched.price && errors.price}
              onChange={event => setFieldValue('price', event.target.value)}
            />
          </div>
        </div>
        <SelectField
          title={'Додати до категорії'}
          loading={categoryStore.loading}
          options={categoryStore.categories ? categoryStore.categories : []}
          onBlur={() => setFieldTouched('categoryId')}
          errorMessage={touched.categoryId && errors.categoryId}
          value={values.categoryId ? values.categoryId : ' + Додати '}
          onChange={value => {
            setFieldValue('categoryId', value);
          }}
          wrapperStyle={{ margin: '16px auto 0 auto', width: '98%' }}
        />
      </div>
    </Form>
  );
};

const CreateWithFormik = withFormik<any, UpdateDishValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: '',
    description: '',
    price: 0,
    categoryId: null
  }),
  handleSubmit: (values, { resetForm }) => {
    dishStore.create(values);
    resetForm();
  },
  validationSchema: yup.object().shape<UpdateDishValues>({
    name: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(60, 'Назва повинна мати не більше 60 символів')
      .required('Це поле не може бути порожнім!'),
    description: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(500, 'Назва повинна мати не більше 500 символів')
      .required('Це поле не може бути порожнім!'),
    categoryId: yup
      .number()
      .nullable()
      .required('Це поле не може бути порожнім!')
  }),
  displayName: 'Create'
})(observer(Create));

export default CreateWithFormik;
