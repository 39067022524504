import * as React from 'react';
import { Route } from 'react-router-dom';

import Login from './pages/Login';
import Module from '../Connector';

export * from './accessComponents';

export default new Module({
  route: [<Route exact={true} path="/login" component={Login} />]
});
