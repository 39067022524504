import { observable, action, runInAction } from 'mobx';

import { Screens } from '../types';
import { screensServices } from '../services';

class ScreenStore {
  @observable
  public screens: Screens | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action
  public async load() {
    this.loading = true;

    try {
      const screens = await screensServices.load();
      runInAction(() => {
        this.screens = screens;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async update(updateValue: any, screen: string) {
    this.loading = true;

    try {
      const screens = await screensServices.update({ ...this.screens, [screen]: updateValue });
      runInAction(() => {
        this.screens = screens;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new ScreenStore();
