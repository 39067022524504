import { observable, action, runInAction } from 'mobx';

import { Organization } from '../types';
import { pitstopService } from '../services';

class PitstopStore {
    @observable
    public pitstop: Organization | '' = '';
    @observable
    public loading = true;
    @observable
    public error: Error | null = null;

    @action
    public async load(organizationId: any) {
        this.loading = true;
        try {
            const pitstop = await pitstopService.load(organizationId);
            runInAction(() => {
                this.pitstop = pitstop;
            });
        } catch (error) {
            runInAction(() => {
                this.error = error;
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    // @action
    // public async update(updateValue: any) {
    //     this.loading = true;

    //     try {
    //         const pitstop = await pitstopService.update({
    //             ...this.pitstop,
    //             ...updateValue,
    //         });
    //         runInAction(() => {
    //             this.pitstop = pitstop;
    //         });
    //     } catch (error) {
    //         runInAction(() => {
    //             this.error = error;
    //         });
    //     } finally {
    //         runInAction(() => {
    //             this.loading = false;
    //         });
    //     }
    // }
}

export default new PitstopStore();
