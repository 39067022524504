import * as React from 'react';

import PageArea from '../../components/PageArea';
import GetOrderCashierForm from '../../components/GetOrderCashierForm';

const GetOrderСashierPage = () => {
  return (
    <PageArea
      content={<GetOrderCashierForm />}
      pageName="Отримання замовленя на касі"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/сashier-order' },
        { name: 'Отримання замовленя на касі', url: '/screens/сashier-order' }
      ]}
    />
  );
};

export default GetOrderСashierPage;
