import * as React from 'react';

import PageArea from '../../components/PageArea';
import WhereEatForm from '../../components/WhereEatForm';

const WhereEatPage = () => {
  return (
    <PageArea
      content={<WhereEatForm />}
      pageName="Екран Де поїсти?"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/where-eat' },
        { name: 'Де поїсти?', url: '/screens/where-eat' }
      ]}
    />
  );
};

export default WhereEatPage;
