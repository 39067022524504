import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';

import settings from '../../../../settings';
import { AppLayout } from '../../../common/components';

import styles from './feedbackPage.module.scss';
import Pitstop from '../../components/Pitstop';

const PitstopPage = () => {
    return (
        <AppLayout>
            <Helmet>
                <title>{`${settings.appName} - Дані для Pitstop`}</title>
                <meta
                    name="description"
                    content={`${settings.appName} - Дані для Pitstop`}
                />
            </Helmet>
            <div className={styles.pageWrapper}>
                <div className={styles.wrap}>
                    <Pitstop />
                </div>
            </div>
        </AppLayout>
    );
};

export default observer(PitstopPage);
