import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Icon } from 'antd';

import styles from './feedbackList.module.scss';
import Title from '../../../common/components/Title';
import { feedbackStore } from '../../stores';

const StatisticList = () => {
  return (
    <div className={styles.wrap}>
      <Title
        title={`Всього оцінок: ${
          feedbackStore.feedback
            ? feedbackStore.feedback.one +
              feedbackStore.feedback.two +
              feedbackStore.feedback.three +
              feedbackStore.feedback.four +
              feedbackStore.feedback.five
            : 0
        }`}
      />
      <div className={styles.ratingWrap}>
        <div>
          <Icon type="star" />
          <Icon type="star" />
          <Icon type="star" />
          <Icon type="star" />
          <Icon type="star" />
          <b> - {feedbackStore.feedback ? feedbackStore.feedback.five : 0}</b>
        </div>
        <div>
          <Icon type="star" />
          <Icon type="star" />
          <Icon type="star" />
          <Icon type="star" />
          <b> - {feedbackStore.feedback ? feedbackStore.feedback.four : 0}</b>
        </div>
        <div>
          <Icon type="star" />
          <Icon type="star" />
          <Icon type="star" />
          <b> - {feedbackStore.feedback ? feedbackStore.feedback.three : 0}</b>
        </div>
        <div>
          <Icon type="star" />
          <Icon type="star" />
          <b> - {feedbackStore.feedback ? feedbackStore.feedback.two : 0}</b>
        </div>
        <div>
          <Icon type="star" />
          <b> - {feedbackStore.feedback ? feedbackStore.feedback.one : 0}</b>
        </div>
      </div>
    </div>
  );
};

export default observer(StatisticList);
