import * as React from 'react';

import PageArea from '../../components/PageArea';
import FeedbackThankForm from '../../components/FeedbackThankForm';

const FeedbackThankPage = () => {
  return (
    <PageArea
      content={<FeedbackThankForm />}
      pageName="Екран подяки та відгуку"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/feedback-thanks' },
        { name: 'Подяка та відгук', url: '/screens/feedback-thanks' }
      ]}
    />
  );
};

export default FeedbackThankPage;
