import * as React from 'react';

import PageArea from '../../components/PageArea';
import Create from '../../components/Create';

const DishPage = () => {
  return (
    <PageArea
      content={<Create />}
      pageName="Створення нової страви"
      breadLinks={[{ name: 'Меню', url: '/' }, { name: '# Створити страву', url: '/dishes/create' }]}
    />
  );
};

export default DishPage;
