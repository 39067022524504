import authentication from './authentication';
import notFoundPage from './notFoundPage';
import dish from './dish';
import category from './category';
import menu from './menu';
import statistic from './statistic';
import response from './feedback';
import pitstop from './pitstop';
import screens from './screens';

import Module from './Connector';

export default new Module(
    authentication,
    menu,
    category,
    dish,
    screens,
    statistic,
    response,
    pitstop,
    notFoundPage
);
