import * as React from 'react';
import { merge, map, union, without, castArray } from 'lodash';

export const combine = <Module>(modules: Module[], extractor: (x: Module) => any): any[] =>
  without(union(...map(modules, res => castArray(extractor(res)))), undefined);

// TODO: add types
interface ModuleShape {
  // localization?: any | any[];
  reducer?: any | any[];
  dataRootComponent?: React.ComponentType | React.ComponentType[];
  data?: any | any[];
  route?: React.ReactElement<any> | Array<React.ReactElement<any>>;
  navItem?: React.ReactElement<any> | Array<React.ReactElement<any>>;
}

export default class {
  // public localization: any[];
  public reducer: any[];
  public dataRootComponent: React.ComponentType[];
  public data: any[];
  public route: Array<React.ReactElement<any>>;
  public navItem: Array<React.ReactElement<any>>;

  constructor(...modules: ModuleShape[]) {
    // // Localization
    // this.localization = combine(modules, arg => arg.localization);

    // State management
    this.reducer = combine(modules, arg => arg.reducer);

    // UI provider-components
    this.dataRootComponent = combine(modules, arg => arg.dataRootComponent);

    this.route = combine(modules, arg => arg.route);
    this.navItem = combine(modules, arg => arg.navItem);

    // Shared modules data
    const empty: ModuleShape = {};
    this.data = combine([empty].concat(Array.from(modules)), arg => arg.data).reduce(
      (acc, el) => [{ ...acc[0], ...el }],
      [{}]
    );
  }

  // get localizations() {
  //   return this.localization;
  // }

  get reducers() {
    return merge({}, ...this.reducer);
  }

  public getDataRoot(root: React.ReactElement<any>) {
    let nestedRoot = root;
    for (const component of this.dataRootComponent) {
      nestedRoot = React.createElement(component, {}, nestedRoot);
    }
    return nestedRoot;
  }

  get routes() {
    return this.route.map((component: React.ReactElement<any>, idx: number) =>
      React.cloneElement(component, { key: idx + this.route.length })
    );
  }

  // get navItems() {
  //   return this.navItem.map((component: React.ReactElement<any>, idx: number) =>
  //     React.cloneElement(component, {
  //       key: component.key ? component.key : idx + this.navItem.length
  //     })
  //   );
  // }
}
