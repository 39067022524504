import React from 'react';
import { Icon, Button } from 'antd';
import { observer } from 'mobx-react-lite';

import AddArchiveData from '../../../common/components/AddArchiveData';
import OptionsButton from '../../../common/components/OptionsButton';
import OptionButtonMenu from '../../../common/components/OptionsButtonMenu';

import styles from './categoryOptions.module.scss';
import { categoryStore } from '../../stores';

const CategoryOptions = () => {
  return (
    <div>
      <OptionsButton
        style={styles.optionsButton}
        content={
          <div className={styles.optionsMenuWrap}>
            <Button className={styles.optionsMenuButton} type="primary">
              сінхронізувати
            </Button>
            {categoryStore.category && !categoryStore.category.archived ? (
              <div>
                <Button
                  className={styles.optionsMenuButton}
                  type="primary"
                  onClick={() => categoryStore.addToArhiv(Date.now(), 0)}
                >
                  екстрена зупинка
                </Button>
                <OptionButtonMenu
                  name="архівувати"
                  placement="left"
                  trigger="hover"
                  icon={<Icon className={styles.icon} type="caret-left" />}
                  content={
                    <div className={styles.arhivationWrap}>
                      <AddArchiveData title={'З вибраної дати'} type="category" onlyFrom={true} />
                      <AddArchiveData title={'На вибраний період часу'} type="category" />
                    </div>
                  }
                />
              </div>
            ) : (
              <Button
                className={styles.optionsMenuButton}
                type="primary"
                onClick={() => categoryStore.extractFromArchive()}
              >
                Відновити
              </Button>
            )}
          </div>
        }
      />
    </div>
  );
};

export default observer(CategoryOptions);
