import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Radio } from 'antd';

import styles from '../StatisticCharts/StatisticCharts.module.scss';
import { statisticStore } from '../../stores';
import { deliveryTypeList, paymentTypeList, systemTypeList } from '../../types';
import { IntervalChartBizcharts } from '../StatisticChartsCommon/IntervalChartBizcharts';
import { prepareLegendTitles, prepareLists } from '../../services/ChartsPrepare';

const StatisticChartsGeneral = () => {
  const [chartType, setChartType] = useState('pricePercent');
  const [isSystemTypes, setIsSystemTypes] = useState(false);
  const [isPaymentTypes, setIsPaymentTypes] = useState(false);
  const [isDeliveryTypes, setIsDeliveryTypes] = useState(false);

  const systemTypeChart = prepareLists(systemTypeList);
  const paymentTypeChart = prepareLists(paymentTypeList);
  const deliveryTypeChart = prepareLists(deliveryTypeList);

  const config = {
    height: 300,
    width: 300,
    radius: 0.6
  };
  let system = [];
  let payment = [];
  let delivery = [];

  useEffect(() => {
    if (statisticStore.loading === false && statisticStore && statisticStore.statistic) {
      setIsSystemTypes(true);
      setIsPaymentTypes(true);
      setIsDeliveryTypes(true);
    }
  }, [statisticStore.loading]);

  const prepareDataForGeneralCharts = () => {
    statisticStore?.statistic?.forEach((order: any) => {
      if (systemTypeChart.find(sysChart => sysChart.key === order.systemType)) {
        systemTypeChart[systemTypeChart.findIndex(sysChart => sysChart.key === order.systemType)].count++;
        systemTypeChart[systemTypeChart.findIndex(sysChart => sysChart.key === order.systemType)].price +=
          order.orderPrice;
      }
      if (paymentTypeChart.find(sysChart => sysChart.key === order.payType)) {
        paymentTypeChart[paymentTypeChart.findIndex(sysChart => sysChart.key === order.payType)].count++;
        paymentTypeChart[paymentTypeChart.findIndex(sysChart => sysChart.key === order.payType)].price +=
          order.orderPrice;
      }
      if (deliveryTypeChart.find(sysChart => sysChart.key === order.deliveryType)) {
        deliveryTypeChart[deliveryTypeChart.findIndex(sysChart => sysChart.key === order.deliveryType)].count++;
        deliveryTypeChart[deliveryTypeChart.findIndex(sysChart => sysChart.key === order.deliveryType)].price +=
          order.orderPrice;
      }
    });
  };

  const renderGeneralCharts = () => {
    prepareDataForGeneralCharts();

    system = prepareLegendTitles(systemTypeChart);
    payment = prepareLegendTitles(paymentTypeChart);
    delivery = prepareLegendTitles(deliveryTypeChart);

    return (
      <section className={styles.oneLineWithThree}>
        {chartType === 'countPercent' ? (
          <section className={styles.oneLineWithThreeSub}>
            {isSystemTypes && (
              <section className={styles.chartWrapper}>
                <h2> Кіоск / Pitstop, од</h2>
                <IntervalChartBizcharts list={system} config={{ ...config, position: 'countPercent' }} />
              </section>
            )}
            {isPaymentTypes && (
              <section className={styles.chartWrapper}>
                <h2>Метод оплати, од</h2>
                <IntervalChartBizcharts list={payment} config={{ ...config, position: 'countPercent' }} />
              </section>
            )}
            {isDeliveryTypes && (
              <section className={styles.chartWrapper}>
                <h2>Метод отримання замовлення, од</h2>
                <IntervalChartBizcharts list={delivery} config={{ ...config, position: 'countPercent' }} />
              </section>
            )}
          </section>
        ) : (
          <section className={styles.oneLineWithThreeSub}>
            {isSystemTypes && (
              <section className={styles.chartWrapper}>
                <h2> Кіоск / Pitstop, ₴</h2>
                <IntervalChartBizcharts
                  list={system}
                  config={{ ...config, position: 'pricePercent', color: 'legendTotalTitle' }}
                />
              </section>
            )}
            {isPaymentTypes && (
              <section className={styles.chartWrapper}>
                <h2>Метод оплати, ₴</h2>
                <IntervalChartBizcharts
                  list={payment}
                  config={{ ...config, position: 'pricePercent', color: 'legendTotalTitle' }}
                />
              </section>
            )}
            {isDeliveryTypes && (
              <section className={styles.chartWrapper}>
                <h2>Метод отримання замовлення, ₴</h2>
                <IntervalChartBizcharts
                  list={delivery}
                  config={{ ...config, position: 'pricePercent', color: 'legendTotalTitle' }}
                />
              </section>
            )}
          </section>
        )}
      </section>
    );
  };

  const handleSizeChange = e => {
    setChartType(e.target.value);
  };
  return (
    <>
      <Radio.Group className={styles.switchButtons} value={chartType} onChange={handleSizeChange}>
        <Radio.Button value="pricePercent">Оборот</Radio.Button>
        <Radio.Button value="countPercent">Kількість чеків</Radio.Button>
      </Radio.Group>
      <br />
      {renderGeneralCharts()}
    </>
  );
};

export default observer(StatisticChartsGeneral);
