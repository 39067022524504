import axios from 'axios';
import { notification } from 'antd';
// import { notification, message } from 'antd';

import { Organization } from '../types';
import settings from '../../../settings';

class PitstopService {
    public load = async (organizationId: any): Promise<Organization> => {
        try {
            const { data } = await axios.get(
                `${settings.domain}/api/admin/organization/${organizationId}`
            );
            return data.data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка',
                description:
                    'Не вдалося завантажити дані для Pitstop. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    // public update = async (updateValue: any) => {
    //     try {
    //         const { data } = await axios.put(
    //             `${settings.domain}/api/screens?locationId=1`,
    //             updateValue
    //         );
    //         message.success('Дані для Pitstop успішно оновлений!');
    //         return data.data;
    //     } catch (error) {
    //         notification.error({
    //             message: 'Серверна помилка',
    //             description:
    //                 'Не вдалося оновити дані для Pitstop. Спробуйте пізніше.',
    //         });
    //     }
    // };
}

export default new PitstopService();
