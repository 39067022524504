import React from 'react';
import { Select, Button, Icon } from 'antd';
import { SelectProps } from 'antd/lib/select';

import styles from './selectField.module.scss';
import { InputFieldTitle } from '../../../common/components';
import { dishStore, ingridientStore } from '../../stores';

type SelectFieldProps = {
  title?: string;
  defaultValue?: string;
  wrapperStyle?: React.CSSProperties;
  options: Array<{ id?: string | number; name: string; description?: string }>;
  errorMessage?: string | boolean;
  loading?: boolean;
  required?: boolean;
} & SelectProps;

const SelectField = ({
  title,
  defaultValue,
  required,
  errorMessage,
  options,
  loading,
  wrapperStyle,
  ...props
}: SelectFieldProps) => {
  return (
    <InputFieldTitle required={required} title={title || ''} errorMessage={errorMessage} style={wrapperStyle}>
      <Select {...props} size={'large'} className={styles.select} loading={loading} value={'+ Додати'}>
        {options.map(option => (
          <Select.Option
            key={option.name + option.id}
            value={option.id}
            title={option.description}
            className={styles.option}
          >
            <div
              className={styles.optionName}
              onClick={() => {
                if (dishStore.dish) {
                  ingridientStore.addToDish(Number(option.id), dishStore.dish);
                }
              }}
            >
              {option.name}
            </div>
            <Button
              size="small"
              className={styles.button}
              onClick={() => {
                if (dishStore.dish && option.id) {
                  ingridientStore.delete(Number(option.id));
                }
              }}
            >
              <Icon type="delete" />
            </Button>
          </Select.Option>
        ))}
      </Select>
    </InputFieldTitle>
  );
};

export default SelectField;
