import { notification, message } from 'antd';
import axios from 'axios';

import settings from '../../../settings';

import { Screens } from '../types';

class ScreensServices {
  public load = async (): Promise<Screens> => {
    try {
      const menuInfo = JSON.parse(sessionStorage.getItem('menu') || '');
      const { data } = await axios.get(`${settings.domain}/api/screens?orgId=${menuInfo.organizationId}`);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити інформацію екранів. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public update = async (updateValue: any) => {
    try {
      const menuInfo = JSON.parse(sessionStorage.getItem('menu') || '');
      const { data } = await axios.put(`${settings.domain}/api/screens?orgId=${menuInfo.organizationId}`, updateValue);
      message.success('Екран успішно оновлений!');
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося оновити екрани. Спробуйте пізніше.'
      });
    }
  };
}

export default new ScreensServices();
