import React from 'react';
import { Drawer } from 'antd';
import { observer } from 'mobx-react-lite';

import './leftSlider.global.scss';
import Menu from './Menu';
import View from './View';
import { leftMenuDrawerStore } from '../../stores';
import { LEFT_MENU_TYPES } from '../../constants';

const LeftMenuSlider = () => {
  const menus = {
    [LEFT_MENU_TYPES.Menu]: {
      Component: <Menu />
    },
    [LEFT_MENU_TYPES.View]: {
      Component: <View />
    }
  };

  return (
    <Drawer
      className="menu-drawer"
      visible={!!leftMenuDrawerStore.menuType}
      placement="left"
      onClose={() => leftMenuDrawerStore.setMenuType(null)}
    >
      {leftMenuDrawerStore.menuType && menus[leftMenuDrawerStore.menuType].Component}
    </Drawer>
  );
};

export default observer(LeftMenuSlider);
