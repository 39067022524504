import React, { useEffect } from 'react';
import { Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';

import Storage from '../../../common/utils/Storage';

import { categoryStore } from '../../stores';
import { imageStore } from '../../../common/stores';
import { UpdateCategoryValues } from '../../types';

import MenuLang from '../../../common/components/MenuLang';
import StatisticEdit from '../StatisticEdit';
import ShowCategoryList from '../ShowCategoryList';
import InputField from '../../../common/components/InputField';
import LoadImg from '../../../common/components/LoadImg';

import styles from './edit.module.scss';
import './edit.global.scss';
import SelectField from '../../../common/components/SelectField';
import CategoryOptions from '../CategoryOptions';

const Edit = (props: FormikProps<UpdateCategoryValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;

  useEffect(() => {
    imageStore.download(categoryStore.category && categoryStore.category.imgUrl ? categoryStore.category.imgUrl : '');
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="editing-category-wrap">
        <div className={styles.wrapTopField}>
          <LoadImg title="Upload" style={styles.loadImg} type={'categories'} />
          <div className={styles.wrapWithoutImg}>
            <div className={styles.wrapLangMenu}>
              <div className={styles.langMenu}>
                <MenuLang />
              </div>
              <div className={styles.buttonWrap}>
                <Button type="primary" htmlType="submit" className={styles.button}>
                  Зберегти
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    if (categoryStore.category) {
                      categoryStore.delete(categoryStore.category.id);
                    }
                  }}
                  className={styles.button}
                >
                  Видалити
                </Button>
                <CategoryOptions />
              </div>
            </div>
            <StatisticEdit />
            <InputField
              title={'Назва категорії'}
              placeholder={'Мінімум 2, максимум 60 символів'}
              name="name"
              defaultValue={categoryStore.category ? categoryStore.category.name : values.name}
              onBlur={() => setFieldTouched('name')}
              errorMessage={touched.name && errors.name}
              onChange={event => setFieldValue('name', event.target.value)}
            />
            <InputField
              title={'Опис категорії'}
              placeholder={'Мінімум 2, максимум 500 символів'}
              name="description"
              defaultValue={categoryStore.category ? categoryStore.category.description : values.description}
              onBlur={() => setFieldTouched('description')}
              errorMessage={touched.description && errors.description}
              onChange={event => setFieldValue('description', event.target.value)}
            />
          </div>
        </div>
        {categoryStore.category && !categoryStore.category.rootId ? (
          <SelectField
            title={'Додати до категорії'}
            loading={categoryStore.loading}
            options={categoryStore.categories ? categoryStore.categories : []}
            onBlur={() => setFieldTouched('rootCategoryId')}
            errorMessage={touched.rootCategoryId && errors.rootCategoryId}
            value={values.rootCategoryId ? values.rootCategoryId : ' + Додати '}
            onChange={value => {
              setFieldValue('rootCategoryId', value);
            }}
          />
        ) : null}
        <div>
          <div className={styles.categoryTableName}>
            <h2>Категорії</h2>
          </div>
          <ShowCategoryList data={categoryStore.category} />
        </div>
      </div>
    </form>
  );
};

const EditWithFormik = withFormik<any, UpdateCategoryValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: categoryStore.category ? categoryStore.category.name : '',
    description: categoryStore.category ? categoryStore.category.description : '',
    imgUrl: categoryStore.category ? categoryStore.category.imgUrl : '',
    rootCategoryId: null,
    order: categoryStore.category ? categoryStore.category.order : 0
  }),
  handleSubmit: values => {
    if (
      Storage('sessionStorage').get('language') === 'EN' ||
      Storage('sessionStorage').get('language') === 'PL' ||
      Storage('sessionStorage').get('language') === 'RU'
    ) {
      if (!values.rootCategoryId) {
        categoryStore.updateLanguage(
          { ...values, imgUrl: imageStore.storagePath ? imageStore.storagePath : values.imgUrl },
          Storage('sessionStorage').get('language') || ''
        );
      } else {
        categoryStore.addSubCategories({
          ...values,
          imgUrl: imageStore.storagePath ? imageStore.storagePath : values.imgUrl
        });
      }
    } else {
      if (!values.rootCategoryId) {
        categoryStore.update({ ...values, imgUrl: imageStore.storagePath ? imageStore.storagePath : values.imgUrl });
      } else {
        categoryStore.addSubCategories({
          ...values,
          imgUrl: imageStore.storagePath ? imageStore.storagePath : values.imgUrl
        });
      }
    }
  },
  validationSchema: yup.object().shape<UpdateCategoryValues>({
    name: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(60, 'Назва повинна мати не більше 60 символів')
      .required('Це поле не може бути порожнім!'),
    description: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(500, 'Назва повинна мати не більше 500 символів')
      .required('Це поле не може бути порожнім!')
  }),
  displayName: 'Edit'
})(observer(Edit));

export default EditWithFormik;
