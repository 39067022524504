import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Spin, Tabs } from 'antd';

import styles from './StatisticTabsContainer.module.scss';
import { statisticStore } from '../../stores';
import StatisticList from '../StatisticList';
import StatisticCharts from '../StatisticCharts';
import StatisticSummary from '../StatisticSummary';
import StatisticChartsAnalysis from '../StatisticChartsAnalysis';
import StatisticChartsDynamics from '../StatisticChartsDynamics';

const { TabPane } = Tabs;

function callback(key) {
  // console.log(key);
}
const StatisticTabsContainer = () => {
  return (
    <Tabs className={styles.tabsContainer} defaultActiveKey="1" type="card" onChange={callback}>
      <TabPane tab="Дашборд" key="1">
        <StatisticSummary />
        {statisticStore.loading === false && statisticStore && statisticStore.statistic && <StatisticCharts />}
      </TabPane>
      <TabPane tab="Аналіз продажів" key="2">
        <StatisticChartsAnalysis />
      </TabPane>
      <TabPane tab="Динаміка продажів" key="3">
        <StatisticChartsDynamics />
      </TabPane>
      <TabPane tab="Замовлення" key="4">
        {statisticStore.loading === true ? <Spin size="large" /> : <StatisticList />}
      </TabPane>
    </Tabs>
  );
};

export default observer(StatisticTabsContainer);
