import * as React from 'react';

import PageArea from '../../components/PageArea';
import CountdownForm from '../../components/CountdownForm';

const CountdownPage = () => {
  return (
    <PageArea
      content={<CountdownForm />}
      pageName="Екран Відліку"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/countdown' },
        { name: 'Відлік', url: '/screens/countdown' }
      ]}
    />
  );
};

export default CountdownPage;
