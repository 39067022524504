import React from 'react';

import ImgBackground from '../ImgBackground';

import styles from './sharedBgImage.module.scss';

const SharedBgImage = ({ screenType }) => {
  return (
    <div className={styles.imgWrap}>
      <div>
        <div className={styles.title}>Фонове зображення скріна</div>
        <ImgBackground title="Додати фон" style={styles.loadImg} screenType={screenType} />
      </div>
    </div>
  );
};

export default SharedBgImage;
