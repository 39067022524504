import React from 'react';
import { Button, Form, Icon, Input } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';

import styles from './loginForm.module.scss';
import { loginStore } from '../../stores';
import { hashingPassword } from '../../../common/utils';

interface FormikValues {
  email: string;
  password: string;
}

const LoginForm = (props: FormikProps<FormikValues>) => {
  const { handleSubmit, values, setFieldValue, errors, setFieldTouched, touched } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <h1>Login</h1>
      <Form.Item
        label="Login"
        required={true}
        validateStatus={loginStore.badCredentials || (errors.email && touched.email) ? 'error' : undefined}
        help={errors.email && touched.email ? 'Required' : undefined}
      >
        <Input
          prefix={<Icon type="user" />}
          placeholder="Email"
          name="email"
          value={values.email}
          onBlur={() => setFieldTouched('email')}
          onChange={event => setFieldValue('email', event.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="Password"
        required={true}
        validateStatus={loginStore.badCredentials || (errors.password && touched.password) ? 'error' : undefined}
        help={errors.password && touched.password ? 'Required' : undefined}
      >
        <Input
          prefix={<Icon type="lock" />}
          type="password"
          name="password"
          placeholder="Password"
          value={values.password}
          onBlur={() => setFieldTouched('password')}
          onChange={event => setFieldValue('password', event.target.value)}
        />
      </Form.Item>
      {loginStore.badCredentials && (
        <div className={styles.errorCredentials}>
          <Icon type="exclamation-circle" className={styles.errorCredentialsIcon} />
          <span>Incorrect email or password.</span>
        </div>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loginStore.loading} className={styles.button}>
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

const LoginFormWithFormik = withFormik<any, FormikValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({ email: '', password: '' }),
  handleSubmit: values => {
    loginStore.login({
      email: values.email,
      password: hashingPassword.hashSHA256(values.password)
    });
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().required('Required'),
    password: Yup.string().required('Required')
  }),
  displayName: 'StudyFilterForm'
})(observer(LoginForm));

export default LoginFormWithFormik;
