import React from 'react';
import { Checkbox, Icon, Button } from 'antd';
import { observer } from 'mobx-react-lite';

import EditAdditionalIngridient from '../EditAdditionalIngridient';

import styles from './showAdditionalIngridientList.module.scss';
import { ShowIngredientsListProps, Ingridient } from '../../types';
import { ingridientStore, dishStore } from '../../stores';
import LoadImg from '../../../common/components/LoadImg';

const ShowAdditionalIngridientList = ({ data }: ShowIngredientsListProps) => {
  return (
    <div className={styles.wrap}>
      <div>
        {data && data.ingredients
          ? data.ingredients.map((ingridient: Ingridient) => {
              return ingridient && ingridient.type === 'ADDITIONAL' ? (
                <div key={ingridient.id} className={styles.checkDish}>
                  {/* here must be onChange fn */}
                  <div className={styles.checkImgWrap}>
                    <Icon type="pushpin" className={styles.icon} />
                    <LoadImg style={styles.loadImg} type={'ingridients'} ingridient={ingridient} />
                    {<Checkbox>{ingridient.name}</Checkbox>}
                  </div>
                  <div className={styles.priceDeleteWrap}>
                    <div className={styles.price}>{ingridient.price} </div>
                    <div className={styles.buttonWrap}>
                      <EditAdditionalIngridient ingridient={ingridient} />
                      <Button
                        size="small"
                        className={styles.button}
                        onClick={() => {
                          if (dishStore.dish) {
                            ingridientStore.removeFromDish(ingridient.id, dishStore.dish);
                          }
                        }}
                      >
                        <Icon type="delete" />
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null;
            })
          : null}
      </div>
      {ingridientStore.ingridients.map((item: any) => {
        if (item.type === 'ADDITIONAL') {
          return (
            <div key={item.id} className={styles.checkDish}>
              <Checkbox>{item.name}</Checkbox>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default observer(ShowAdditionalIngridientList);
