import * as React from 'react';

import MainScreenPage from './pages/MainScreenPage';
import CategoryScreenPage from './pages/CategoryScreenPage';
import BinScreenPage from './pages/BinScreenPage';
import ConfirmScreenPage from './pages/ConfirmScreenPage';
import WhereEatPage from './pages/WhereEatPage';
import TableSelectionPage from './pages/TableSelectionPage';
import PaymentMethodPage from './pages/PaymentMethodPage';
import BankInstructionPage from './pages/BankInstructionPage';
import GetOrderTablePage from './pages/GetOrderTablePage';
import FeedbackPage from './pages/FeedbackThankPage';
import CountdownPage from './pages/CountdownPage';
import GetOrderСashierPage from './pages/GetOrderСashierPage';
import ThemePage from './pages/ThemePage';

import Module from '../Connector';
import { AuthRoute } from '../authentication/accessComponents';

export default new Module({
  route: [
    <AuthRoute exact={true} path="/screens/main" component={MainScreenPage} />,
    <AuthRoute exact={true} path="/screens/category" component={CategoryScreenPage} />,
    <AuthRoute exact={true} path="/screens/bin" component={BinScreenPage} />,
    <AuthRoute exact={true} path="/screens/confirm" component={ConfirmScreenPage} />,
    <AuthRoute exact={true} path="/screens/where-eat" component={WhereEatPage} />,
    <AuthRoute exact={true} path="/screens/table-selection" component={TableSelectionPage} />,
    <AuthRoute exact={true} path="/screens/payment-method" component={PaymentMethodPage} />,
    <AuthRoute exact={true} path="/screens/bank-instructions" component={BankInstructionPage} />,
    <AuthRoute exact={true} path="/screens/table-order" component={GetOrderTablePage} />,
    <AuthRoute exact={true} path="/screens/сashier-order" component={GetOrderСashierPage} />,
    <AuthRoute exact={true} path="/screens/feedback-thanks" component={FeedbackPage} />,
    <AuthRoute exact={true} path="/screens/countdown" component={CountdownPage} />,
    <AuthRoute exact={true} path="/screens/theme" component={ThemePage} />
  ]
});
