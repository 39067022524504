import React from 'react';
import { observer } from 'mobx-react-lite';

import styles from './StatisticCharts.module.scss';
import StatisticChartsGeneral from '../StatisticChartsGeneral';
import StatisticChartsDashboardGoods from '../StatisticChartsDashboardGoods';

const StatisticCharts = () => {
  return (
    <section className={styles.chartsWrapper}>
      <StatisticChartsGeneral />
      <StatisticChartsDashboardGoods />
    </section>
  );
};

export default observer(StatisticCharts);
