import { observable, action, runInAction } from 'mobx';

import { imagesService } from '../services';
import { Category } from '../../category/types';
import { Dish, Ingridient } from '../../dish/types';
import { Organization } from '../../pitstop/types';

class DishStore {
    @observable
    public imgUrl: string | null = null;
    @observable
    public imgBackgroundUrl: string | null = null;
    @observable
    public storagePath: string | null = null;
    @observable
    public loading = true;
    @observable
    public error: Error | null = null;

    @action
    public async download(storagePath: string) {
        this.loading = true;

        try {
            const data = await imagesService.getImageUrlByPath(storagePath);
            runInAction(() => {
                this.imgUrl = data.message;
            });
        } catch (error) {
            runInAction(() => {
                this.imgUrl = null;
                this.error = error;
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
    @action
    public async downloadBackground(storagePath: string) {
        this.loading = true;

        try {
            const data = await imagesService.getImageUrlByPath(storagePath);
            runInAction(() => {
                this.imgBackgroundUrl = data.message;
            });
        } catch (error) {
            runInAction(() => {
                this.imgUrl = null;
                this.error = error;
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    public async upload(
        file: FormData,
        item: Category | Dish | Ingridient | Organization | null,
        type: string
    ) {
        this.loading = true;

        try {
            if (type === 'dishes' && item) {
                const { data } = await imagesService.uploadDishImg(
                    file,
                    item.id
                );
                runInAction(() => {
                    this.storagePath = data.url;
                });
            }
            if (type === 'ingridients' && item) {
                await imagesService.uploadIngridientImg(file, item.id);
            }
            if (type === 'categories' && item) {
                const { data } = await imagesService.uploadСategoryImg(
                    file,
                    item.id
                );
                runInAction(() => {
                    this.storagePath = data.imgUrl;
                });
            }
            if (type === 'pitstoplogo' && item) {
                const { data } = await imagesService.uploadPitstopLogoImg(
                    file,
                    item.id
                );
                runInAction(() => {
                    this.storagePath = data.imgUrl;
                });
            }
            if (type === 'pitstopbackground' && item) {
                const { data } = await imagesService.uploadPitstopBackgroundImg(
                    file,
                    item.id
                );
                runInAction(() => {
                    this.storagePath = data.imgUrl;
                });
            }
        } catch (error) {
            runInAction(() => {
                this.error = error;
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}

export default new DishStore();
