import React from 'react';
import { Chart, Geom, Coord, Tooltip } from 'bizcharts';

import { colorsArray } from '../../types';
interface Props {
  key?: any;
  title?: string;
  list: any[];
  config?: any;
}

export const IntervalChartHorizontalBarBizcharts = ({ key, title, list, config }: Props) => {
  const color = config?.color ? config?.color : 'legendTitle';
  const count = config?.count ? config?.count : 'count';
  const position = `${color}*${count}`;
  return (
    <Chart height={list.length * 25 + 50} data={list} autoFit={true} key={key}>
      <Coord transpose={true} />
      <Tooltip showTitle={false} />
      <Geom type="interval" position={position} color={[color, colorsArray]} label={count} />
    </Chart>
  );
};
