import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Switch } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import Storage from '../../../common/utils/Storage';
import { InputField } from '../../../common/components';
import { UpdateChoosePayment } from '../../types';
import { screenStore } from '../../stores';
import MenuLang from '../MenuLang';

import styles from './paymentMethodForm.module.scss';
import SharedBgImage from '../SharedBgImage';
// import OptionButton from '../../../common/components/OptionsButton';

const PaymentMethodForm = (props: FormikProps<UpdateChoosePayment>) => {
  const [activeLang, setActiveLang] = useState(String(Storage('sessionStorage').get('language')).toLowerCase());
  const { handleSubmit, setFieldTouched, touched, errors, setFieldValue, values } = props;

  const handleLangChange = (language: string) => {
    setActiveLang(language.toLowerCase());
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Спосіб оплати</h3>
      <div className={styles.wrap}>
        <div className={styles.fieldsWrap}>
          <InputField
            title={'Повідомлення'}
            placeholder={'Бажаєте отримати своє замовленя за столиком?'}
            name="message"
            value={values.message[activeLang]}
            onBlur={() => setFieldTouched(`message.${activeLang}`)}
            errorMessage={
              touched.message && touched.message[activeLang] && errors.message && errors.message[activeLang]
            }
            onChange={event => {
              setFieldValue(`message.${activeLang}`, event.target.value);
              setFieldTouched(`message.${activeLang}`);
            }}
          />
          <InputField
            title={'Кнопка зліва'}
            placeholder={'Оплатити тут'}
            name="buttonPayKiosk"
            value={values.buttonPayKiosk[activeLang]}
            onBlur={() => setFieldTouched(`instruction.${activeLang}`)}
            errorMessage={
              touched.buttonPayKiosk &&
              touched.buttonPayKiosk[activeLang] &&
              errors.buttonPayKiosk &&
              errors.buttonPayKiosk[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonPayKiosk.${activeLang}`, event.target.value);
              setFieldTouched(`buttonPayKiosk.${activeLang}`);
            }}
          />
          <InputField
            title={'Поясненя'}
            placeholder={'Заберу замовленя на касі'}
            name="buttonPayKioskDescription"
            value={values.buttonPayKioskDescription[activeLang]}
            onBlur={() => setFieldTouched(`buttonPayKioskDescription.${activeLang}`)}
            errorMessage={
              touched.buttonPayKioskDescription &&
              touched.buttonPayKioskDescription[activeLang] &&
              errors.buttonPayKioskDescription &&
              errors.buttonPayKioskDescription[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonPayKioskDescription.${activeLang}`, event.target.value);
              setFieldTouched(`buttonPayKioskDescription.${activeLang}`);
            }}
          />
          <InputField
            title={'Кнопка справа'}
            placeholder={'Оплатити на касі'}
            name="buttonPayCashier"
            value={values.buttonPayCashier[activeLang]}
            onBlur={() => setFieldTouched(`buttonPayCashier.${activeLang}`)}
            errorMessage={
              touched.buttonPayCashier &&
              touched.buttonPayCashier[activeLang] &&
              errors.buttonPayCashier &&
              errors.buttonPayCashier[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonPayCashier.${activeLang}`, event.target.value);
              setFieldTouched(`buttonPayCashier.${activeLang}`);
            }}
          />
          <InputField
            title={'Пояснення (Оплатити на касі)'}
            placeholder={'На касі оплата проводіться карткою та готівкою'}
            name="buttonPayCashierDescription"
            value={values.buttonPayCashierDescription[activeLang]}
            onBlur={() => setFieldTouched(`buttonPayCashierDescription.${activeLang}`)}
            errorMessage={
              touched.buttonPayCashierDescription &&
              touched.buttonPayCashierDescription[activeLang] &&
              errors.buttonPayCashierDescription &&
              errors.buttonPayCashierDescription[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonPayCashierDescription.${activeLang}`, event.target.value);
              setFieldTouched(`buttonPayCashierDescription.${activeLang}`);
            }}
          />
          <SharedBgImage screenType="choosePayment" />
        </div>
        <div className={styles.buttonsWrap}>
          <div className={styles.wrapTopButtons}>
            <MenuLang onChange={handleLangChange} />
            {/* TODO:here must be OptionButton */}
            {/* <OptionButton /> */}
          </div>
          <div className={styles.wrapBottomButtons}>
            <Switch
              defaultChecked={
                screenStore.screens && screenStore.screens.choosePayment
                  ? screenStore.screens.choosePayment.switchOff
                  : false
              }
              onChange={() =>
                screenStore.update(
                  {
                    ...(screenStore.screens && screenStore.screens.choosePayment),
                    switchOff: screenStore.screens && !screenStore.screens.choosePayment.switchOff
                  },
                  'choosePayment'
                )
              }
            />
            <Button className={styles.saveButton} type="primary" htmlType="submit">
              Зберегти
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const PaymentMethodFormWithFormik = withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    imageUrl:
      screenStore.screens &&
      screenStore.screens.choosePayment &&
      screenStore.screens.choosePayment.background?.imageUrl,
    message: {
      en: screenStore.screens && screenStore.screens.choosePayment && screenStore.screens.choosePayment.message.en,
      pl: screenStore.screens && screenStore.screens.choosePayment && screenStore.screens.choosePayment.message.pl,
      ru: screenStore.screens && screenStore.screens.choosePayment && screenStore.screens.choosePayment.message.ru,
      uk: screenStore.screens && screenStore.screens.choosePayment && screenStore.screens.choosePayment.message.uk
    },
    buttonPayKiosk: {
      en:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayKiosk.name.en,
      pl:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayKiosk.name.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayKiosk.name.ru,
      uk:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayKiosk.name.uk
    },
    buttonPayKioskDescription: {
      en:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayKiosk.description.en,
      pl:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayKiosk.description.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayKiosk.description.ru,
      uk:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayKiosk.description.uk
    },
    buttonPayCashier: {
      en:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayCashier.name.en,
      pl:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayCashier.name.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayCashier.name.ru,
      uk:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayCashier.name.uk
    },
    buttonPayCashierDescription: {
      en:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayCashier.description.en,
      pl:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayCashier.description.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayCashier.description.ru,
      uk:
        screenStore.screens &&
        screenStore.screens.choosePayment &&
        screenStore.screens.choosePayment.buttonPayCashier.description.uk
    }
  }),
  handleSubmit: ({
    message,
    buttonPayKiosk,
    buttonPayKioskDescription,
    buttonPayCashier,
    buttonPayCashierDescription,
    imageUrl
  }) => {
    screenStore.update(
      {
        message: {
          en: message.en,
          pl: message.pl,
          ru: message.ru,
          uk: message.uk
        },
        buttonPayKiosk: {
          name: {
            en: buttonPayKiosk.en,
            pl: buttonPayKiosk.pl,
            ru: buttonPayKiosk.ru,
            uk: buttonPayKiosk.uk
          },
          description: {
            en: buttonPayKioskDescription.en,
            pl: buttonPayKioskDescription.pl,
            ru: buttonPayKioskDescription.ru,
            uk: buttonPayKioskDescription.uk
          }
        },
        buttonPayCashier: {
          name: {
            en: buttonPayCashier.en,
            pl: buttonPayCashier.pl,
            ru: buttonPayCashier.ru,
            uk: buttonPayCashier.uk
          },
          description: {
            en: buttonPayCashierDescription.en,
            pl: buttonPayCashierDescription.pl,
            ru: buttonPayCashierDescription.ru,
            uk: buttonPayCashierDescription.uk
          }
        },
        background: {
          imageUrl
        }
      },
      'choosePayment'
    );
  },
  validationSchema: yup.object().shape<any>({
    message: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    buttonPayKiosk: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    buttonPayKioskDescription: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    buttonPayCashier: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    buttonPayCashierDescription: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    })
  }),
  displayName: 'PaymentMethodForm'
})(observer(PaymentMethodForm));

export default PaymentMethodFormWithFormik;
