import React from 'react';
import { Chart, Line, Point, Tooltip } from 'bizcharts';

interface Props {
  list: any[];
  config?: any;
}
const LineChartBizcharts = ({ list, config }: Props) => {
  const key = config?.key ? config.key : 'day';
  const count = config?.count ? config.count : 'count';
  const scale = {} as any;
  scale[count] = { min: 0, type: 'linear-strict' };
  return (
    <>
      <Chart appendPadding={[10, 0, 0, 10]} autoFit={true} height={200} data={list} scale={scale}>
        <Line position={`${key}*${count}`} />
        <Point position={`${key}*${count}`} />
        <Tooltip showCrosshairs={true} />
      </Chart>
    </>
  );
};

export default LineChartBizcharts;
