import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';

import settings from '../../../../settings';
import { AppLayout } from '../../../common/components';

import styles from './statisticPage.module.scss';
import Filter from '../../components/Filter';
import { statisticStore } from '../../stores';
import { userStore } from '../../../authentication/stores';
import StatisticTabsContainer from '../../components/StatisticTabsContainer';

const StatisticPage = () => {
  useEffect(() => {
    statisticStore.load({
      endDateTime: new Date().setHours(23, 59, 59, 999),
      locationIds: [],
      categoryIds: [],
      orgId: userStore.user && userStore.user.organizationId,
      payType: null,
      deliveryType: null,
      systemType: null,
      startDateTime: new Date().setHours(0, 0, 0, 0)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.user && userStore.user.organizationId]);
  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Сторінка статистики`}</title>
        <meta name="description" content={`${settings.appName} - Сторінка статистики`} />
      </Helmet>
      <div className={styles.pageWrapper}>
        <div className={styles.wrap}>
          <Filter />
          <StatisticTabsContainer />
        </div>
      </div>
    </AppLayout>
  );
};

export default observer(StatisticPage);
