import React from 'react';
import { Button, Icon } from 'antd';

import EditIngridientsModificator from '../EditIngridientModificator';

import styles from './showIngredientsModificatorList.module.scss';
import { Ingridient, ShowIngredientsListProps } from '../../types';
import { ingridientStore, dishStore } from '../../stores';

const ShowIngredientsModificarorList = ({ data }: ShowIngredientsListProps) => {
  return (
    <div className={styles.wrap}>
      <div>
        {data && data.ingredients
          ? data.ingredients.map((ingridient: Ingridient) => {
              return ingridient && ingridient.type === 'MODIFIER' ? (
                <div key={ingridient.id} className={styles.checkDish}>
                  <div>
                    <Icon type="pushpin" className={styles.icon} />
                    {ingridient.name}
                  </div>
                  <div className={styles.buttonWrap}>
                    <EditIngridientsModificator ingridient={ingridient} />
                    <Button
                      size="small"
                      className={styles.button}
                      onClick={() => {
                        if (dishStore.dish) {
                          ingridientStore.removeFromDish(ingridient.id, dishStore.dish);
                        }
                      }}
                    >
                      <Icon type="delete" />
                    </Button>
                  </div>
                </div>
              ) : null;
            })
          : null}
      </div>
      {ingridientStore.ingridients.map((item: any) => {
        if (item.type === 'MODIFIER') {
          return (
            <div key={item.id} className={styles.checkDish}>
              {item.name}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default ShowIngredientsModificarorList;
