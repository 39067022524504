import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Button, Icon } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';

import PageArea from '../../components/PageArea';
import { categoryStore } from '../../../category/stores';
import styles from './categoryScreenPage.module.scss';
import { Category } from '../../../category/types';
import SaveButton from '../../components/SaveButton';

const CategoryScreenPage = () => {
  React.useEffect(() => {
    categoryStore.loadList();
  }, []);
  const createEditMenu = (item: Category) => {
    return (
      <div className={styles.category} key={item.id}>
        <div className={styles.titleWrap}>
          <Checkbox className={styles.title}>{item.name}</Checkbox>
          <ButtonGroup size="small">
            <Button onClick={() => categoryStore.changeCategoryOrder('UP', item)}>
              <Icon type="caret-up" />
            </Button>
            <Button onClick={() => categoryStore.changeCategoryOrder('DOWN', item)}>
              <Icon type="caret-down" />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  };

  const Content = (
    <div>
      <h3>Каталог меню</h3>
      <div>Категорії</div>
      <div className={styles.wrap}>
        <div>
          {categoryStore.categories
            ? categoryStore.categories.map((category: Category) => createEditMenu(category))
            : null}
        </div>
      </div>
    </div>
  );
  return (
    <PageArea
      content={Content}
      savePanel={<SaveButton isSwich={false} />}
      pageName="Екран категорій"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/category' },
        { name: 'Категорії', url: '/screens/category' }
      ]}
    />
  );
};

export default observer(CategoryScreenPage);
