import React from 'react';
import { Helmet } from 'react-helmet';

import logo from './touch.png';

import settings from '../../../../settings';
import { AppLayout } from '../../../common/components';
import styles from './startPage.module.scss';

const StartPage = () => {
  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Меню`}</title>
        <meta name="description" content={`${settings.appName} - Меню`} />
      </Helmet>
      <div className={styles.wrap}>
        <div className={styles.logoWrap}>
          <div className={styles.heading}>TOUCHCORE</div>
          <img className={styles.logo} src={logo} alt="touch logo" />
        </div>
      </div>
    </AppLayout>
  );
};

export default StartPage;
