import * as React from 'react';

import PageArea from '../../components/PageArea';
import ConfirmScreenForm from '../../components/ConfirmScreenForm';

const ConfirmScreenPage = () => {
  return (
    <PageArea
      content={<ConfirmScreenForm />}
      pageName="Екран підтвердження"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/confirm' },
        { name: 'Підтвердження', url: '/screens/confirm' }
      ]}
    />
  );
};

export default ConfirmScreenPage;
