import { action, observable } from 'mobx';

import { LEFT_MENU_TYPES } from '../constants';

class LeftMenuDrawer {
  @observable
  public menuType: LEFT_MENU_TYPES | null = null;

  @action
  public setMenuType = (menuType: LEFT_MENU_TYPES | null) => {
    this.menuType = menuType;
  };
}

export default new LeftMenuDrawer();
