import * as React from 'react';
import { configure } from 'mobx';
import { Router, BrowserRouter, Switch } from 'react-router-dom';

import modules from './modules';
import history from './history';
import Storage from './modules/common/utils/Storage';

configure({ enforceActions: 'observed' });

const isBrowserSupportsHistory = 'pushState' in window.history;

const currentLang = Storage('sessionStorage').get('language');
const currentMenu = Storage('sessionStorage').get('menu');

if (!currentLang) {
    Storage('sessionStorage').set('language', 'UK');
}

if (!currentMenu) {
    Storage('sessionStorage').set('menu', { id: null, name: '' });
}

class App extends React.Component {
    public render() {
        return modules.getDataRoot(
            <BrowserRouter forceRefresh={!isBrowserSupportsHistory}>
                <Router history={history}>
                    <Switch>{modules.routes}</Switch>
                </Router>
            </BrowserRouter>
        );
    }
}

export default App;
