import React from 'react';
import { observer } from 'mobx-react-lite';

import OptionsButton from '../OptionsButton';
import styles from './viewport.module.scss';

const Viewport = () => {
  return (
    <div
      className={styles.window}
      style={{
        backgroundImage: `url('')`
      }}
    >
      <OptionsButton content={'Пока ничего'} style={styles.viewButton} />
    </div>
  );
};

export default observer(Viewport);
