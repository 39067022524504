export class InputDelay {
  public timerId: number | null = null;
  public ms: number;

  constructor(ms: number = 500) {
    this.ms = ms;
  }

  public onSearch = (text: string, callback: () => void) => {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }

    this.timerId = setTimeout(() => callback(), this.ms) as any;
  };
}
