import axios from 'axios';
import { notification } from 'antd';

import { Feedback, FeedbackValues } from '../types';
import settings from '../../../settings';

class FeedbackService {
  public load = async (values: FeedbackValues): Promise<Feedback> => {
    try {
      const { data } = await axios.post(`${settings.domain}/api/admin/rating/lviv-croissan/load`, values);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити відгуки. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new FeedbackService();
