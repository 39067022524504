import * as React from 'react';
import { Row, Button, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import styles from './notFoundPage.module.scss';
import settings from '../../../../settings';
import { AppLayout } from '../../../common/components';

class NotFoundPage extends React.Component {
  public render() {
    return (
      <AppLayout>
        <Helmet>
          <title>{`${settings.appName} - Сторінку не знайдено`}</title>
          <meta name="description" content={`${settings.appName} - Сторінку не знайдено`} />
        </Helmet>

        <Row type="flex" justify="center" align="middle" className={styles.pageWrapper}>
          <div>
            <div className={styles.title}>404</div>
            <div className={styles.subTitle}>На жаль, сторінку не знайдено.</div>
            <div className={styles.buttonWrapper}>
              <Link to="/">
                <Button className={styles.buttonBack} type="dashed">
                  <div>
                    <Icon type="left" /> Перейти на головну сторінку
                  </div>
                </Button>
              </Link>
            </div>
          </div>
        </Row>
      </AppLayout>
    );
  }
}

export default NotFoundPage;
