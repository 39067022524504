import React from 'react';
import { observer } from 'mobx-react-lite';
import Table from 'antd/lib/table';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import styles from '../StatisticCharts/StatisticCharts.module.scss';
import { statisticStore } from '../../stores';
import { prepareLegendTitlesByOrderCount, updateLocationsArray } from '../../services/ChartsPrepare';
import { IntervalChartHorizontalBarBizcharts } from '../StatisticChartsCommon/IntervalChartHorizontalBarBizcharts';
import { IntervalChartBizcharts } from '../StatisticChartsCommon/IntervalChartBizcharts';

const StatisticChartsAnalysis = () => {
  let locations = [] as any[];

  const prepareDataForLocationsCharts = () => {
    statisticStore?.statistic?.forEach((order: any) => {
      locations = updateLocationsArray(locations, order.locationId, order.locationName, order.orderPrice);
      const locIndex = locations && locations.findIndex((loc: any) => loc.key === order.locationId);
      order.orderItems.forEach(oi => (locations[locIndex].menuItemsCount += oi.count));
    });
  };

  const renderLocationsRadialCharts = () => {
    prepareDataForLocationsCharts();
    const preparedLocations = prepareLegendTitlesByOrderCount(locations);
    const locationsByCount = preparedLocations.map(loc => loc);
    locationsByCount.sort((a, b) => a.avgMenuItemsInOrder - b.avgMenuItemsInOrder);
    const locationsByPrice = preparedLocations.map(loc => loc);
    locationsByPrice.sort((a, b) => a.avgReceipt - b.avgReceipt);
    return (
      <>
        <section className={styles.oneLineWithThree}>
          <section className={styles.oneLineWithThreeSub}>
            <section className={styles.chartWrapper}>
              <h2>Локації, кількість замовлень</h2>
              <IntervalChartBizcharts
                list={preparedLocations}
                config={{
                  height: 250,
                  width: 250,
                  position: 'ordersPercent',
                  color: 'ordersLegendTitle'
                }}
              />
            </section>
            <section className={styles.chartWrapper}>
              <h2>Локації, кількість товарів</h2>
              <IntervalChartBizcharts
                list={preparedLocations}
                config={{
                  height: 250,
                  width: 250,
                  position: 'menuItemsPercent',
                  color: 'menuItemsLegendTitle'
                }}
              />
            </section>
            <section className={styles.chartWrapper}>
              <h2>Локації, вартість товарів ₴</h2>
              <IntervalChartBizcharts
                list={preparedLocations}
                config={{
                  height: 250,
                  width: 250,
                  position: 'pricePercent',
                  color: 'priceLegendTitle'
                }}
              />
            </section>
          </section>
        </section>
        <section className={styles.oneLineWithTwo}>
          <section className={styles.chartWrapper}>
            <h2> Середня кількість товарів в чеку</h2>
            <IntervalChartHorizontalBarBizcharts
              list={locationsByCount}
              config={{
                count: 'avgMenuItemsInOrder',
                color: 'avgMenuItemsInOrderLegendTitle'
              }}
            />
          </section>
          <section className={styles.chartWrapper}>
            <h2>Середній чек ₴</h2>
            <IntervalChartHorizontalBarBizcharts
              list={locationsByPrice}
              config={{
                count: 'avgReceipt',
                color: 'avgReceiptLegendTitle'
              }}
            />
          </section>
        </section>
      </>
    );
  };

  const columns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      align: 'left' as 'left',
      width: '20px',
      sorter: (a: any, b: any) => a.key - b.key
    },
    {
      title: 'Локація',
      dataIndex: 'title',
      key: 'title',
      align: 'left' as 'left'
    },
    {
      title: 'Кількість чеків (шт.)',
      dataIndex: 'count',
      key: 'count',
      align: 'left' as 'left',
      sorter: (a: any, b: any) => a.count - b.count
    },
    {
      title: 'Оборот (грн.)',
      dataIndex: 'price',
      key: 'price',
      align: 'left' as 'left',
      sorter: (a: any, b: any) => a.price - b.price
    }
  ];

  const getDataSource = () => {
    if (statisticStore.loading === false && statisticStore && statisticStore.statistic) {
      prepareDataForLocationsCharts();
      const table = locations.map((record: any, idx) => ({
        key: idx + 1,
        title: record.title,
        count: record.ordersCount,
        price: record.priceCount
      }));
      return table;
    }
  };

  const prepTable = () => {
    return (
      <table id="statistic-analysis" style={{ display: 'none' }}>
        <thead>
          <tr key={'heading'}>
            {columns.map(column => (
              <th key={column.key}>{column.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {locations.map((record: any, idx) => (
            <tr key={'statistic-row-' + idx}>
              <td>{idx + 1}</td>
              <td>{record.title}</td>
              <td>{record.ordersCount}</td>
              <td>{record.priceCount}</td>
            </tr>
          ))}
          <tr key={'statistic-row-' + (locations.length + 1)}>
            <td />
            <td>{'Сума'}</td>
            <td>
              {locations.reduce((sum, current: any) => {
                return sum + current.ordersCount;
              }, 0)}
            </td>
            <td>
              {locations.reduce((sum, current: any) => {
                return sum + current.priceCount;
              }, 0)}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <section className={styles.chartsWrapper}>
      <div className="table">
        <Table
          className="statisticTable"
          locale={{ emptyText: 'Немає даних' }}
          dataSource={getDataSource()}
          columns={columns}
          pagination={{
            className: styles.tablePagination,
            position: 'top',
            defaultPageSize: 10,
            pageSizeOptions: ['10', '20', '30', '40', '50', '60', '100'],
            showSizeChanger: true
          }}
        />
        {statisticStore && statisticStore.statistic && prepTable()}
        <ReactHTMLTableToExcel
          type="primary"
          className={styles.button}
          table="statistic-analysis"
          filename={`TouchCore-Statistic-Analysis-${new Date()
            .toISOString()
            .slice(0, 19)
            .replace(/-/g, '/')
            .replace('T', ' ')}`}
          sheet="Sheet"
          buttonText="Експортувати в Excel"
        />
      </div>
      {renderLocationsRadialCharts()}
    </section>
  );
};

export default observer(StatisticChartsAnalysis);
