import React, { useEffect } from 'react';
import { Divider, Menu as MenuAntd, Icon, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { StopOutlined } from '@ant-design/icons';

import './menu.global.scss';
import styles from './menu.module.scss';
import { leftMenuDrawerStore } from '../../stores';
import { categoryStore } from '../../../category/stores';
import { dishStore } from '../../../dish/stores';
import { Category } from '../../../category/types';
import { Dish } from '../../../dish/types';
const { SubMenu } = MenuAntd;

const Menu = () => {
  useEffect(() => {
    categoryStore.loadList();
    dishStore.loadStopList();
  }, []);

  const showList = (categories: Category[]) => {
    return categories.map((item: Category) => {
      return (
        <SubMenu
          key={`sub${item.id}`}
          onTitleClick={() => categoryStore.load(item.id, true)}
          title={
            !item.archived ? (
              <Link
                to={`/categories/edit/${item.id}`}
                className={styles.link}
                onClick={() => {
                  categoryStore.load(item.id);
                  leftMenuDrawerStore.setMenuType(null);
                }}
              >
                <Icon type="pushpin" /> {item.name}
              </Link>
            ) : (
              <Link
                to={`/categories/edit/${item.id}`}
                className={styles.linkArchived}
                onClick={() => {
                  categoryStore.load(item.id);
                  leftMenuDrawerStore.setMenuType(null);
                }}
              >
                <Icon type="pushpin" /> {item.name}
              </Link>
            )
          }
        >
          {categoryStore.category && categoryStore.category.id === item.id && categoryStore.category.menuItems ? (
            !categoryStore.loading ? (
              categoryStore.category.menuItems.map((dish: Dish) => {
                return (
                  <MenuAntd.Item key={dish.id} className={styles.menuItem}>
                    {dish.archived ? (
                      <Link
                        className={styles.linkArchived}
                        to={`/dishes/edit/${dish.id}`}
                        onClick={() => leftMenuDrawerStore.setMenuType(null)}
                      >
                        {dish.name}
                      </Link>
                    ) : dishStore.stopList && !dishStore.loading && dishStore.stopList.includes(dish.id) ? (
                      <Link
                        className={styles.linkArchived}
                        to={`/dishes/edit/${dish.id}`}
                        onClick={() => leftMenuDrawerStore.setMenuType(null)}
                      >
                        <StopOutlined />
                        {dish.name}
                      </Link>
                    ) : (
                      <Link
                        className={styles.link}
                        to={`/dishes/edit/${dish.id}`}
                        onClick={() => leftMenuDrawerStore.setMenuType(null)}
                      >
                        {dish.name}
                      </Link>
                    )}
                  </MenuAntd.Item>
                );
              })
            ) : (
              <MenuAntd.Item className={styles.menuItem}>
                <Skeleton active={true} paragraph={{ rows: 1 }} />
              </MenuAntd.Item>
            )
          ) : null}
          {item.subCategories ? showList(item.subCategories) : null}
        </SubMenu>
      );
    });
  };

  if (!categoryStore.categories) {
    return <Skeleton active={true} paragraph={{ rows: 16 }} />;
  }
  return (
    <div className="left-slider-menu">
      <Divider className={styles.divider} />
      <div className={styles.title}>
        <div className={styles.titleName}>
          <h4>Каталог меню</h4>
        </div>
        <div className={styles.titleImg}>
          <Icon type="arrow-left" onClick={() => leftMenuDrawerStore.setMenuType(null)} />
        </div>
      </div>
      <Divider className={styles.divider} />
      <MenuAntd className={styles.menu} defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']} mode="inline">
        {showList(categoryStore.categories)}
      </MenuAntd>
    </div>
  );
};

export default observer(Menu);
