import React from 'react';

import { Chart, Interval, Tooltip, Axis, Coordinate, Interaction, Legend } from 'bizcharts';
interface Props {
  title?: string;
  list: any[];
  config?: any;
}

export const IntervalChartBizcharts = ({ title, list, config }: Props) => {
  const position = config?.position ? config.position : 'percent';
  const color = config?.color ? config.color : 'legendTitle';
  const cols = {
    percent: {
      formatter: val => {
        val = val + '%';
        return val;
      }
    },
    ordersPercent: {
      formatter: val => {
        val = val + '%';
        return val;
      }
    },
    pricePercent: {
      formatter: val => {
        val = val + '%';
        return val;
      }
    },
    menuItemsPercent: {
      formatter: val => {
        val = val + '%';
        return val;
      }
    }
  };

  const legendConfig = {} as any;
  if (config?.itemWidth) {
    legendConfig.itemWidth = config.itemWidth;
  }

  return (
    <Chart height={config?.height ? config?.height : 400} data={list} scale={cols} autoFit={true}>
      <Coordinate type="theta" radius={config?.radius || 0.9} />
      <Tooltip showTitle={false} />
      <Axis visible={false} />
      <Interval
        position={position}
        adjust="stack"
        color={color}
        style={{
          lineWidth: 1,
          stroke: '#fff'
        }}
        element-highlight={true}
        width={config?.width ? config?.width : 400}
        height={config?.height ? config?.height : 400}
        label={[
          '',
          {
            content: data => {
              return `${data[color]}`;
            }
          }
        ]}
      />
      <Legend
        position={config?.legendPosition ? config.legendPosition : 'bottom'}
        itemHeight={25}
        {...legendConfig}
        itemGap={1}
      />
      <Interaction type="element-single-selected" />
    </Chart>
  );
};
