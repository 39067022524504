import React, { useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { observer } from 'mobx-react-lite';

import styles from './synchronizeModal.module.scss';
import './synchronizeModal.global.scss';
import { synchronizeStore } from '../../stores';

const SynchronizeModal = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="synchronize-modal">
      <Button
        className={styles.syncButton}
        icon="sync"
        type="primary"
        ghost={true}
        onClick={() => {
          synchronizeStore.getSyncData();
          setOpen(true);
        }}
      >
        Синхронізувати
      </Button>

      <Modal
        visible={!isOpen ? isOpen : synchronizeStore.loading}
        onCancel={() => setOpen(false)}
        centered={true}
        footer={null}
        closable={false}
      >
        <div className={styles.modalContent}>
          <Spin size={'large'} />
          <div className={styles.modalText}>Дочекайтесь будь ласка завершення синхронізації</div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(SynchronizeModal);
