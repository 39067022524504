import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';

import { authStore } from '../stores';

const AuthComponent: FunctionComponent = ({ children }) => {
  return <div>{!!authStore.entity && children}</div>;
};

export default observer(AuthComponent);
