import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';

import { categoryStore } from '../../stores';
import PageArea from '../../components/PageArea';
import Edit from '../../components/Edit';
import styles from './editCategoryPage.module.scss';

const EditCategoryPage = ({ match }: RouteComponentProps<{ id: string }>) => {
  useEffect(() => {
    categoryStore.load(Number(match.params.id));
    categoryStore.loadList();
  }, [match.params.id]);
  return (
    <PageArea
      content={
        categoryStore.loading ? (
          <div>
            <Skeleton className={styles.spinnerWrap} />
          </div>
        ) : (
          <Edit />
        )
      }
      pageName="Редагування категорії"
      breadLinks={[
        { name: 'Меню', url: '/' },
        {
          name: `# ${categoryStore.category ? categoryStore.category.name : ''}`,
          url: `/category/edit/${match.params.id} `
        }
      ]}
    />
  );
};

export default observer(EditCategoryPage);
