import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import Storage from '../../../common/utils/Storage';
import { InputField } from '../../../common/components';
import { UpdateConfirmOrder } from '../../types';
import { screenStore } from '../../stores';
import MenuLang from '../MenuLang';

import styles from './confirmScreenForm.module.scss';
import SharedBgImage from '../SharedBgImage';
// import OptionButton from '../../../common/components/OptionsButton';

const ConfirmScreenForm = (props: FormikProps<UpdateConfirmOrder>) => {
  const [activeLang, setActiveLang] = useState(String(Storage('sessionStorage').get('language')).toLowerCase());
  const { handleSubmit, setFieldTouched, touched, errors, setFieldValue, values } = props;

  const handleLangChange = (language: string) => {
    setActiveLang(language.toLowerCase());
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Підтвердження замовлення</h3>
      <div className={styles.wrap}>
        <div className={styles.fieldsWrap}>
          <InputField
            title={'Повідомлення'}
            placeholder={'Замовлення правильне?'}
            name="message"
            value={values.message[activeLang]}
            onBlur={() => setFieldTouched(`message.${activeLang}`)}
            errorMessage={
              touched.message && touched.message[activeLang] && errors.message && errors.message[activeLang]
            }
            onChange={event => {
              setFieldValue(`message.${activeLang}`, event.target.value);
              setFieldTouched(`message.${activeLang}`);
            }}
          />
          <InputField
            title={'Кнопка підтвердження'}
            placeholder={'Так'}
            name="buttonConfirm"
            value={values.buttonConfirm[activeLang]}
            onBlur={() => setFieldTouched(`buttonConfirm.${activeLang}`)}
            errorMessage={
              touched.buttonConfirm &&
              touched.buttonConfirm[activeLang] &&
              errors.buttonConfirm &&
              errors.buttonConfirm[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonConfirm.${activeLang}`, event.target.value);
              setFieldTouched(`buttonConfirm.${activeLang}`);
            }}
          />
          <InputField
            title={'Кнопка відміни'}
            placeholder={'Ні'}
            name="buttonCancel"
            value={values.buttonCancel[activeLang]}
            onBlur={() => setFieldTouched(`buttonCancel.${activeLang}`)}
            errorMessage={
              touched.buttonCancel &&
              touched.buttonCancel[activeLang] &&
              errors.buttonCancel &&
              errors.buttonCancel[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonCancel.${activeLang}`, event.target.value);
              setFieldTouched(`buttonCancel.${activeLang}`);
            }}
          />
          <SharedBgImage screenType="confirmOrder" />
        </div>
        <div className={styles.buttonsWrap}>
          <div className={styles.wrapTopButtons}>
            <MenuLang onChange={handleLangChange} />
            {/* TODO:here must be OptionButton */}
            {/* <OptionButton /> */}
          </div>
          <div className={styles.wrapBottomButtons}>
            <div />
            <Button className={styles.saveButton} type="primary" htmlType="submit">
              Зберегти
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const ConfirmScreenFormWithFormik = withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    message: {
      en: screenStore.screens && screenStore.screens.confirmOrder && screenStore.screens.confirmOrder.message.en,
      pl: screenStore.screens && screenStore.screens.confirmOrder && screenStore.screens.confirmOrder.message.pl,
      ru: screenStore.screens && screenStore.screens.confirmOrder && screenStore.screens.confirmOrder.message.ru,
      uk: screenStore.screens && screenStore.screens.confirmOrder && screenStore.screens.confirmOrder.message.uk
    },
    buttonConfirm: {
      en:
        screenStore.screens &&
        screenStore.screens.confirmOrder &&
        screenStore.screens.confirmOrder.buttonConfirm.name.en,
      pl:
        screenStore.screens &&
        screenStore.screens.confirmOrder &&
        screenStore.screens.confirmOrder.buttonConfirm.name.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.confirmOrder &&
        screenStore.screens.confirmOrder.buttonConfirm.name.ru,
      uk:
        screenStore.screens &&
        screenStore.screens.confirmOrder &&
        screenStore.screens.confirmOrder.buttonConfirm.name.uk
    },
    buttonCancel: {
      en:
        screenStore.screens &&
        screenStore.screens.confirmOrder &&
        screenStore.screens.confirmOrder.buttonCancel.name.en,
      pl:
        screenStore.screens &&
        screenStore.screens.confirmOrder &&
        screenStore.screens.confirmOrder.buttonCancel.name.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.confirmOrder &&
        screenStore.screens.confirmOrder.buttonCancel.name.ru,
      uk:
        screenStore.screens && screenStore.screens.confirmOrder && screenStore.screens.confirmOrder.buttonCancel.name.uk
    }
  }),
  handleSubmit: ({ message, buttonConfirm, buttonCancel, imageUrl }) => {
    screenStore.update(
      {
        imageUrl:
          (screenStore.screens &&
            screenStore.screens.confirmOrder &&
            screenStore.screens.confirmOrder.background?.imageUrl) ||
          null,
        message: {
          en: message.en,
          pl: message.pl,
          ru: message.ru,
          uk: message.uk
        },
        buttonConfirm: {
          description:
            screenStore.screens &&
            screenStore.screens.confirmOrder &&
            screenStore.screens.confirmOrder.buttonConfirm.description,
          name: {
            en: buttonConfirm.en,
            pl: buttonConfirm.pl,
            ru: buttonConfirm.ru,
            uk: buttonConfirm.uk
          }
        },
        buttonCancel: {
          description:
            screenStore.screens &&
            screenStore.screens.confirmOrder &&
            screenStore.screens.confirmOrder.buttonConfirm.description,
          name: {
            en: buttonCancel.en,
            pl: buttonCancel.pl,
            ru: buttonCancel.ru,
            uk: buttonCancel.uk
          }
        },
        background: {
          imageUrl
        }
      },
      'confirmOrder'
    );
  },
  validationSchema: yup.object().shape<any>({
    message: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),

    buttonConfirm: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    buttonCancel: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    })
  }),
  displayName: 'ConfirmScreenForm'
})(observer(ConfirmScreenForm));

export default ConfirmScreenFormWithFormik;
