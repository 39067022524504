import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import Storage from '../../../common/utils/Storage';
import { InputField } from '../../../common/components';
import { UpdateGetOrderCashier } from '../../types';
import { screenStore } from '../../stores';
import MenuLang from '../MenuLang';

import styles from './getOrderСashierForm.module.scss';
import SharedBgImage from '../SharedBgImage';
// import OptionButton from '../../../common/components/OptionsButton';

const GetOrderCashierForm = (props: FormikProps<UpdateGetOrderCashier>) => {
  const [activeLang, setActiveLang] = useState(String(Storage('sessionStorage').get('language')).toLowerCase());
  const { handleSubmit, setFieldTouched, touched, errors, setFieldValue, values } = props;

  const handleLangChange = (language: string) => {
    setActiveLang(language.toLowerCase());
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Отримання замовленя на касі</h3>
      <div className={styles.wrap}>
        <div className={styles.fieldsWrap}>
          <InputField
            title={'Повідомлення'}
            placeholder={'Будь-ласка візьміть чек для перевірки на касі'}
            name="description"
            value={values.description[activeLang]}
            onBlur={() => setFieldTouched(`description.${activeLang}`)}
            errorMessage={
              touched.description &&
              touched.description[activeLang] &&
              errors.description &&
              errors.description[activeLang]
            }
            onChange={event => {
              setFieldValue(`description.${activeLang}`, event.target.value);
              setFieldTouched(`description.${activeLang}`);
            }}
          />
          <SharedBgImage screenType="getOrderCashier" />
        </div>
        <div className={styles.buttonsWrap}>
          <div className={styles.wrapTopButtons}>
            <MenuLang onChange={handleLangChange} />
            {/* TODO:here must be OptionButton */}
            {/* <OptionButton /> */}
          </div>
          <div className={styles.wrapBottomButtons}>
            <div />
            <Button className={styles.saveButton} type="primary" htmlType="submit">
              Зберегти
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const GetOrderCashierFormWithFormik = withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    imageUrl:
      (screenStore.screens &&
        screenStore.screens.getOrderCashier &&
        screenStore.screens.getOrderCashier.background?.imageUrl) ||
      null,
    description: {
      en:
        screenStore.screens &&
        screenStore.screens.getOrderCashier &&
        screenStore.screens.getOrderCashier.description.en,
      pl:
        screenStore.screens &&
        screenStore.screens.getOrderCashier &&
        screenStore.screens.getOrderCashier.description.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.getOrderCashier &&
        screenStore.screens.getOrderCashier.description.ru,
      uk:
        screenStore.screens && screenStore.screens.getOrderCashier && screenStore.screens.getOrderCashier.description.uk
    }
  }),
  handleSubmit: ({ description, imageUrl }) => {
    screenStore.update(
      {
        description: {
          en: description.en,
          pl: description.pl,
          ru: description.ru,
          uk: description.uk
        },
        background: {
          imageUrl
        }
      },
      'getOrderCashier'
    );
  },
  validationSchema: yup.object().shape<any>({
    description: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    })
  }),
  displayName: 'GetOrderСashierForm'
})(observer(GetOrderCashierForm));

export default GetOrderCashierFormWithFormik;
