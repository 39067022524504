import axios from 'axios';
import { notification } from 'antd';

import Storage from '../utils/Storage';

import settings from '../../../settings';
import { userStore } from '../../authentication/stores';

class Synchronize {
  public getSyncData = async () => {
    try {
      const { data } = await axios.get(
        `${settings.domain}/api/database/sync-one-c/${userStore.user?.organizationId}/${
          Storage('sessionStorage').get('menu').id
        }/${Storage('sessionStorage').get('menu').locationId}`
      );
      return data;
    } catch (error) {
      if (!Storage('sessionStorage').get('menu').id || !Storage('sessionStorage').get('menu').locationId) {
        notification.warning({
          message: 'Оберіть локацію з меню.',
          description: 'Не вдалося синхронізуватися з 1С. Оберіть будь-ласка в панелі локацію з меню.'
        });
      } else {
        notification.error({
          message: 'Серверна помилка.',
          description: 'Не вдалося синхронізуватися з 1С. Спробуйте пізніше.'
        });
        throw error;
      }
    }
  };
}

export default new Synchronize();
