import { observable, action, runInAction } from 'mobx';

import { Feedback, FeedbackValues } from '../types';
import { feedbackService } from '../services';

class FeedbackStore {
  @observable
  public feedback: Feedback | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action
  public async load(values: FeedbackValues) {
    this.loading = true;

    try {
      const feedback = await feedbackService.load(values);
      runInAction(() => {
        this.feedback = feedback;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new FeedbackStore();
