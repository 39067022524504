import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Spin } from 'antd';

import { observer } from 'mobx-react-lite';

import settings from '../../../../settings';
import { AppLayout, Breadcrumbs, MaterialShadow } from '../../../common/components';
import Viewport from '../../../common/components/Viewport';

import styles from './pageArea.module.scss';
import { screenStore } from '../../stores';

interface PageAreaProps {
  content: JSX.Element;
  savePanel?: JSX.Element;
  pageName?: string;
  breadLinks: Array<{
    name: string;
    url: string;
  }>;
}

const PageArea = ({ content, pageName, breadLinks }: PageAreaProps) => {
  useEffect(() => {
    screenStore.load();
  }, []);

  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - ${pageName} `}</title>
        <meta name="description" content={`${settings.appName} - ${pageName} `} />
      </Helmet>
      <Row type="flex" justify="space-around">
        <Col span={24} xl={11}>
          <Breadcrumbs links={breadLinks} />
        </Col>
        <Col span={24} xl={11} />
      </Row>

      <Row type="flex" justify="space-around">
        <Col span={24} xl={14}>
          <MaterialShadow>
            {!screenStore.loading ? (
              <Row className={styles.wrap}>
                <Col span={24}>{content}</Col>
              </Row>
            ) : (
              <div className={styles.spinnerWrap}>
                <Spin />
              </div>
            )}
          </MaterialShadow>
        </Col>

        <Col span={24} xl={9}>
          <MaterialShadow>
            <Viewport />
          </MaterialShadow>
        </Col>
      </Row>
    </AppLayout>
  );
};

export default observer(PageArea);
