import * as React from 'react';

import PageArea from '../../components/PageArea';
import PaymentMethodForm from '../../components/PaymentMethodForm';

const PaymentMethodPage = () => {
  return (
    <PageArea
      content={<PaymentMethodForm />}
      pageName="Екран cпособу оплати"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/payment-method' },
        { name: 'Спосіб оплати', url: '/screens/payment-method' }
      ]}
    />
  );
};

export default PaymentMethodPage;
