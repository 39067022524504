import { observable, action, runInAction } from 'mobx';

import { imagesService } from '../services';
import { screenStore } from '.';

class ImageScreensStore {
  @observable
  public logo: string | null = null;
  @observable
  public background: Array<{}> | [] = [];
  @observable
  public loadingLogo = true;
  @observable
  public loadingBackground = true;
  @observable
  public error: Error | null = null;

  @action
  public async download(storagePath: string, type?: 'logo' | 'background', screenType = 'start') {
    this.loadingLogo = true;
    this.loadingBackground = true;

    try {
      if (type === 'logo') {
        const data = await imagesService.getImageUrlByPath(storagePath);
        runInAction(() => {
          this.logo = data.message;
        });
      }
      if (type === 'background') {
        const data = await imagesService.getImageUrlByPath(storagePath);
        runInAction(() => {
          this.background[screenType] = data.message;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.logo = null;
        this.background[screenType] = null;
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loadingLogo = false;
        this.loadingBackground = false;
      });
    }
  }

  @action
  public async uploadLogo(file: FormData) {
    this.loadingLogo = true;
    try {
      const { data } = await imagesService.uploadScreenLogo(file);
      runInAction(() => {
        this.logo = data.start.logo;
      });
      screenStore.load();
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loadingLogo = false;
      });
    }
  }

  @action
  public async uploadBackground(file: FormData, contentType: 'IMAGE' | 'VIDEO' = 'IMAGE', screenType = 'start') {
    this.loadingBackground = true;
    try {
      if (contentType === 'IMAGE') {
        const { data } = await imagesService.uploadScreenBackground(file, 'IMAGE', screenType);
        runInAction(() => {
          this.background[screenType] = data[screenType].background;
          screenStore.load();
        });
      }
      if (contentType === 'VIDEO') {
        const { data } = await imagesService.uploadScreenBackground(file, 'VIDEO');
        runInAction(() => {
          this.background[screenType] = data.start.background;
          screenStore.load();
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loadingBackground = false;
      });
    }
  }
}

export default new ImageScreensStore();
