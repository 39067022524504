import React from 'react';
import { Upload, notification, Icon, Spin, Popover, Alert } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { observer } from 'mobx-react-lite';

import { imageScreensStore } from '../../stores';

import './loadImg.global.scss';
import styles from './loadImg.module.scss';

type LoadImgState = {
  url: string | null;
  loading: boolean;
  originFile: any;
} & UploadProps;

interface LoadImgProps {
  title?: string;
  style?: string;
}

interface File {
  type: string;
  size: number;
}

const dummyRequest = ({ file, onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

const LoadImg = ({ title, style }: LoadImgProps) => {
  const [file, setFile] = React.useState<LoadImgState>({ originFile: null, url: null, loading: false });

  const getBase64 = (img: Blob, callback: CallableFunction) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (uploadFile: File) => {
    const isJpgOrPng = uploadFile.type === 'image/jpeg' || uploadFile.type === 'image/png';
    const isLt2M = uploadFile.size / 1024 / 1024 < 2;
    if (!isJpgOrPng) {
      notification.error({
        message: 'Помилка завантаження файлу',
        description: 'У Вас є можливість завантажувати тільки JPG/PNG файли!',
        duration: 0
      });
    }
    if (!isLt2M) {
      notification.error({
        message: 'Помилка завантаження файлу',
        description: 'Зображення має бути меньше ніж 2MB!',
        duration: 0
      });
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setFile({ originFile: null, url: null, loading: true });
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imgUrl: string) =>
        setFile({ originFile: info.file.originFileObj, url: imgUrl, loading: false })
      );
    }
  };

  const uploadButton = (
    <div className={style}>
      <div>
        <Icon className={styles.uploadIcon} type={file.loading ? 'loading' : 'plus'} />
        <div>{title}</div>
      </div>
    </div>
  );

  const { url, originFile } = file;
  if (originFile) {
    const formdata = new FormData();
    formdata.append('file', originFile);
    imageScreensStore.uploadLogo(formdata);
  }

  const getImg = () => {
    if (url) {
      return <img src={url} alt="avatar" className={style} />;
    } else if (imageScreensStore.logo) {
      if (imageScreensStore.loadingLogo) {
        return <Spin />;
      } else {
        return <img src={imageScreensStore.logo} alt="logo" className={style} />;
      }
    } else {
      return uploadButton;
    }
  };

  return (
    <div className="imgWrap">
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {!imageScreensStore.loadingLogo ? (
          <Popover
            className={styles.popover}
            content={
              <div className={styles.popoverContent}>{`Завантажений файл: ${imageScreensStore.logo &&
                imageScreensStore.logo
                  .split('/')
                  [imageScreensStore.logo && imageScreensStore.logo.split('/').length - 1].split('?')[0]}`}</div>
            }
            placement="topRight"
            title={
              <Alert
                message="Для завантаження логотипу кіоску застосовуються тільки зображення!"
                type="info"
                showIcon={true}
              />
            }
            trigger="hover"
          >
            {getImg()}
          </Popover>
        ) : (
          <Spin />
        )}
      </Upload>
    </div>
  );
};

export default observer(LoadImg);
