import * as React from 'react';
import { Menu as MenuAntd, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import queryString from 'querystring';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { MinusCircleOutlined, PlusCircleOutlined, ContainerOutlined } from '@ant-design/icons';

import './statisticList.global.scss';
import styles from './statisticList.module.scss';
import { statisticStore } from '../../stores';
import { deliveryTypeList, paymentTypeList, systemTypeList } from '../../types';
import { formatSumValues, tableTitles } from '../StatisticSummary';

const { SubMenu } = MenuAntd;

const StatisticList = () => {
  const currentLocation = useLocation();
  const filter = queryString.parse(currentLocation.search.substr(1, currentLocation.search.length));

  const payFilters: Array<{ text: string; value: string }> = [];
  Object.entries(paymentTypeList).forEach(([objKey, objValue]) => payFilters.push({ text: objValue, value: objKey }));
  const columns = [
    {
      title: 'ID',
      dataIndex: 'orderId',
      key: 'orderId',
      align: 'center' as 'center',
      sorter: (a: any, b: any) => a.orderId - b.orderId
    },
    {
      title: '№ в Тачкор',
      dataIndex: 'dailyNumber',
      key: 'dailyNumber',
      align: 'center' as 'center',
      sorter: (a: any, b: any) => a.dailyNumber - b.dailyNumber
    },
    {
      title: 'Тип',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
      align: 'center' as 'center'
    },
    {
      title: 'Оплата',
      dataIndex: 'payType',
      key: 'payType',
      align: 'center' as 'center',
      filters: payFilters,
      onFilter: (value: any, record: any) => value !== null && record.payType === paymentTypeList[value]
    },
    {
      title: 'Тип системи',
      dataIndex: 'systemType',
      key: 'systemType',
      align: 'center' as 'center'
    },
    {
      title: 'Сума',
      dataIndex: 'orderPrice',
      key: 'orderPrice',
      align: 'center' as 'center',
      sorter: (a: any, b: any) => a.orderPrice - b.orderPrice
    },
    {
      title: 'Дата',
      dataIndex: 'orderDate',
      key: 'orderDate',
      sorter: (a: any, b: any) => a.orderDate - b.orderDate
    },
    {
      title: 'Деталі замовлення',
      dataIndex: 'details',
      key: 'details',
      width: '45%'
    }
  ];

  const getDataSource = () => {
    const tableRows: any = [];
    if (statisticStore && statisticStore.statistic) {
      statisticStore.statistic.forEach((record: any, idx) => {
        const tableData = {
          key: record.orderId,
          orderId: record.orderId,
          dailyNumber: record.dailyNumber,
          details: (
            <MenuAntd mode="inline">
              <SubMenu key={`sub${record.orderId}`} title={'Деталі замовлення'}>
                {record.orderItems.map((dish: any, index: any) => {
                  return (
                    <React.Fragment key={'record-order-item-' + dish.title}>
                      <MenuAntd.Item key={index} className={styles.dishStatistic}>
                        <div />
                        <div>
                          <ContainerOutlined />
                          {dish.title}
                        </div>
                        <div>{dish.count}</div>
                        <div>{dish.price} ₴</div>
                      </MenuAntd.Item>
                      {dish.itemIngredients &&
                        dish.itemIngredients.map((ingridient: any) => {
                          return (
                            <MenuAntd.Item
                              key={ingridient.title}
                              className={styles.additionalDishStatistic}
                              style={{ paddingLeft: '25px' }}
                            >
                              <div />
                              <div>
                                {ingridient.ingredientType === 'ADDITIONAL' && <PlusCircleOutlined />}
                                {ingridient.ingredientType === 'MODIFIER' && <MinusCircleOutlined />}
                                {ingridient.title}
                              </div>
                              <div>{ingridient.amount}</div>
                              <div>{ingridient.price} ₴</div>
                            </MenuAntd.Item>
                          );
                        })}{' '}
                    </React.Fragment>
                  );
                })}
              </SubMenu>
            </MenuAntd>
          ),
          deliveryType: deliveryTypeList[record.deliveryType],
          payType: paymentTypeList[record.payType],
          systemType: systemTypeList[record.systemType],
          orderPrice: record.orderPrice + ' ₴',
          orderDate: moment(new Date(record.orderDate)).format('DD/MM/YYYY H:m:s')
        };
        if (
          filter.search !== undefined &&
          (record.orderId.toString().indexOf(filter.search) >= 0 ||
            record.dailyNumber.toString().indexOf(filter.search) >= 0)
        ) {
          tableRows.push(tableData);
        } else if (filter.search === undefined) {
          tableRows.push(tableData);
        }
      });
    }
    return tableRows;
  };

  const prepTable = () => {
    return (
      <table id="statistic" style={{ display: 'none' }}>
        <thead>
          <tr key={'heading'}>
            <th key={'num'}>{'№'}</th>
            {columns.map(column => (
              <th key={column.key}>
                {column.title}
                {column.key === 'details' && (
                  <table>
                    <tbody>
                      <tr>
                        <td>{'Товар'}</td>
                        <td>{'Категорія'}</td>
                        <td>{'Кількість'}</td>
                        <td>{'Ціна'}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {statisticStore?.statistic?.map((record: any, idx) => (
            <tr key={'statistic-row-' + idx}>
              <td>{idx + 1}</td>
              <td>{record.orderId}</td>
              <td>{record.dailyNumber}</td>
              <td>{deliveryTypeList[record.deliveryType]}</td>
              <td>{paymentTypeList[record.payType]}</td>
              <td>{systemTypeList[record.systemType]}</td>
              <td>{record.orderPrice}</td>
              <td>
                {new Date(record.orderDate)
                  .toISOString()
                  .slice(0, 19)
                  .replace(/-/g, '/')
                  .replace('T', ' ')}
              </td>
              <td>
                <table>
                  <tbody>
                    {record.orderItems.map((dish: any, index: any) => (
                      <React.Fragment key={'order-items-' + dish.title}>
                        <tr key={'statistic-sub-row' + index}>
                          <td>
                            <b>{dish.title}</b>
                          </td>
                          <td>{dish.categoryName}</td>
                          <td>{dish.count}</td>
                          <td>{dish.price}</td>
                        </tr>
                        {dish.itemIngredients &&
                          dish.itemIngredients.map((ingridient: any) => (
                            <tr key={ingridient.title}>
                              <td>{ingridient.title}</td>
                              <td />
                              <td>{ingridient.amount}</td>
                              <td>{ingridient.price}</td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          ))}
          {statisticStore && statisticStore.statisticSummary && (
            <React.Fragment key={'fragment-item'}>
              <tr key={'empty-1'}>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr key={'empty-2'}>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr key={'empty-3'}>
                <td colSpan={3}>
                  <b>Підсумок</b>
                </td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            </React.Fragment>
          )}
          {statisticStore &&
            statisticStore.statisticSummary &&
            Object.entries(statisticStore.statisticSummary).map(([key, value]) => (
              <tr key={'summary-' + key}>
                <td colSpan={3}>{tableTitles[key]}</td>
                <td align="right">
                  {formatSumValues(key, value)}
                  {key === 'avgReceipt' || key === 'ordersSum' ? ' грн' : ' од'}
                </td>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className={styles.wrap}>
      <div className="table">
        <Table
          className="statisticTable"
          locale={{ emptyText: 'Немає даних' }}
          dataSource={getDataSource()}
          columns={columns}
          pagination={{
            className: styles.tablePagination,
            position: 'top',
            defaultPageSize: 10,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true
          }}
        />
        {statisticStore && statisticStore.statistic && prepTable()}
        <ReactHTMLTableToExcel
          type="primary"
          className={styles.button}
          table="statistic"
          filename={`TouchCore-Statistic-${new Date()
            .toISOString()
            .slice(0, 19)
            .replace(/-/g, '/')
            .replace('T', ' ')}`}
          sheet="Sheet"
          buttonText="Експортувати в Excel"
        />
      </div>
    </div>
  );
};

export default observer(StatisticList);
