import axios from 'axios';
import { notification, message } from 'antd';

import settings from '../../../settings';
import { StatisticValues } from '../types';

class Statistic {
  public load = async (statisticValues: StatisticValues) => {
    try {
      const { data } = await axios.post(`${settings.domain}/api/admin/stat/load-sum`, statisticValues);
      if (data.message === 'Payments are not found') {
        message.warning('Не вдалося знайти жодного чеку. Спробуйте іншу дату.');
        return null;
      } else {
        message.success('Статистика успішно завантажена згідно оновлених значень!');
        return data.data;
      }
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося завантажити данні статистики. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new Statistic();
