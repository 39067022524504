// import { toJS } from 'mobx';

// TODO: types
export const changeArrayElementOrder = (array: any[], id: number, direction: 'UP' | 'DOWN') => {
  let activeElementOrder: any;
  let elementOrderToReplace: any;

  array.forEach((value: any, index: number) => {
    if (value.id === id && direction === 'UP') {
      if (array[index - 1]) {
        activeElementOrder = { ...array[index] };
        elementOrderToReplace = { ...array[index - 1] };
      }
    }
    if (value.id === id && direction === 'DOWN') {
      if (array[index + 1]) {
        activeElementOrder = { ...array[index] };
        elementOrderToReplace = { ...array[index + 1] };
      }
    }
  });

  if (activeElementOrder && elementOrderToReplace) {
    return array.map(element => {
      if (element.id === activeElementOrder.id) {
        return {
          ...element,
          order: elementOrderToReplace.order
        };
      } else if (element.id === elementOrderToReplace.id) {
        return {
          ...element,
          order: activeElementOrder.order
        };
      } else {
        return {
          ...element
        };
      }
    });
  }
  return [...array];
};
