import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'antd';

import settings from '../../../../settings';
import { AppLayout, Breadcrumbs, MaterialShadow } from '../../../common/components';
import Viewport from '../../../common/components/Viewport';

export interface PageAreaProps {
  content: JSX.Element;
  pageName?: string;
  breadLinks: Array<{
    name: string;
    url: string;
  }>;
}

const PageArea = ({ content, pageName, breadLinks }: PageAreaProps) => {
  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - ${pageName}`}</title>
        <meta name="description" content={`${settings.appName} - ${pageName}`} />
      </Helmet>

      <Row type="flex" justify="space-around">
        <Col span={24} xl={11}>
          <Breadcrumbs links={breadLinks} />
        </Col>
        <Col span={24} xl={11} />
      </Row>

      <Row type="flex" justify="space-around">
        <Col span={24} xl={14}>
          <MaterialShadow>{content}</MaterialShadow>
        </Col>
        <Col span={24} xl={9}>
          <MaterialShadow>
            <Viewport />
          </MaterialShadow>
        </Col>
      </Row>
    </AppLayout>
  );
};

export default PageArea;
