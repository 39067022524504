import * as React from 'react';

import PageArea from '../../components/PageArea';
import BinScreenForm from '../../components/BinScreenForm';

const BinScreenPage = () => {
  return (
    <PageArea
      content={<BinScreenForm />}
      pageName="Екран корзини"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/bin' },
        { name: 'Корзина', url: '/screens/bin' }
      ]}
    />
  );
};

export default BinScreenPage;
