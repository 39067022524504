import { observable, action, runInAction } from 'mobx';

import history from '../../../history';
import { authStore } from './';
import { loginService } from '../services';

class Login {
  @observable
  public loading: boolean = false;
  @observable
  public error: Error | null = null;
  @observable
  public badCredentials: boolean = false;

  @action public async login(values: { email: string; password: string }) {
    this.loading = true;

    try {
      const data = await loginService.login(values);
      if (data) {
        authStore.saveTokenToStore(data.userToken.authToken, data.userToken);
        history.push('/');
      }
      runInAction(() => {
        this.badCredentials = !data;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async logout(userId: number) {
    this.loading = true;

    try {
      const data = await loginService.logout(userId);
      authStore.removeTokenFromStore();
      history.push('/login');

      runInAction(() => {
        this.badCredentials = !data;
        this.loading = false;
      });
    } catch (error) {
      this.error = error;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new Login();
