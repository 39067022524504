import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';

import { dishStore } from '../../stores';
import PageArea from '../../components/PageArea';
import Edit from '../../components/Edit';
import styles from './editDishPage.module.scss';
import { categoryStore } from '../../../category/stores';

const DishPage = ({ match }: RouteComponentProps<{ id: string }>) => {
  useEffect(() => {
    dishStore.load(Number(match.params.id));
    // categoryStore.loadListWithSubcategories();
    categoryStore.loadList();
  }, [match.params.id]);
  return (
    <PageArea
      content={
        dishStore.loading ? (
          <div>
            <Skeleton className={styles.spinnerWrap} />
          </div>
        ) : (
          <Edit />
        )
      }
      pageName="Редагування страви"
      breadLinks={
        categoryStore.category
          ? [
              { name: 'Меню', url: '/' },
              {
                name: `# ${categoryStore.category ? categoryStore.category.name : ''}`,
                url: `/categories/edit/${categoryStore.category.id} `
              },
              { name: `${dishStore.dish ? dishStore.dish.name : ''}`, url: `/dishes/edit/${match.params.id} ` }
            ]
          : [
              { name: 'Меню', url: '/' },
              { name: `${dishStore.dish ? dishStore.dish.name : ''}`, url: `/dishes/edit/${match.params.id} ` }
            ]
      }
    />
  );
};

export default observer(DishPage);
