import { observable, action, runInAction } from 'mobx';

import history from '../../../history';

import { refreshKiosksService } from '../services';
import { leftMenuDrawerStore } from '.';

class RefreshKiosks {
  @observable
  public loading = false;
  @observable
  public error: Error | null = null;

  @action
  public refresh = async () => {
    this.loading = true;

    try {
      const data = await refreshKiosksService.refresh();
      if (data.code === 200) {
        runInAction(() => {
          this.loading = false;
        });
      }
      leftMenuDrawerStore.setMenuType(null);
      history.push('/');
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
  };
}

export default new RefreshKiosks();
