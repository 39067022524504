import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './breadcrumbs.module.scss';

interface BreadcrumbsProps {
  links: Array<{
    name: string | React.ReactNode;
    url: string;
  }>;
}

const Breadcrumbs = ({ links }: BreadcrumbsProps) => {
  return (
    <div className={styles.breadcrumbs}>
      <ul className={styles.breadcrumbsOne}>
        {links.map((link, index) => (
          <li key={index}>
            <Link to={link.url}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
