import React, { useEffect } from 'react';
import { Upload, Icon, Spin, notification, Popover, Alert } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { observer } from 'mobx-react-lite';

import { imageScreensStore, screenStore } from '../../stores';

import './loadImg.global.scss';
import styles from './loadImg.module.scss';

type LoadImgState = {
  url: string | null;
  loading: boolean;
  originFile: any;
} & UploadProps;

interface TypeImgState {
  type: string | null;
}

interface LoadImgProps {
  title?: string;
  style?: string;
  screenType?: string;
}

interface File {
  type: string;
  size: number;
}

const dummyRequest = ({ file, onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

const LoadImg = ({ title, style, screenType = 'start' }: LoadImgProps) => {
  useEffect(() => {
    if (
      screenStore.screens &&
      screenStore.screens[screenType] &&
      screenStore.screens[screenType].background &&
      screenStore.screens[screenType].background.imageUrl &&
      screenStore.screens[screenType].background.imageUrl !== 'string-persigned-url'
    ) {
      imageScreensStore.download(screenStore.screens[screenType].background.imageUrl, 'background', screenType);
    }
  }, []);

  const [fileType, setFileType] = React.useState<TypeImgState>({ type: null });
  const [file, setFile] = React.useState<LoadImgState>({ originFile: null, url: null, loading: false });

  const getBase64 = (img: Blob, callback: CallableFunction) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (uploadFile: File) => {
    const isJpgOrPng = uploadFile.type === 'image/jpeg' || uploadFile.type === 'image/png';
    const isLt2M = uploadFile.size / 1024 / 1024 < 2;
    const isLt100M = uploadFile.size / 1024 / 1024 < 100;
    if (!isLt100M) {
      notification.error({
        message: 'Помилка завантаження файлу',
        description: 'Відео має бути меньше ніж 100MB!',
        duration: 0
      });
    }
    if (!isLt2M && isJpgOrPng) {
      notification.error({
        message: 'Помилка завантаження файлу',
        description: 'Зображення має бути меньше ніж 2MB та тільки png/jpeg!',
        duration: 0
      });
    }
    if (isLt2M && isJpgOrPng) {
      setFileType({ type: 'image' });
      return isLt2M;
    }
    if (isLt100M) {
      setFileType({ type: 'video' });
      return isLt100M;
    }
    return isLt100M;
  };

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setFile({ originFile: null, url: null, loading: true });
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imgUrl: string) =>
        setFile({ originFile: info.file.originFileObj, url: imgUrl, loading: false })
      );
    }
  };

  const uploadButton = (
    <div className={style}>
      <div>
        <Icon className={styles.uploadIcon} type={file.loading ? 'loading' : 'plus'} />
        <div>{title}</div>
      </div>
    </div>
  );

  const { url, originFile } = file;
  const { type } = fileType;
  if (originFile) {
    const formdata = new FormData();
    formdata.append('file', originFile);
    if (type === 'image') {
      // add here screen type/name
      imageScreensStore.uploadBackground(formdata, 'IMAGE', screenType);
    }
    if (type === 'video') {
      imageScreensStore.uploadBackground(formdata, 'VIDEO');
    }
  }

  const getImg = () => {
    if (url) {
      if (screenStore.screens && screenStore.screens[screenType].background.videoUrl === 'string-persigned-url') {
        return <img src={url} alt="avatar" className={style} />;
      } else {
        return (
          <div>
            <Icon type="play-circle" />
            <div>
              {imageScreensStore.background[screenType] &&
                typeof imageScreensStore.background[screenType] !== 'object' &&
                imageScreensStore.background[screenType]
                  .split('/')
                  [
                    imageScreensStore.background[screenType] &&
                      imageScreensStore.background[screenType].split('/').length - 1
                  ].split('?')[0]}
            </div>
          </div>
        );
      }
    } else if (imageScreensStore.background[screenType]) {
      if (imageScreensStore.loadingBackground) {
        return <Spin />;
      } else {
        if (
          screenStore.screens &&
          screenStore.screens[screenType] &&
          screenStore.screens[screenType].background.videoUrl === 'string-persigned-url'
        ) {
          return <img src={imageScreensStore.background[screenType]} alt="background" className={style} />;
        } else {
          return (
            <div>
              <Icon type="play-circle" />
              <div>
                {imageScreensStore.background[screenType] &&
                  typeof imageScreensStore.background[screenType] !== 'object' &&
                  imageScreensStore.background[screenType]
                    .split('/')
                    [
                      imageScreensStore.background[screenType] &&
                        imageScreensStore.background[screenType].split('/').length - 1
                    ].split('?')[0]}
              </div>
            </div>
          );
        }
      }
    } else {
      return uploadButton;
    }
  };

  return (
    <div className="imgWrap">
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {!imageScreensStore.loadingBackground ? (
          <Popover
            className={styles.popover}
            content={
              <div className={styles.popoverContent}>{`Завантажений файл: ${imageScreensStore.background[screenType] &&
                typeof imageScreensStore.background[screenType] !== 'object' &&
                imageScreensStore.background[screenType]
                  .split('/')
                  [
                    imageScreensStore.background[screenType] &&
                      imageScreensStore.background[screenType].split('/').length - 1
                  ].split('?')[0]}`}</div>
            }
            placement="topRight"
            title={
              <Alert
                message="Для завантаження фону кіоску застосовуються зображення та відео!"
                type="info"
                showIcon={true}
              />
            }
            trigger="hover"
          >
            {getImg()}
          </Popover>
        ) : (
          <Spin />
        )}
      </Upload>
    </div>
  );
};

export default observer(LoadImg);
