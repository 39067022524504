import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import Storage from '../../../common/utils/Storage';
import { InputField } from '../../../common/components';
import { UpdateStart } from '../../types';
import { screenStore, imageScreensStore } from '../../stores';
import MenuLang from '../MenuLang';

import styles from './mainScreenForm.module.scss';
import ImgBackground from '../ImgBackground';
import ImgLogo from '../ImgLogo';

const MainScreenForm = (props: FormikProps<UpdateStart>) => {
  useEffect(() => {
    if (screenStore.screens && screenStore.screens.start) {
      imageScreensStore.download(screenStore.screens.start.logo, 'logo', 'start');
      imageScreensStore.download(
        screenStore.screens.start.background.imageUrl &&
          screenStore.screens.start.background.imageUrl !== 'string-persigned-url'
          ? screenStore.screens.start.background.imageUrl
          : screenStore.screens.start.background.videoUrl,
        'background',
        'start'
      );
    }
  }, []);
  const [activeLang, setActiveLang] = useState(String(Storage('sessionStorage').get('language')).toLowerCase());
  const { handleSubmit, setFieldTouched, touched, errors, setFieldValue, values } = props;

  const handleLangChange = (language: string) => {
    setActiveLang(language.toLowerCase());
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Стартовий екран</h3>
      <div className={styles.wrap}>
        <div className={styles.imgWrap}>
          <div>
            <div className={styles.title}>Логотип</div>
            <ImgLogo title="Додати логотип" style={styles.loadImg} />
          </div>
          <div>
            <div className={styles.title}>Фон</div>
            <ImgBackground title="Додати фон" style={styles.loadImg} screenType="start" />
          </div>
        </div>
        <div className={styles.buttonsWrap}>
          <div className={styles.wrapTopButtons}>
            <MenuLang onChange={handleLangChange} />
            {/* TODO:here must be OptionButton */}
            {/* <OptionButton /> */}
          </div>
          <div className={styles.wrapBottomButtons}>
            <div />
            <Button className={styles.saveButton} type="primary" htmlType="submit">
              Зберегти
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.fieldsWrap}>
          <InputField
            title={'Слоган'}
            placeholder={'Замовляй тут'}
            name="title"
            value={values.title[activeLang]}
            onBlur={() => setFieldTouched(`title.${activeLang}`)}
            errorMessage={touched.title && touched.title[activeLang] && errors.title && errors.title[activeLang]}
            onChange={event => {
              setFieldValue(`title.${activeLang}`, event.target.value);
              setFieldTouched(`title.${activeLang}`);
            }}
          />
          <InputField
            title={'Кнопка'}
            placeholder={'Розпочати'}
            name="buttonStart"
            value={values.buttonStart[activeLang]}
            onBlur={() => setFieldTouched(`buttonStart.${activeLang}`)}
            errorMessage={
              touched.buttonStart &&
              touched.buttonStart[activeLang] &&
              errors.buttonStart &&
              errors.buttonStart[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonStart.${activeLang}`, event.target.value);
              setFieldTouched(`buttonStart.${activeLang}`);
            }}
          />
        </div>
      </div>
    </form>
  );
};

const MainScreenFormWithFormik = withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    logo: screenStore.screens && screenStore.screens.start && screenStore.screens.start.logo,
    imageUrl: screenStore.screens && screenStore.screens.start && screenStore.screens.start.background.imageUrl,
    videoUrl: screenStore.screens && screenStore.screens.start && screenStore.screens.start.background.videoUrl,
    title: {
      en: screenStore.screens && screenStore.screens.start && screenStore.screens.start.title.en,
      pl: screenStore.screens && screenStore.screens.start && screenStore.screens.start.title.pl,
      ru: screenStore.screens && screenStore.screens.start && screenStore.screens.start.title.ru,
      uk: screenStore.screens && screenStore.screens.start && screenStore.screens.start.title.uk
    },
    buttonStart: {
      en: screenStore.screens && screenStore.screens.start && screenStore.screens.start.buttonStart.name.en,
      pl: screenStore.screens && screenStore.screens.start && screenStore.screens.start.buttonStart.name.pl,
      ru: screenStore.screens && screenStore.screens.start && screenStore.screens.start.buttonStart.name.ru,
      uk: screenStore.screens && screenStore.screens.start && screenStore.screens.start.buttonStart.name.uk
    }
  }),
  handleSubmit: ({ title, buttonStart, logo, imageUrl, videoUrl }) => {
    screenStore.update(
      {
        logo,
        background: {
          imageUrl,
          videoUrl
        },
        title: {
          en: title.en,
          pl: title.pl,
          ru: title.ru,
          uk: title.uk
        },
        buttonStart: {
          description:
            screenStore.screens && screenStore.screens.start && screenStore.screens.start.buttonStart.description,
          name: {
            en: buttonStart.en,
            pl: buttonStart.pl,
            ru: buttonStart.ru,
            uk: buttonStart.uk
          }
        }
      },
      'start'
    );
  },
  validationSchema: yup.object().shape<any>({
    title: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),

    buttonStart: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    })
  }),
  displayName: 'MainScreenForm'
})(observer(MainScreenForm));

export default MainScreenFormWithFormik;
