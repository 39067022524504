import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['UK', 'EN', 'PL', 'RU'],
    debug: true,
    resources: {
      UK: {
        translation: {
          button: 'Зберегти',
          name: 'Назва категорії',
          description: 'Опис категорії'
        }
      },
      EN: {
        translation: {
          button: 'Save',
          name: 'Category name',
          description: 'Category description'
        }
      },
      PL: {
        translation: {
          button: 'Zapisz',
          name: 'Nazwa kategorii',
          description: 'Opis kategorii'
        }
      },
      RU: {
        translation: {
          button: 'Сохранить',
          name: 'Название категории',
          description: 'Описание категории'
        }
      }
    }
  });

export default i18n;
