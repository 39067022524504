import { observable, action, runInAction } from 'mobx';

import history from '../../../history';
import { dishesService } from '../services';
import { Dish, Ingridient, UpdateDishValues } from '../types';
import { imagesService } from '../../common/services';
import { categoryStore } from '../../category/stores';
import Storage from '../../../modules/common/utils/Storage';

class DishStore {
  @observable
  public dish: Dish | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;
  @observable
  public stopList: number[] | null = null;

  @action public async load(dishId: number) {
    this.loading = true;

    try {
      let dish = await dishesService.load(dishId);

      if (dish.ingredients) {
        const imageList = await Promise.all(
          dish.ingredients.map((ingredient: Ingridient) => {
            if (ingredient.type === 'ADDITIONAL') {
              return imagesService.getImageUrlByPath(ingredient.url ? ingredient.url : '');
            } else {
              return null;
            }
          })
        );

        dish = {
          ...dish,
          ingredients: dish.ingredients.map((ingredient, index) => {
            return {
              ...ingredient,
              imageUrl: imageList[index] ? imageList[index].message : ''
            };
          })
        };
      }

      runInAction(() => {
        this.dish = dish;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async create(dishValues: UpdateDishValues) {
    this.loading = true;

    try {
      const dish = await dishesService.create(dishValues);
      if (dish === 208) {
        this.dish = null;
      } else {
        runInAction(() => {
          this.dish = dish.data;
        });
        history.push(`/dishes/edit/${dish.data.id}`);
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async update(updateValues: UpdateDishValues) {
    try {
      if (this.dish) {
        await dishesService.update(this.dish.id, updateValues);
        let dish = await dishesService.load(this.dish.id);

        if (dish.ingredients) {
          const imageList = await Promise.all(
            dish.ingredients.map((ingredient: Ingridient) => {
              if (ingredient.type === 'ADDITIONAL') {
                return imagesService.getImageUrlByPath(ingredient.url ? ingredient.url : '');
              } else {
                return null;
              }
            })
          );

          dish = {
            ...dish,
            ingredients: dish.ingredients.map((ingredient, index) => {
              return {
                ...ingredient,
                imageUrl: imageList[index] ? imageList[index].message : ''
              };
            })
          };
        }

        runInAction(() => {
          this.dish = dish;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async delete(dishId: number) {
    this.loading = true;
    try {
      await dishesService.delete(dishId);
      history.push(`/categories/edit/${categoryStore.category && categoryStore.category.id}`);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async updateWithoutRedirect(dishId: number, updateObject: Dish) {
    try {
      const { data } = await dishesService.updateWithoutRedirect(dishId, updateObject);
      runInAction(() => {
        this.dish = data;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async updateLanguage(categoryValues: UpdateDishValues, langType: string) {
    this.loading = true;
    try {
      if (this.dish) {
        await dishesService.updateLanguage(this.dish.id, categoryValues, langType);
        let dish = await dishesService.load(this.dish.id);

        if (dish.ingredients) {
          const imageList = await Promise.all(
            dish.ingredients.map((ingredient: Ingridient) => {
              if (ingredient.type === 'ADDITIONAL') {
                return imagesService.getImageUrlByPath(ingredient.url ? ingredient.url : '');
              } else {
                return null;
              }
            })
          );

          dish = {
            ...dish,
            ingredients: dish.ingredients.map((ingredient, index) => {
              return {
                ...ingredient,
                imageUrl: imageList[index] ? imageList[index].message : ''
              };
            })
          };
        }

        runInAction(() => {
          this.dish = dish;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async addToArhiv(hideFrom: number | null, hideTo: number | null, dish?: Dish) {
    this.loading = true;
    try {
      if (dish) {
        const { data } = await dishesService.addToArhiv(dish.id, hideFrom, hideTo);
        const newDish = { ...dish, archived: data.archived };
        runInAction(() => {
          this.dish = newDish;
        });
        categoryStore.load(categoryStore.category ? categoryStore.category.id : 0);
      }
      if (this.dish && !dish) {
        const { data } = await dishesService.addToArhiv(this.dish.id, hideFrom, hideTo);
        const newDish = { ...this.dish, archived: data.archived };
        runInAction(() => {
          this.dish = newDish;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async extractFromArchive(dish?: Dish) {
    this.loading = true;
    try {
      if (dish) {
        await dishesService.extractFromArchive(dish.id);
        const newDish = { ...dish, archived: false };
        runInAction(() => {
          this.dish = newDish;
        });
        categoryStore.load(categoryStore.category ? categoryStore.category.id : 0);
      }
      if (this.dish && !dish) {
        await dishesService.extractFromArchive(this.dish.id);
        const newDish = { ...this.dish, archived: false };
        runInAction(() => {
          this.dish = newDish;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async loadStopList() {
    this.loading = true;

    try {
      const list = await dishesService.loadStopList();

      runInAction(() => {
        this.stopList = list;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async addToStopList(dish: Dish | null) {
    this.loading = true;
    try {
      if (dish) {
        await dishesService.addToStopList(dish.id, Storage('sessionStorage').get('menu').locationId);
        this.loadStopList();
      }
      if (this.dish && !dish) {
        await dishesService.addToStopList(this.dish.id, Storage('sessionStorage').get('menu').locationId);
        this.loadStopList();
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async removeFromStopList(dish: Dish | null) {
    this.loading = true;
    try {
      if (dish) {
        await dishesService.removeFromStopList(dish.id, Storage('sessionStorage').get('menu').locationId);
        this.loadStopList();
      }
      if (this.dish && !dish) {
        await dishesService.removeFromStopList(this.dish.id, Storage('sessionStorage').get('menu').locationId);
        this.loadStopList();
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new DishStore();
