import React, { useEffect } from 'react';
import { Icon, Button } from 'antd';

import AddArchiveData from '../../../common/components/AddArchiveData';
import OptionsButton from '../../../common/components/OptionsButton';
import OptionButtonMenu from '../../../common/components/OptionsButtonMenu';

import styles from './dishOptions.module.scss';
import { dishStore } from '../../stores';

const DishOptions = () => {
  useEffect(() => {
    if (!dishStore.stopList) {
      dishStore.loadStopList();
    }
  }, []);
  return (
    <OptionsButton
      style={styles.optionsButton}
      content={
        <div className={styles.optionsMenuWrap}>
          {dishStore.dish && dishStore.dish.specialities ? (
            <Button
              className={styles.optionsMenuButton}
              type="primary"
              onClick={() => {
                if (dishStore.dish) {
                  dishStore.dish.specialities = !dishStore.dish.specialities;
                  dishStore.updateWithoutRedirect(dishStore.dish.id, dishStore.dish);
                }
              }}
            >
              не власний
            </Button>
          ) : (
            <Button
              className={styles.optionsMenuButton}
              type="primary"
              onClick={() => {
                if (dishStore.dish) {
                  dishStore.dish.specialities = !dishStore.dish.specialities;
                  dishStore.updateWithoutRedirect(dishStore.dish.id, dishStore.dish);
                }
              }}
            >
              власний
            </Button>
          )}
          <Button
            className={styles.optionsMenuButton}
            type="primary"
            onClick={() => {
              if (dishStore.dish) {
                dishStore.dish.topSeller = !dishStore.dish.topSeller;
                dishStore.updateWithoutRedirect(dishStore.dish.id, dishStore.dish);
              }
            }}
          >
            {dishStore.dish ? (dishStore.dish.topSeller ? 'видалити з обраних' : 'до обраних') : null}
          </Button>
          {dishStore.dish && !dishStore.stopList?.includes(dishStore.dish.id) ? (
            <OptionButtonMenu
              name="екстрена зупинка"
              placement="left"
              trigger="hover"
              icon={<Icon className={styles.icon} type="caret-left" />}
              content={
                <div className={styles.arhivationWrap}>
                  <Button
                    className={styles.optionsMenuButton}
                    type="primary"
                    onClick={() => dishStore.addToArhiv(Date.now(), 0)}
                  >
                    По всім локаціям
                  </Button>
                  <Button
                    className={styles.optionsMenuButton}
                    type="primary"
                    onClick={() => dishStore.addToStopList(dishStore.dish)}
                  >
                    В Стоп-лист локації
                  </Button>
                  {/* <AddArchiveData title={'В Стоп-лист локації'} dish={dishStore.dish} type="dish" /> */}
                </div>
              }
            />
          ) : (
            <Button
              className={styles.optionsMenuButton}
              type="primary"
              onClick={() => dishStore.removeFromStopList(dishStore.dish)}
            >
              Відновити зі стоп-ліста
            </Button>
          )}
          {dishStore.dish && !dishStore.dish.archived ? (
            <div>
              <OptionButtonMenu
                name="архівувати"
                placement="left"
                trigger="hover"
                icon={<Icon className={styles.icon} type="caret-left" />}
                content={
                  <div className={styles.arhivationWrap}>
                    <AddArchiveData title={'З вибраної дати'} type="dish" onlyFrom={true} />
                    <AddArchiveData title={'На вибраний період часу'} type="dish" />
                  </div>
                }
              />
            </div>
          ) : (
            <Button className={styles.optionsMenuButton} type="primary" onClick={() => dishStore.extractFromArchive()}>
              Відновити
            </Button>
          )}
        </div>
      }
    />
  );
};

export default DishOptions;
