import React from 'react';
import { Icon } from 'antd';
import { StopOutlined } from '@ant-design/icons';

import StatisticButtonMenu from '../../../common/components/StatisticButtonMenu';
import { categoryStore } from '../../stores';
import { dishStore } from '../../../dish/stores';

import styles from './statisticEdit.module.scss';

const StatisticEdit = () => {
  return (
    <div className={styles.statistic}>
      <StatisticButtonMenu
        trigger="click"
        name={`${
          categoryStore.category && categoryStore.category.menuItems ? categoryStore.category.menuItems.length : 0
        } страв`}
      />
      <StatisticButtonMenu
        trigger="click"
        name={`${
          categoryStore.category && categoryStore.category.menuItems
            ? categoryStore.category.menuItems.filter(item => item.archived).length
            : 0
        } архівовано`}
        icon={<Icon type="eye-invisible" />}
      />
      <StatisticButtonMenu
        trigger="click"
        name={`${
          categoryStore.category && categoryStore.category.menuItems
            ? categoryStore.category.menuItems.filter(item => dishStore.stopList?.includes(item.id)).length
            : 0
        } в стоп-лісті`}
        icon={<StopOutlined />}
      />
    </div>
  );
};

export default StatisticEdit;
