import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Radio, Table } from 'antd';
import { round } from 'lodash';

import styles from '../StatisticCharts/StatisticCharts.module.scss';
import { statisticStore } from '../../stores';
import {
  newCategoryRow,
  newMenuItemRow,
  prepareLegendTitles,
  prepareLegendTitlesShort
} from '../../services/ChartsPrepare';
import { IntervalChartHorizontalBarBizcharts } from '../StatisticChartsCommon/IntervalChartHorizontalBarBizcharts';
import { IntervalChartBizcharts } from '../StatisticChartsCommon/IntervalChartBizcharts';

interface ChartItem {
  key: string;
  title: string;
  count: number;
  percent: number;
  price: number;
  totalPrice: number;
  color: string;
  legendTitle: string;
}
interface CategoryChartItem {
  key: string;
  title: string;
  count: number;
  price: number;
  percent: number;
  color: string;
  legendTitle: string;
  menuItems: ChartItem[];
}

const StatisticChartsDashboardGoods = () => {
  const [chartType, setChartType] = useState(null as any);
  const [categories] = useState([] as CategoryChartItem[]);
  const [menuItems] = useState([] as ChartItem[]);

  const prepareDataForCategoriesCharts = () => {
    statisticStore?.statistic?.forEach((order: any) => {
      order.orderItems.forEach(oi => {
        let catIndex = categories && categories.findIndex((cat: any) => cat.title === oi.categoryName);
        if (catIndex < 0) {
          categories.push(newCategoryRow(oi.categoryName, categories.length));
          catIndex = categories && categories.findIndex((cat: any) => cat.title === oi.categoryName);
        }
        categories[catIndex].count += oi.count;
        categories[catIndex].price += oi.price * oi.count;
      });
    });
  };

  const prepareDataForMenuItemsCharts = () => {
    statisticStore.statistic?.forEach((order: any) => {
      order.orderItems.forEach(oi => {
        const catIndex = categories && categories.findIndex((cat: any) => cat.title === oi.categoryName);
        let productIndex = categories && categories[catIndex].menuItems.findIndex(item => item.title === oi.title);
        if (productIndex < 0) {
          categories[catIndex].menuItems.push(
            newMenuItemRow(
              oi.title,
              oi.price,
              categories[catIndex].menuItems.length.toString(),
              categories[catIndex].title
            )
          );
          productIndex = categories && categories[catIndex].menuItems.findIndex(item => item.title === oi.title);
        }
        categories[catIndex].menuItems[productIndex].count += oi.count;
      });
    });

    categories.map(category =>
      category.menuItems.map(menuItem => {
        menuItems.push(newMenuItemRow(menuItem.title, menuItem.price, menuItems.length, category.title));
        const menuItemIndex = menuItems && menuItems.findIndex((cat: any) => cat.title === menuItem.title);
        menuItems[menuItemIndex].count = menuItem.count;
      })
    );
  };

  const menuItemsColumns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      align: 'center' as 'center',
      width: '20px',
      sorter: (a: any, b: any) => a.key - b.key
    },
    {
      title: 'Позиція',
      dataIndex: 'title',
      key: 'title',
      align: 'left' as 'left'
    },
    {
      title: 'Категорія',
      dataIndex: 'categoryTitle',
      key: 'categoryTitle',
      align: 'left' as 'left'
    },
    {
      title: 'Продано, шт.',
      dataIndex: 'count',
      key: 'count',
      align: 'left' as 'left',
      sorter: (a: any, b: any) => a.count - b.count
    },
    {
      title: 'Продано, %',
      dataIndex: 'countPercent',
      key: 'countPercent',
      align: 'left' as 'left',
      sorter: (a: any, b: any) => a.countPercent - b.countPercent
    },
    {
      title: 'Виторг, ₴',
      dataIndex: 'price',
      key: 'price',
      align: 'left' as 'left',
      sorter: (a: any, b: any) => a.price - b.price
    },
    {
      title: 'Виторг, %',
      dataIndex: 'pricePercent',
      key: 'pricePercent',
      align: 'left' as 'left',
      sorter: (a: any, b: any) => a.pricePercent - b.pricePercent
    }
  ];
  const categoriesColumns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      align: 'left' as 'left',
      width: '20px',
      sorter: (a: any, b: any) => a.key - b.key
    },
    {
      title: 'Категорія',
      dataIndex: 'title',
      key: 'title',
      align: 'left' as 'left'
    },
    {
      title: 'Продано, шт.',
      dataIndex: 'count',
      key: 'count',
      align: 'left' as 'left',
      sorter: (a: any, b: any) => a.count - b.count
    },
    {
      title: 'Продано, %',
      dataIndex: 'countPercent',
      key: 'countPercent',
      align: 'left' as 'left',
      sorter: (a: any, b: any) => a.countPercent - b.countPercent
    },
    {
      title: 'Виторг',
      dataIndex: 'price',
      key: 'price',
      align: 'left' as 'left',
      sorter: (a: any, b: any) => a.price - b.price
    },
    {
      title: 'Виторг, %',
      dataIndex: 'pricePercent',
      key: 'pricePercent',
      align: 'left' as 'left',
      sorter: (a: any, b: any) => a.pricePercent - b.pricePercent
    }
  ];

  const getCategoriesDataSource = () => {
    if (statisticStore.loading === false && statisticStore && statisticStore.statistic && categories) {
      const totalCount = categories.reduce((sum, current: any) => {
        return sum + current.count;
      }, 0);
      const totalSumm = categories.reduce((sum, current: any) => {
        return sum + current.price;
      }, 0);
      return categories.map((record: any, idx) => ({
        key: idx + 1,
        title: record.title,
        count: record.count,
        countPercent: round((record.count / totalCount) * 100, 2),
        price: record.price,
        pricePercent: +round((record.price / totalSumm) * 100, 2)
      }));
    }
  };

  const getMenuItemsDataSource = () => {
    if (statisticStore.loading === false && statisticStore && statisticStore.statistic && menuItems) {
      const totalCount = menuItems.reduce((sum, current: any) => {
        return sum + current.count;
      }, 0);
      const totalSumm = menuItems.reduce((sum, current: any) => {
        return sum + current.price;
      }, 0);
      return menuItems.map((record: any, idx) => ({
        key: idx + 1,
        title: record.title,
        categoryTitle: record.categoryTitle,
        count: record.count,
        countPercent: round((record.count / totalCount) * 100, 2),
        price: record.price,
        pricePercent: round((record.price / totalSumm) * 100, 2)
      }));
    }
  };

  const renderMenuItemsByCountChart = () => {
    const sortByCount = menuItems.map(item => item);
    sortByCount.sort((a, b) => a.count - b.count);
    const byCount = prepareLegendTitlesShort(sortByCount);
    return (
      <section className={styles.chartWrapper}>
        <h2>Товари, кількість</h2>
        <IntervalChartHorizontalBarBizcharts list={byCount} config={{ color: 'legendTitle', count: 'count' }} />
      </section>
    );
  };

  const renderMenuItemsByPriceChart = () => {
    const sortByPrice = menuItems.map(item => item);
    sortByPrice.sort((a, b) => a.totalPrice - b.totalPrice);
    const byPrice = prepareLegendTitlesShort(sortByPrice);
    return (
      <section className={styles.chartWrapper}>
        <h2>Товари, вартість ₴</h2>
        <IntervalChartHorizontalBarBizcharts list={byPrice} config={{ color: 'legendTitle', count: 'totalPrice' }} />
      </section>
    );
  };
  const renderMenuItemsHorizChart = () => {
    return (
      <section className={styles.chartsWrapper}>
        <section className={styles.oneLineWithTwo}>
          {renderMenuItemsByCountChart()}
          {renderMenuItemsByPriceChart()}
        </section>
      </section>
    );
  };

  const renderMenuItemsRadialChart = () => {
    return (
      <section className={styles.oneLineWithOne}>
        <h2>За категоріями в розрізі товарів</h2>
        {categories.map(category => {
          return (
            <section key={'category-wrapper-' + category.key} className={styles.chartWrapper}>
              <h2>{category.title}</h2>
              <IntervalChartBizcharts
                title={category.title}
                list={prepareLegendTitles(category.menuItems)}
                config={{
                  height: 450,
                  width: 350,
                  position: 'countPercent',
                  color: 'legendTitle',
                  legendPosition: 'right',
                  radius: 0.8,
                  itemWidth: 250
                }}
              />
            </section>
          );
        })}
      </section>
    );
  };

  const renderCategoriesRadialChart = () => {
    return (
      <section className={styles.oneLineWithOne}>
        <section className={styles.chartWrapper}>
          <h2>Категорії</h2>
          <IntervalChartBizcharts
            list={prepareLegendTitles(categories)}
            config={{
              height: 600,
              width: 1000,
              position: 'countPercent',
              color: 'legendTitle'
            }}
          />
        </section>
      </section>
    );
  };

  const handleSizeChange = e => {
    setChartType(e.target.value);
  };

  useEffect(() => {
    if (statisticStore.loading === false && statisticStore && statisticStore.statistic) {
      prepareDataForCategoriesCharts();
      prepareDataForMenuItemsCharts();
      setChartType('menuItem');
    }
  }, [statisticStore.loading]);

  return (
    <section className={styles.chartWrapper}>
      <Radio.Group className={styles.switchButtons} value={chartType} onChange={handleSizeChange}>
        <Radio.Button value="menuItem">Позиція</Radio.Button>
        <Radio.Button value="category">Категорія</Radio.Button>
      </Radio.Group>
      {chartType === 'menuItem' && renderMenuItemsHorizChart()}
      <div className="table">
        <Table
          className="statisticTable"
          locale={{ emptyText: 'Немає даних' }}
          dataSource={chartType === 'category' ? getCategoriesDataSource() : getMenuItemsDataSource()}
          columns={chartType === 'category' ? categoriesColumns : menuItemsColumns}
          pagination={{
            className: styles.tablePagination,
            position: 'top',
            defaultPageSize: 10,
            pageSizeOptions: ['10', '20', '30', '40', '50', '60', '100'],
            showSizeChanger: true
          }}
        />
      </div>
      {chartType === 'menuItem' && renderMenuItemsRadialChart()}
      {chartType === 'category' && renderCategoriesRadialChart()}
    </section>
  );
};

export default observer(StatisticChartsDashboardGoods);
