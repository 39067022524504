import React, { ReactNode } from 'react';
import { Popover, Button } from 'antd';

import styles from './statisticButtonMenu.module.scss';

interface StatisticButtonMenuProps {
  name?: string;
  needName?: string;
  content?: ReactNode;
  trigger?: any;
  placement?: any;
  style?: string;
  needStyle?: string;
  icon?: ReactNode;
}

const StatisticButtonMenu = ({
  name,
  needName,
  content,
  trigger,
  placement,
  style,
  needStyle,
  icon
}: StatisticButtonMenuProps) => {
  return (
    <Popover className={styles.popover} placement={placement} trigger={trigger} content={content}>
      <Button className={styles.popverButton} size="small">
        <div className={needStyle}>{needName}</div>
        <div className={style}>
          {icon} {name}
        </div>
      </Button>
    </Popover>
  );
};

export default StatisticButtonMenu;
