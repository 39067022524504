import axios from 'axios';
import { notification } from 'antd';

import settings from '../../../settings';

class Images {
    public getImageUrlByPath = async (storagePath: string) => {
        try {
            const { data } = await axios.post(
                `${
                    settings.domain
                }/api/admin/image/storage/download?storagePath=${storagePath ||
                    ''}`
            );
            return data;
        } catch (error) {
            return { code: 0, message: null };
        }
    };

    public uploadСategoryImg = async (file: FormData, categoryId: number) => {
        try {
            const {
                data,
            } = await axios.post(
                `${settings.domain}/api/admin/image/storage/upload-category/${categoryId}/${categoryId}`,
                file,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            return data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка.',
                description:
                    'Не вдалося завантажити зображення. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public uploadDishImg = async (file: FormData, dishId: number) => {
        try {
            const { data } = await axios.post(
                `${settings.domain}/api/admin/image/storage/upload-menu-item/${dishId}/${dishId}`,
                file,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );
            return data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка.',
                description:
                    'Не вдалося завантажити зображення. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public uploadIngridientImg = async (
        file: FormData,
        ingredientId: number
    ) => {
        try {
            const {
                data,
            } = await axios.post(
                `${settings.domain}/api/admin/image/storage/upload-ingredient/${ingredientId}/${ingredientId}`,
                file,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            return data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка.',
                description:
                    'Не вдалося завантажити зображення. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public uploadPitstopLogoImg = async (
        file: FormData,
        organizationId: number
    ) => {
        try {
            const {
                data,
            } = await axios.post(
                `${settings.domain}/api/admin/image/storage/upload-organization/${organizationId}/LOGO/`,
                file,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            return data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка.',
                description:
                    'Не вдалося завантажити зображення. Спробуйте пізніше.',
            });
            throw error;
        }
    };
    public uploadPitstopBackgroundImg = async (
        file: FormData,
        organizationId: number
    ) => {
        try {
            const {
                data,
            } = await axios.post(
                `${settings.domain}/api/admin/image/storage/upload-organization/${organizationId}/BACKGROUND/`,
                file,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            return data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка.',
                description:
                    'Не вдалося завантажити зображення. Спробуйте пізніше.',
            });
            throw error;
        }
    };
}

export default new Images();
