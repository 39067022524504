import React from 'react';
import { Button, Form, DatePicker } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import SelectField from '../../../common/components/SelectField';
import styles from './filter.module.scss';
import { feedbackStore } from '../../stores';
import { FeedbackValues } from '../../types';
import { userStore } from '../../../authentication/stores';

const Filter = (props: FormikProps<FeedbackValues>) => {
  const { handleSubmit, setFieldTouched, setFieldValue } = props;

  // useEffect(() => {
  //   categoryStore.loadListWithSubcategories();
  // }, []);
  return (
    <Form onSubmit={handleSubmit}>
      <div className={styles.wrap}>
        <div className={styles.selectsWrap}>
          <SelectField
            title={'В локації/ях'}
            defaultValue={'Всі локації'}
            size="small"
            options={
              userStore.user && userStore.user.locations
                ? [{ name: 'Всі локації', id: 'null' }, ...userStore.user.locations]
                : []
            }
            wrapperStyle={{ margin: '0px 5px', width: '50%' }}
            onChange={value => {
              setFieldValue('locationIds', value !== 'null' ? [value] : null);
            }}
          />
          <div className={styles.date}>
            <div className={styles.dateTitle}>За період</div>
            <div className={styles.dateFieldsWrap}>
              <DatePicker
                placeholder={'Початок'}
                format={'YYYY/MM/DD'}
                defaultValue={moment(new Date(), 'YYYY/MM/DD')}
                size="small"
                className={styles.dateField}
                onOpenChange={() => setFieldTouched('startDateTime')}
                onChange={(_, dateString) => setFieldValue('startDateTime', new Date(dateString).setHours(0, 0, 0, 0))}
              />
              <DatePicker
                placeholder={'Кінець'}
                format={'YYYY/MM/DD'}
                defaultValue={moment(new Date(), 'YYYY/MM/DD')}
                size="small"
                className={styles.dateField}
                onOpenChange={() => setFieldTouched('endDateTime')}
                onChange={(_, dateString) =>
                  setFieldValue('endDateTime', new Date(dateString).setHours(23, 59, 59, 999))
                }
              />
            </div>
          </div>
          <Button type="primary" htmlType="submit" className={styles.button}>
            Пошук
          </Button>
        </div>
      </div>
    </Form>
  );
};

const FilterWithFormik = withFormik<any, FeedbackValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    endDateTime: new Date().setHours(23, 59, 59, 999),
    locationIds: [],
    orgId: userStore.user && userStore.user.organizationId,
    startDateTime: new Date().setHours(0, 0, 0, 0)
  }),
  handleSubmit: values => {
    feedbackStore.load({
      locationIds: values.locationIds || [],
      orgId: userStore.user && userStore.user.organizationId,
      startDateTime: values.startDateTime || new Date().setHours(0, 0, 0, 0),
      endDateTime: values.endDateTime || new Date().setHours(23, 59, 59, 999)
    });
  },
  displayName: 'filter options'
})(observer(Filter));

export default FilterWithFormik;
