import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Switch } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import Storage from '../../../common/utils/Storage';
import { InputField } from '../../../common/components';
import { UpdateGetOrderTable } from '../../types';
import { screenStore } from '../../stores';
import MenuLang from '../MenuLang';

import styles from './getOrderTableForm.module.scss';
import SharedBgImage from '../SharedBgImage';
// import OptionButton from '../../../common/components/OptionsButton';

const GetOrderTableForm = (props: FormikProps<UpdateGetOrderTable>) => {
  const [activeLang, setActiveLang] = useState(String(Storage('sessionStorage').get('language')).toLowerCase());
  const { handleSubmit, setFieldTouched, touched, errors, setFieldValue, values } = props;

  const handleLangChange = (language: string) => {
    setActiveLang(language.toLowerCase());
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Отримання замовленя за столом</h3>
      <div className={styles.wrap}>
        <div className={styles.fieldsWrap}>
          <InputField
            title={'Повідомлення замовлення'}
            placeholder={'Номер вашого замовлення:'}
            name="descriptionOrder"
            value={values.descriptionOrder[activeLang]}
            onBlur={() => setFieldTouched(`descriptionOrder.${activeLang}`)}
            errorMessage={
              touched.descriptionOrder &&
              touched.descriptionOrder[activeLang] &&
              errors.descriptionOrder &&
              errors.descriptionOrder[activeLang]
            }
            onChange={event => {
              setFieldValue(`descriptionOrder.${activeLang}`, event.target.value);
              setFieldTouched(`descriptionOrder.${activeLang}`);
            }}
          />
          <InputField
            title={'Повідомлення номера таблички'}
            placeholder={'Табличка з номерком:'}
            name="descriptionTable"
            value={values.descriptionTable[activeLang]}
            onBlur={() => setFieldTouched(`descriptionTable.${activeLang}`)}
            errorMessage={
              touched.descriptionTable &&
              touched.descriptionTable[activeLang] &&
              errors.descriptionTable &&
              errors.descriptionTable[activeLang]
            }
            onChange={event => {
              setFieldValue(`descriptionTable.${activeLang}`, event.target.value);
              setFieldTouched(`descriptionTable.${activeLang}`);
            }}
          />
          <InputField
            title={'Інструкція'}
            placeholder={'Поставте табличку на стіл щоб офіціант зміг вас знайти'}
            name="instruction"
            value={values.instruction[activeLang]}
            onBlur={() => setFieldTouched(`instruction.${activeLang}`)}
            errorMessage={
              touched.instruction &&
              touched.instruction[activeLang] &&
              errors.instruction &&
              errors.instruction[activeLang]
            }
            onChange={event => {
              setFieldValue(`instruction.${activeLang}`, event.target.value);
              setFieldTouched(`instruction.${activeLang}`);
            }}
          />
          <SharedBgImage screenType="getOrderTable" />
        </div>
        <div className={styles.buttonsWrap}>
          <div className={styles.wrapTopButtons}>
            <MenuLang onChange={handleLangChange} />
            {/* TODO:here must be OptionButton */}
            {/* <OptionButton /> */}
          </div>
          <div className={styles.wrapBottomButtons}>
            <Switch
              defaultChecked={
                screenStore.screens && screenStore.screens.getOrderTable
                  ? screenStore.screens.getOrderTable.switchOff
                  : false
              }
              onChange={() =>
                screenStore.update(
                  {
                    ...(screenStore.screens && screenStore.screens.getOrderTable),
                    switchOff: screenStore.screens && !screenStore.screens.getOrderTable.switchOff
                  },
                  'getOrderTable'
                )
              }
            />
            <Button className={styles.saveButton} type="primary" htmlType="submit">
              Зберегти
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const GetOrderTableFormWithFormik = withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    imageUrl:
      (screenStore.screens &&
        screenStore.screens.getOrderTable &&
        screenStore.screens.getOrderTable.background?.imageUrl) ||
      null,
    descriptionOrder: {
      en:
        screenStore.screens &&
        screenStore.screens.getOrderTable &&
        screenStore.screens.getOrderTable.descriptionOrder.en,
      pl:
        screenStore.screens &&
        screenStore.screens.getOrderTable &&
        screenStore.screens.getOrderTable.descriptionOrder.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.getOrderTable &&
        screenStore.screens.getOrderTable.descriptionOrder.ru,
      uk:
        screenStore.screens &&
        screenStore.screens.getOrderTable &&
        screenStore.screens.getOrderTable.descriptionOrder.uk
    },
    descriptionTable: {
      en:
        screenStore.screens &&
        screenStore.screens.getOrderTable &&
        screenStore.screens.getOrderTable.descriptionTable.en,
      pl:
        screenStore.screens &&
        screenStore.screens.getOrderTable &&
        screenStore.screens.getOrderTable.descriptionTable.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.getOrderTable &&
        screenStore.screens.getOrderTable.descriptionTable.ru,
      uk:
        screenStore.screens &&
        screenStore.screens.getOrderTable &&
        screenStore.screens.getOrderTable.descriptionTable.uk
    },
    instruction: {
      en: screenStore.screens && screenStore.screens.getOrderTable && screenStore.screens.getOrderTable.instruction.en,
      pl: screenStore.screens && screenStore.screens.getOrderTable && screenStore.screens.getOrderTable.instruction.pl,
      ru: screenStore.screens && screenStore.screens.getOrderTable && screenStore.screens.getOrderTable.instruction.ru,
      uk: screenStore.screens && screenStore.screens.getOrderTable && screenStore.screens.getOrderTable.instruction.uk
    }
  }),
  handleSubmit: ({ descriptionOrder, descriptionTable, instruction, imageUrl }) => {
    screenStore.update(
      {
        descriptionOrder: {
          en: descriptionOrder.en,
          pl: descriptionOrder.pl,
          ru: descriptionOrder.ru,
          uk: descriptionOrder.uk
        },
        descriptionTable: {
          en: descriptionTable.en,
          pl: descriptionTable.pl,
          ru: descriptionTable.ru,
          uk: descriptionTable.uk
        },
        instruction: {
          en: instruction.en,
          pl: instruction.pl,
          ru: instruction.ru,
          uk: instruction.uk
        },
        background: {
          imageUrl
        }
      },
      'getOrderTable'
    );
  },
  validationSchema: yup.object().shape<any>({
    descriptionOrder: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    descriptionTable: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    instruction: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    })
  }),
  displayName: 'GetOrderTableForm'
})(observer(GetOrderTableForm));

export default GetOrderTableFormWithFormik;
