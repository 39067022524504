import { round } from 'lodash';
import moment from 'moment';

import { colorsArray } from '../types';

export const getRangeOfDates = (start, end, key, arr = [start.startOf(key)]) => {
  if (start.isAfter(end)) {
    throw new Error('start must precede end');
  }
  const next = moment(start)
    .add(1, key)
    .startOf(key);
  if (next.isAfter(end, key)) {
    return arr;
  }
  return getRangeOfDates(next, end, key, arr.concat(next));
};

// preparation for general charts -paytype/delivery/system
export const prepareLegendTitles = list => {
  let totalCount = 0;
  let totalPrice = 0;
  list.forEach(item => (totalCount += item.count));
  list.forEach(item => (totalPrice += item.price));
  list.forEach(item => {
    item.countPercent = round((item.count / totalCount) * 100, 2);
    item.pricePercent = round((item.price / totalPrice) * 100, 2);
    item.legendTitle = item.title + '\n\r' + item.count + '-' + item.countPercent + '%';
    item.legendTotalTitle = item.title + '\n\r' + item.price + '₴-' + item.pricePercent + '%';
  });
  return list;
};

export const prepareLegendTitlesShort = list => {
  let totalPoints = 0;
  list.forEach(item => (totalPoints += item.count));
  list.forEach(item => {
    item.totalPrice = item.count * item.price;
    item.percent = round((item.count / totalPoints) * 100, 2);
    item.legendTitle = item.title;
  });
  return list;
};

export const formatDate = date => {
  const parsed = date.toString().split('/');
  return parsed[2] + '.' + parsed[1] + '.' + parsed[0];
};

export const prepareLists = list => {
  return Object.entries(list).map(([type, typeLabel], key) => {
    return {
      key: type,
      title: typeLabel,
      count: 0,
      percent: 0,
      color: colorsArray[key],
      legendTitle: '',
      price: 0
    };
  });
};

export const prepareHoursArray = () => {
  const list = [] as any[];
  for (let i = 0; i < 24; i++) {
    list.push({
      from: i < 10 ? '0' + i : i.toString(),
      to: i + 1 < 10 ? '0' + (i + 1) : (i + 1).toString(),
      ordersCount: 0,
      priceCount: 0
    });
  }
  return list.reverse();
};

export const prepareDatesLegendTitles = list => {
  list.forEach((item, itemIndex) => {
    item.ordersLegendTitle = formatDate(item.day) + ' ';
  });
  return list;
};

export const prepareHoursLegendTitles = list => {
  list.forEach((item, itemIndex) => {
    item.ordersLegendTitle = item.from + ':00-' + item.to + ':00';
    // item.priceLegendTitle = item.from + ':00-' + item.to + ':00';
  });
  return list;
};

export const prepareLegendTitlesByOrderCount = list => {
  let totalOrderCount = 0;
  let totalMenuItemsCount = 0;
  let totalPriceCount = 0;
  list.forEach(item => {
    totalOrderCount += item.ordersCount;
    totalPriceCount += item.priceCount;
    totalMenuItemsCount += item.menuItemsCount;
  });
  list.forEach(item => {
    item.ordersPercent = round((item.ordersCount / totalOrderCount) * 100, 2);
    item.pricePercent = round((item.priceCount / totalPriceCount) * 100, 2);
    item.menuItemsPercent = round((item.menuItemsCount / totalMenuItemsCount) * 100, 2);

    item.ordersLegendTitle = item.title + ' - ' + item.ordersCount;
    item.priceLegendTitle = item.title + ' -' + item.priceCount + '₴';
    item.menuItemsLegendTitle = item.title + ' -' + item.menuItemsCount;

    // avarage points
    item.avgMenuItemsInOrder = round(item.menuItemsCount / item.ordersCount, 2);
    item.avgMenuItemsInOrderLegendTitle = item.title;
    item.avgReceipt = round(item.priceCount / item.ordersCount, 2);
    item.avgReceiptLegendTitle = item.title + '₴';
  });
  return list;
};

export const newCategoryRow = (y, index, optionalIndex = index) => {
  return {
    key: index,
    title: y,
    count: 0,
    price: 0,
    percent: 0,
    legendTitle: '',
    color: colorsArray[optionalIndex],
    menuItems: []
  };
};

export const newMenuItemRow = (y, price, index, categoryTitle) => {
  return {
    key: y,
    title: y,
    count: 0,
    percent: 0,
    price,
    totalPrice: 0,
    color: colorsArray[index],
    legendTitle: '',
    categoryTitle
  };
};

export const prepareDatesArray = (orderDate, orderPrice, byDatesHours) => {
  const formattedDate = new Date(orderDate)
    .toISOString()
    .slice(0, 19)
    .replace(/-/g, '/')
    .replace('T', ' ');
  let oDate =
    byDatesHours &&
    byDatesHours.findIndex(
      (date: any) => new Date(date.day).setHours(0, 0, 0, 0) === new Date(formattedDate).setHours(0, 0, 0, 0)
    );

  if (oDate < 0) {
    byDatesHours.push({
      day: formattedDate.split(' ')[0],
      ordersCount: 0,
      priceCount: 0
    });
    oDate =
      byDatesHours &&
      byDatesHours.findIndex(
        (date: any) => new Date(date.day).setHours(0, 0, 0, 0) === new Date(formattedDate).setHours(0, 0, 0, 0)
      );
  }
  byDatesHours[oDate].priceCount += orderPrice;
  byDatesHours[oDate].ordersCount += 1;

  return byDatesHours;
};

export const updateHoursArray = (orderDate, orderPrice, byHoursRange) => {
  const formattedDate = new Date(orderDate)
    .toISOString()
    .slice(0, 19)
    .replace(/-/g, '/')
    .replace('T', ' ');
  const hours = formattedDate.split(' ')[1].split(':');
  const hIndex = byHoursRange && byHoursRange.findIndex(item => item.from === hours[0]);
  byHoursRange[hIndex].priceCount += orderPrice;
  byHoursRange[hIndex].ordersCount += 1;

  return byHoursRange;
};

export const updateLocationsArray = (locations, locationId, locationName, orderPrice) => {
  let locIndex = locations && locations.findIndex((loc: any) => loc.key === locationId);
  if (locIndex < 0) {
    locations.push({
      key: locationId,
      title: locationName,
      ordersCount: 0,
      ordersPercent: 0,
      ordersAngle: 0,
      ordersLegendTitle: '',
      priceCount: 0,
      pricePercent: 0,
      pricesAngle: 0,
      priceLegendTitle: '',
      menuItemsCount: 0,
      menuItemsPercent: 0,
      menuItemsAngle: 0,
      menuItemsLegendTitle: '',
      color: colorsArray[locations.length]
    });
    locIndex = locations && locations.findIndex((loc: any) => loc.key === locationId);
  }
  locations[locIndex].priceCount += orderPrice;
  locations[locIndex].ordersCount += 1;

  return locations;
};
