import React, { useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { observer } from 'mobx-react-lite';

import styles from './refreshKiosksModal.module.scss';
import './refreshKiosksModal.global.scss';
import { refreshKioskStore } from '../../stores';

const RefreshKiosksModal = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="synchronize-modal">
      <Button
        className={styles.syncButton}
        icon="sync"
        type="primary"
        ghost={true}
        onClick={() => {
          refreshKioskStore.refresh();
          setOpen(true);
        }}
      >
        Оновити кіоски
      </Button>

      <Modal
        visible={!isOpen ? isOpen : refreshKioskStore.loading}
        onCancel={() => setOpen(false)}
        centered={true}
        footer={null}
        closable={false}
      >
        <div className={styles.modalContent}>
          <Spin size={'large'} />
          <div className={styles.modalText}>Дочекайтесь будь ласка завершення оновлення кіосків</div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(RefreshKiosksModal);
