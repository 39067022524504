import React, { useEffect } from 'react';
import { Button, Form, DatePicker } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { categoryStore } from '../../../category/stores';

import SelectField from '../../../common/components/SelectField';
import styles from './filterOptions.module.scss';
import { statisticStore } from '../../stores';
import { deliveryTypeList, paymentTypeList, StatisticValues, systemTypeList } from '../../types';
import { userStore } from '../../../authentication/stores';

const FilterOptions = (props: FormikProps<StatisticValues>) => {
  const { handleSubmit, setFieldTouched, setFieldValue } = props;

  useEffect(() => {
    categoryStore.loadListWithSubcategories();
  }, []);
  return (
    <Form onSubmit={handleSubmit}>
      <div className={styles.wrap}>
        <div className={styles.selectsWrap}>
          <SelectField
            title={'В локації/ях'}
            defaultValue={'Всі локації'}
            size="small"
            options={
              userStore.user && userStore.user.locations
                ? [{ name: 'Всі локації', id: 'null' }, ...userStore.user.locations]
                : []
            }
            wrapperStyle={{ margin: '0px 5px', width: '50%' }}
            onBlur={() => setFieldTouched('newCategoryId')}
            onChange={value => {
              setFieldValue('locationIds', value !== 'null' ? [value] : null);
            }}
          />
          <SelectField
            title={'В категорії/ях'}
            defaultValue={'Всі категорії'}
            mode="multiple"
            size="small"
            options={
              categoryStore.categories
                ? [
                    { name: 'Всі категорії', id: 'null' },
                    ...categoryStore.categories.filter(cat => cat.archived === false)
                  ]
                : []
            }
            wrapperStyle={{ margin: '0px 5px', width: '50%' }}
            onBlur={() => setFieldTouched('newCategoryId')}
            onChange={value => {
              setFieldValue('categoryIds', value[0] !== 'null' ? value : null);
            }}
          />
          <div className={styles.date}>
            <div className={styles.dateTitle}>За період</div>
            <div className={styles.dateFieldsWrap}>
              <DatePicker
                placeholder={'Початок'}
                format={'YYYY/MM/DD'}
                defaultValue={moment(new Date(), 'YYYY/MM/DD')}
                size="small"
                className={styles.dateField}
                onOpenChange={() => setFieldTouched('startDateTime')}
                onChange={(_, dateString) => setFieldValue('startDateTime', new Date(dateString).setHours(0, 0, 0, 0))}
              />
              <DatePicker
                placeholder={'Кінець'}
                format={'YYYY/MM/DD'}
                defaultValue={moment(new Date(), 'YYYY/MM/DD')}
                size="small"
                className={styles.dateField}
                onOpenChange={() => setFieldTouched('endDateTime')}
                onChange={(_, dateString) =>
                  setFieldValue('endDateTime', new Date(dateString).setHours(23, 59, 59, 999))
                }
              />
            </div>
          </div>
          <SelectField
            title={'Тип системи'}
            defaultValue={'Всі'}
            size="small"
            options={[
              { name: 'Всі', id: 'null' },
              ...Object.entries(systemTypeList).map(([objKey, objValue]) => ({ name: objValue, id: objKey }))
            ]}
            wrapperStyle={{ margin: '0px 5px', width: '50%' }}
            onChange={value => {
              setFieldValue('systemType', value !== 'null' ? value : null);
            }}
          />
          <SelectField
            title={'Спосіб оплати'}
            defaultValue={'Всі'}
            size="small"
            options={[
              { name: 'Всі', id: 'null' },
              ...Object.entries(paymentTypeList).map(([objKey, objValue]) => ({ name: objValue, id: objKey }))
            ]}
            wrapperStyle={{ margin: '0px 5px', width: '50%' }}
            onChange={value => {
              setFieldValue('payType', value !== 'null' ? value : null);
            }}
          />
          <SelectField
            title={'Тип замовлення'}
            defaultValue={'Всі'}
            size="small"
            options={[
              { name: 'Всі', id: 'null' },
              ...Object.entries(deliveryTypeList).map(([objKey, objValue]) => ({ name: objValue, id: objKey }))
            ]}
            wrapperStyle={{ margin: '0px 5px', width: '50%' }}
            onChange={value => {
              setFieldValue('deliveryType', value !== 'null' ? value : null);
            }}
          />
          <Button type="primary" htmlType="submit" className={styles.button}>
            Пошук
          </Button>
        </div>
      </div>
    </Form>
  );
};

const FilterOptionsWithFormik = withFormik<any, StatisticValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    deliveryType: null,
    endDateTime: new Date().setHours(23, 59, 59, 999),
    locationIds: [],
    categoryIds: [],
    orgId: userStore.user && userStore.user.organizationId,
    payType: null,
    systemType: null,
    startDateTime: new Date().setHours(0, 0, 0, 0)
  }),
  handleSubmit: values => {
    statisticStore.load({
      deliveryType: values.deliveryType,
      payType: values.payType,
      systemType: values.systemType,
      locationIds: values.locationIds || [],
      categoryIds: values.categoryIds || [],
      orgId: userStore.user && userStore.user.organizationId,
      startDateTime: values.startDateTime || new Date().setHours(0, 0, 0, 0),
      endDateTime: values.endDateTime || new Date().setHours(23, 59, 59, 999)
    });
  },
  displayName: 'filter options'
})(observer(FilterOptions));

export default FilterOptionsWithFormik;
