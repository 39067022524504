import React, { useState } from 'react';
import { Radio } from 'antd';

import Storage from '../../utils/Storage';
// import i18n from '../../../../i18n';

import styles from './menuLang.module.scss';
import { categoryStore } from '../../../category/stores';
import { dishStore } from '../../../dish/stores';
import { screenStore } from '../../../screens/stores';

const languageData = ['UK', 'EN', 'PL', 'RU'];

const MenuLang = () => {
  const [lang, changeLanguage] = useState({ langName: Storage('sessionStorage').get('language') || 'UK' });
  const language = languageData.map((value, index) => (
    <Radio.Button
      value={value}
      key={index}
      onChange={() => {
        Storage('sessionStorage').set('language', value);
        const location = window.location.pathname.split('/')[1];
        if (location === 'categories') {
          categoryStore.load(categoryStore.category ? categoryStore.category.id : 0);
        }
        if (location === 'dishes') {
          dishStore.load(dishStore.dish ? dishStore.dish.id : 0);
        }
        if (location === 'screens') {
          screenStore.load();
        }
      }}
    >
      {value}
    </Radio.Button>
  ));

  return (
    <div className={styles.langMenu}>
      <Radio.Group size="small" value={lang.langName} onChange={e => changeLanguage({ langName: e.target.value })}>
        {language}
      </Radio.Group>
    </div>
  );
};

export default MenuLang;
