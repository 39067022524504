import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import { InputField } from '../../../common/components';
import { UpfateTheme } from '../../types';
import { screenStore } from '../../stores';

import styles from './ThemeForm.module.scss';
// import OptionButton from '../../../common/components/OptionsButton';

const ThemeForm = (props: FormikProps<UpfateTheme>) => {
  const { handleSubmit, setFieldTouched, setFieldValue, touched, errors, values } = props;

  const themeColors = [
    {
      title: 'Група 1 (заливка кнопок, тогли, активна рамка)',
      name: 'group_1_color',
      placeholder: '#FFFFFF'
    },
    {
      title: 'Група 2 (колір тексту, тексту на кнопках)',
      name: 'group_2_color',
      placeholder: '#FFFFFF'
    },
    {
      title:
        'Група 3 (рамка категорії/продукту, фон навколо товару в корзині, фон навколо товару на екрані підтвердження замовлення',
      name: 'group_3_color',
      placeholder: '#FFFFFF'
    },
    {
      title: 'Фон під написом назви продукту',
      name: 'bg_color_product_title',
      placeholder: '#DDDDDD'
    },
    {
      title: 'Дефолтний колір фону на всі скріни, на яких немає кастомної картинки на бекгрануд',
      name: 'default_page_bg_color',
      placeholder: '#DDDDDD'
    },
    {
      title: 'Колір назв товарів',
      name: 'menuItem_title_color',
      placeholder: '#000000'
    },
    {
      title: 'Колір заголовку у попапі деталей товару',
      name: 'menuItem_title_color_with_details',
      placeholder: '#000000'
    },
    {
      title: 'Колір беграунду попапу деталай товару',
      name: 'menuItem_popup_bg_with_details',
      placeholder: '#000000'
    },
    {
      title: 'Колір помилок (відсутніх товарів тощо)',
      name: 'error_color',
      placeholder: '#FF0000'
    }
  ];
  return (
    <form onSubmit={handleSubmit}>
      <h3>Тема оформлення</h3>
      <div className={styles.wrap}>
        <div className={styles.fieldsWrap}>
          {themeColors.map(color => (
            <>
              <InputField
                title={color.title}
                placeholder={color.placeholder}
                name={color.name}
                type="color"
                value={values[color.name]}
                onBlur={() => setFieldTouched(color.name)}
                onChange={event => {
                  setFieldValue(color.name, event.target.value);
                  setFieldTouched(color.name);
                }}
                errorMessage={touched.group_1_color && errors.group_1_color}
              />
              <section className={styles.colorLabel}>{values[color.name]}</section>
            </>
          ))}
        </div>
        <div className={styles.buttonsWrap}>
          <div className={styles.wrapBottomButtons}>
            <Button className={styles.saveButton} type="primary" htmlType="submit">
              Зберегти
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const ThemeFormWithFormik = withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    group_1_color: (screenStore.screens && screenStore.screens.theme?.group_1_color) || '#598E13',
    group_2_color: (screenStore.screens && screenStore.screens.theme?.group_2_color) || '#FFFFFF',
    group_3_color: (screenStore.screens && screenStore.screens.theme?.group_3_color) || '#DDDDDD',
    bg_color_product_title: (screenStore.screens && screenStore.screens.theme?.bg_color_product_title) || '#000000',
    default_page_bg_color: (screenStore.screens && screenStore.screens.theme?.default_page_bg_color) || '#000000',
    menuItem_title_color: (screenStore.screens && screenStore.screens.theme?.menuItem_title_color) || '#000000',
    menuItem_title_color_with_details:
      (screenStore.screens && screenStore.screens.theme?.menuItem_title_color_with_details) || '#000000',
    menuItem_popup_bg_with_details:
      (screenStore.screens && screenStore.screens.theme?.menuItem_popup_bg_with_details) || '#FFFFFF',
    error_color: (screenStore.screens && screenStore.screens.theme?.error_color) || '#FF0000'
  }),
  handleSubmit: ({
    group_1_color,
    group_2_color,
    group_3_color,
    bg_color_product_title,
    default_page_bg_color,
    menuItem_title_color,
    menuItem_title_color_with_details,
    menuItem_popup_bg_with_details,
    error_color
  }) => {
    screenStore.update(
      {
        group_1_color,
        group_2_color,
        group_3_color,
        bg_color_product_title,
        default_page_bg_color,
        menuItem_title_color,
        menuItem_title_color_with_details,
        menuItem_popup_bg_with_details,
        error_color
      },
      'theme'
    );
  },
  validationSchema: yup.object().shape<any>({
    group_1_color: yup.string(),
    group_2_color: yup.string(),
    group_3_color: yup.string(),
    bg_color_product_title: yup.string(),
    default_page_bg_color: yup.string(),
    menuItem_title_color: yup.string(),
    menuItem_title_color_with_details: yup.string(),
    menuItem_popup_bg_with_details: yup.string(),
    error_color: yup.string()
  }),
  displayName: 'ThemeForm'
})(observer(ThemeForm));

export default ThemeFormWithFormik;
