import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { FormikProps, withFormik } from 'formik';
import LoadImg from '../../../common/components/LoadImg';
import LoadBackground from '../../../common/components/LoadImg';

import styles from './pitstop.module.scss';
import { pitstopStore } from '../../stores';
import { OrganizationValuesUpdate } from '../../types';
import { imageStore } from '../../../common/stores';
const Pitstop = (props: FormikProps<OrganizationValuesUpdate>) => {
    useEffect(() => {
        imageStore.download(
            pitstopStore.pitstop && pitstopStore.pitstop.pitstopLogoUri
                ? pitstopStore.pitstop.pitstopLogoUri
                : ''
        );
        imageStore.downloadBackground(
            pitstopStore.pitstop && pitstopStore.pitstop.pitstopBackgroundUri
                ? pitstopStore.pitstop.pitstopBackgroundUri
                : ''
        );
    }, []);
    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <h3>Вигляд Pitstop</h3>
            <div className={styles.wrap}>
                <div className={styles.imgWrap}>
                    <div>
                        <div className={styles.title}>Логотип для Pitstop</div>
                        <LoadImg
                            title="Upload"
                            style={styles.loadImg}
                            type={'pitstoplogo'}
                        />
                    </div>
                    <div>
                        <div className={styles.title}>Фон для Pitstop</div>
                        <LoadBackground
                            title="Upload"
                            style={styles.loadImg}
                            type={'pitstopbackground'}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

const PitstopWithFormik = withFormik<any, OrganizationValuesUpdate>({
    enableReinitialize: true,
    mapPropsToValues: () => ({
        pitstopLogoUri:
            pitstopStore.pitstop && pitstopStore.pitstop.pitstopLogoUri,
        pitstopBackgroundUri:
            pitstopStore.pitstop && pitstopStore.pitstop.pitstopBackgroundUri,
    }),
    handleSubmit: ({ pitstopLogoUri, pitstopBackgroundUri }) => {
        // pitstopStore.update({
        //     pitstopLogoUri,
        //     pitstopBackgroundUri,
        // });
    },
    displayName: 'filter options',
})(observer(Pitstop));

export default PitstopWithFormik;
