import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Switch } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import Storage from '../../../common/utils/Storage';
import { InputField } from '../../../common/components';
import { UpdateChooseTable } from '../../types';
import { screenStore } from '../../stores';
import MenuLang from '../MenuLang';

import styles from './tableSelectionForm.module.scss';
import SharedBgImage from '../SharedBgImage';
// import OptionButton from '../../../common/components/OptionsButton';

const TableSelectionForm = (props: FormikProps<UpdateChooseTable>) => {
  const [activeLang, setActiveLang] = useState(String(Storage('sessionStorage').get('language')).toLowerCase());
  const { handleSubmit, setFieldTouched, touched, errors, setFieldValue, values } = props;

  const handleLangChange = (language: string) => {
    setActiveLang(language.toLowerCase());
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Вибір солику</h3>
      <div className={styles.wrap}>
        <div className={styles.fieldsWrap}>
          <InputField
            title={'Повідомлення'}
            placeholder={'Бажаєте отримати своє замовленя за столиком?'}
            name="message"
            value={values.message[activeLang]}
            onBlur={() => setFieldTouched(`message.${activeLang}`)}
            errorMessage={
              touched.message && touched.message[activeLang] && errors.message && errors.message[activeLang]
            }
            onChange={event => {
              setFieldValue(`message.${activeLang}`, event.target.value);
              setFieldTouched(`message.${activeLang}`);
            }}
          />
          <InputField
            title={'Інструкція'}
            placeholder={'Візьміть номерок з терміналу і введіть його тут'}
            name="instruction"
            value={values.instruction[activeLang]}
            onBlur={() => setFieldTouched(`instruction.${activeLang}`)}
            errorMessage={
              touched.instruction &&
              touched.instruction[activeLang] &&
              errors.instruction &&
              errors.instruction[activeLang]
            }
            onChange={event => {
              setFieldValue(`instruction.${activeLang}`, event.target.value);
              setFieldTouched(`instruction.${activeLang}`);
            }}
          />
          <InputField
            title={'Кнопка відміни'}
            placeholder={'Ні дякую'}
            name="buttonCancel"
            value={values.buttonCancel[activeLang]}
            onBlur={() => setFieldTouched(`buttonCancel.${activeLang}`)}
            errorMessage={
              touched.buttonCancel &&
              touched.buttonCancel[activeLang] &&
              errors.buttonCancel &&
              errors.buttonCancel[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonCancel.${activeLang}`, event.target.value);
              setFieldTouched(`buttonCancel.${activeLang}`);
            }}
          />
          <InputField
            title={'Поясненя'}
            placeholder={'Заберу замовленя на касі'}
            name="buttonCancelDescription"
            value={values.buttonCancelDescription[activeLang]}
            onBlur={() => setFieldTouched(`buttonCancelDescription.${activeLang}`)}
            errorMessage={
              touched.buttonCancelDescription &&
              touched.buttonCancelDescription[activeLang] &&
              errors.buttonCancelDescription &&
              errors.buttonCancelDescription[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonCancelDescription.${activeLang}`, event.target.value);
              setFieldTouched(`buttonCancelDescription.${activeLang}`);
            }}
          />
          <SharedBgImage screenType="chooseTable" />
        </div>
        <div className={styles.buttonsWrap}>
          <div className={styles.wrapTopButtons}>
            <MenuLang onChange={handleLangChange} />
            {/* TODO:here must be OptionButton */}
            {/* <OptionButton /> */}
          </div>
          <div className={styles.wrapBottomButtons}>
            <Switch
              defaultChecked={
                screenStore.screens && screenStore.screens.chooseTable
                  ? screenStore.screens.chooseTable.switchOff
                  : false
              }
              onChange={() =>
                screenStore.update(
                  {
                    ...(screenStore.screens && screenStore.screens.chooseTable),
                    switchOff: screenStore.screens && !screenStore.screens.chooseTable.switchOff
                  },
                  'chooseTable'
                )
              }
            />
            <Button className={styles.saveButton} type="primary" htmlType="submit">
              Зберегти
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const TableSelectionFormWithFormik = withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    imageUrl:
      screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.background?.imageUrl,
    message: {
      en: screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.message.en,
      pl: screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.message.pl,
      ru: screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.message.ru,
      uk: screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.message.uk
    },
    instruction: {
      en: screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.instruction.en,
      pl: screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.instruction.pl,
      ru: screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.instruction.ru,
      uk: screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.instruction.uk
    },
    buttonCancel: {
      en:
        screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.buttonCancel.name.en,
      pl:
        screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.buttonCancel.name.pl,
      ru:
        screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.buttonCancel.name.ru,
      uk: screenStore.screens && screenStore.screens.chooseTable && screenStore.screens.chooseTable.buttonCancel.name.uk
    },
    buttonCancelDescription: {
      en:
        screenStore.screens &&
        screenStore.screens.chooseTable &&
        screenStore.screens.chooseTable.buttonCancel.description.en,
      pl:
        screenStore.screens &&
        screenStore.screens.chooseTable &&
        screenStore.screens.chooseTable.buttonCancel.description.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.chooseTable &&
        screenStore.screens.chooseTable.buttonCancel.description.ru,
      uk:
        screenStore.screens &&
        screenStore.screens.chooseTable &&
        screenStore.screens.chooseTable.buttonCancel.description.uk
    }
  }),
  handleSubmit: ({ message, instruction, buttonCancel, buttonCancelDescription, imageUrl }) => {
    screenStore.update(
      {
        message: {
          en: message.en,
          pl: message.pl,
          ru: message.ru,
          uk: message.uk
        },
        instruction: {
          en: instruction.en,
          pl: instruction.pl,
          ru: instruction.ru,
          uk: instruction.uk
        },
        buttonCancel: {
          name: {
            en: buttonCancel.en,
            pl: buttonCancel.pl,
            ru: buttonCancel.ru,
            uk: buttonCancel.uk
          },
          description: {
            en: buttonCancelDescription.en,
            pl: buttonCancelDescription.pl,
            ru: buttonCancelDescription.ru,
            uk: buttonCancelDescription.uk
          }
        },
        background: {
          imageUrl
        }
      },
      'chooseTable'
    );
  },
  validationSchema: yup.object().shape<any>({
    message: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    instruction: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    buttonCancel: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    buttonCancelDescription: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    })
  }),
  displayName: 'TableSelectionForm'
})(observer(TableSelectionForm));

export default TableSelectionFormWithFormik;
