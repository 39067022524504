import { observable, action, runInAction } from 'mobx';

import { dishStore } from '../stores';
import { Ingridient, AddIngredientsValue, Dish, UpdateAdditionIngredientValues } from '../types';
import { ingridientsService } from '../services';

class IngridientStore {
  @observable
  public ingridient: Ingridient | null = null;
  @observable
  public ingridients: Ingridient[] = [];
  @observable
  public allIngridients: Ingridient[] = [];
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action public async load() {
    this.loading = true;

    try {
      const allIngridients = await ingridientsService.load();
      runInAction(() => {
        this.allIngridients = allIngridients;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async create(ingridientValues: AddIngredientsValue) {
    this.loading = true;

    try {
      const ingridient = await ingridientsService.create(ingridientValues);
      if (ingridient.code === 204) {
        runInAction(() => {
          this.ingridient = ingridient.data;
        });
      } else {
        runInAction(() => {
          this.ingridient = ingridient.data;
        });
        dishStore.load(dishStore.dish ? dishStore.dish.id : 0);
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async update(
    ingridientId: number,
    ingridientValues: UpdateAdditionIngredientValues,
    langType: string
  ) {
    this.loading = true;
    try {
      await ingridientsService.update(ingridientId, ingridientValues, langType);
      dishStore.load(dishStore.dish ? dishStore.dish.id : 0);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async delete(ingridientId: number) {
    this.loading = true;

    try {
      await ingridientsService.delete(ingridientId);
      await dishStore.load(dishStore.dish ? dishStore.dish.id : 0);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async addToDish(ingridientId: number, dish: Dish) {
    this.loading = true;

    let arrayOfIngredients = [ingridientId];

    if (dish.ingredients) {
      dish.ingredients.forEach(ingredient => arrayOfIngredients.push(ingredient.id));
    }

    try {
      await ingridientsService.addToDish(arrayOfIngredients, dish);
      await dishStore.load(dishStore.dish ? dishStore.dish.id : 0);
      arrayOfIngredients = [];
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async removeFromDish(ingridientId: number, dish: Dish) {
    this.loading = true;

    let arrayOfIngredients: number[] = [];

    if (dish.ingredients) {
      dish.ingredients.forEach(ingredient => arrayOfIngredients.push(ingredient.id));
    }

    arrayOfIngredients = arrayOfIngredients.filter(item => item !== ingridientId);

    try {
      await ingridientsService.removeFromDish(arrayOfIngredients, dish);
      await dishStore.load(dishStore.dish ? dishStore.dish.id : 0);
      arrayOfIngredients = [];
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new IngridientStore();
