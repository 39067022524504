import { notification, message } from 'antd';
import axios from 'axios';

import Storage from '../../common/utils/Storage';

import settings from '../../../settings';

import { UpdateDishValues, Dish } from '../types';

class Dishes {
  public load = async (dishId: number): Promise<Dish> => {
    try {
      const { data } = await axios.get(
        `${settings.domain}/api/admin/menu-item/${Storage('sessionStorage').get('menu').locationId}/${dishId}`,
        {
          headers: { 'Accept-Language': Storage('sessionStorage').get('language') || 'UK' }
        }
      );
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити інформацію про страву. Спробуйте пізніше.'
      });
      throw error;
    }
  };
  public create = async ({ name, description, price, categoryId }: UpdateDishValues) => {
    const dishData = {
      name,
      description,
      additions: [],
      order: 0,
      price
    };

    try {
      const response = await axios.post(`${settings.domain}/api/admin/menu-item/${categoryId}`, dishData);
      if (response.data.code !== 200) {
        notification.error({
          message: 'Не вдалося створити страву.',
          description: 'Такої категорії не існує. Спробуйте іншу категорію.'
        });
        return response.data.code;
      } else {
        message.success('Страва успішно створена!', 5);
        return response.data;
      }
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося створити страву. Спробуйте пізніше.'
      });
      throw error;
    }
  };
  public update = async (dishId: number, updateValues: UpdateDishValues) => {
    try {
      const { data } = await axios.put(
        `${settings.domain}/api/admin/menu-item/${dishId}?newCategoryId=${updateValues.newCategoryId}`,
        {
          ...updateValues
        }
      );
      message.success('Страва успішно оновлена!');
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося редагувати страву. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public delete = async (dishId: number) => {
    try {
      await axios.delete(`${settings.domain}/api/admin/menu-item/${dishId}`);
      message.success('Страва успішно видалена!');
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося видалити страву. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public updateWithoutRedirect = async (dishId: number, updateObject: Dish) => {
    try {
      const { data } = await axios.put(`${settings.domain}/api/admin/menu-item/${dishId}`, updateObject);
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося змінити пріорітет страви. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public updateLanguage = async (dishId: number, dishValues: UpdateDishValues, langType: string) => {
    try {
      const { data } = await axios.post(
        `${settings.domain}/api/database/translation-both?entityId=${dishId}&name=${dishValues.name}&description=${dishValues.description}&entity=MENU_ITEM`,
        {},
        { headers: { 'Accept-Language': langType } }
      );
      message.success('Категорія успішно оновлена!');
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося редагувати категорію. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public addToArhiv = async (dishId: number | null, hideFrom: number | null, hideTo: number | null) => {
    try {
      const { data } = await axios.post(
        `${settings.domain}/api/database/archive/to?entityId=${dishId}&entity=MENU_ITEM&hideFrom=${hideFrom}&hideTo=${hideTo}`
      );
      message.success('Страву успішно додана до архіву!');
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося архівувати страву. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public extractFromArchive = async (dishId?: number | null) => {
    try {
      await axios.post(`${settings.domain}/api/database/archive/from?entityId=${dishId}&entity=MENU_ITEM`);
      message.success('Страва успішно відновлена!');
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося відновити страву. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public loadStopList = async (): Promise<number[]> => {
    try {
      const { data } = await axios.get(
        `${settings.domain}/api/admin/menu-item/stop-list?location_id=${
          Storage('sessionStorage').get('menu').locationId
        }`,
        {
          headers: { 'Accept-Language': Storage('sessionStorage').get('language') || 'UK' }
        }
      );
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити стоп-ліст страв. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public addToStopList = async (dishId: number | null, locationId: number | null) => {
    try {
      const { data } = await axios.put(
        `${settings.domain}/api/admin/menu-item/stop-list?menu_item_id=${dishId}&location_id=${locationId}`
      );
      message.success('Страву успішно додана до стоп-ліста!');
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося додати страву до стоп-ліста. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public removeFromStopList = async (dishId: number | null, locationId: number | null) => {
    try {
      const { data } = await axios.delete(
        `${settings.domain}/api/admin/menu-item/stop-list?menu_item_id=${dishId}&location_id=${locationId}`
      );
      message.success('Страву успішно видалено зі стоп-ліста!');
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося видалити страву зі стоп-ліста. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new Dishes();
