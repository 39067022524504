import * as React from 'react';

import PageArea from '../../components/PageArea';
import MainScreenForm from '../../components/MainScreenForm';

const MainScreenPage = () => {
  return (
    <PageArea
      content={<MainScreenForm />}
      pageName="Стартовий екран"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/main' },
        { name: 'Стартовий екран', url: '/screens/main' }
      ]}
    />
  );
};

export default MainScreenPage;
