import React, { useEffect } from 'react';
import { Button, Divider } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';

import Storage from '../../../common/utils/Storage';

import { imageStore } from '../../../common/stores';
import { dishStore, ingridientStore } from '../../stores';
import { removeArrayDuplicates } from '../../../common/utils';
import { UpdateDishValues } from '../../types';
import AddIngridients from '../AddIngridients';
import AddAdditionalIngridients from '../AddAdditionalIngridients';
import AddIngridientsModificator from '../AddIngridientsModificator';
import MenuLang from '../../../common/components/MenuLang';
import SelectField from '../SelectField';
import CommonSelectField from '../../../common/components/SelectField';
import ShowIngredientsList from '../ShowIngredientsList';
import ShowAdditionalIngridientList from '../ShowAdditionalIngridientList';
import ShowIngredientsModificatorList from '../ShowIngredientsModificatorList';
import InputField from '../../../common/components/InputField';
import LoadImg from '../../../common/components/LoadImg';
import { categoryStore } from '../../../category/stores';

import styles from './edit.module.scss';
import './edit.global.scss';
import DishOptions from '../DishOptions';

const Edit = (props: FormikProps<UpdateDishValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;

  useEffect(() => {
    ingridientStore.load();
    imageStore.download(dishStore.dish && dishStore.dish.url ? dishStore.dish.url : '');
  }, []);

  const filterOptions = (input: string, option: any) => {
    let value = input.trim().toLowerCase();
    const optionValue = option.props.children[0].props.children.toLowerCase();
    return optionValue.indexOf(value) >= 0 ? true : false;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="editing-dish-wrap">
        <div className={styles.wrapTopField}>
          <LoadImg title="Upload" style={styles.loadImg} type={'dishes'} />
          <div className={styles.wrapWithoutImg}>
            <div className={styles.wrapLangMenu}>
              <div className={styles.langMenu}>
                <MenuLang />
              </div>
              <div className={styles.buttonWrap}>
                <Button type="primary" htmlType="submit" className={styles.button}>
                  Зберегти
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    if (dishStore.dish) {
                      dishStore.delete(dishStore.dish.id);
                    }
                  }}
                  className={styles.button}
                >
                  Видалити
                </Button>
                <DishOptions />
              </div>
            </div>
            <InputField
              title={'Назва страви'}
              placeholder={'Мінімум 2, максимум 60 символів'}
              name="name"
              defaultValue={dishStore.dish ? dishStore.dish.name : values.name}
              onBlur={() => setFieldTouched('name')}
              errorMessage={touched.name && errors.name}
              onChange={event => setFieldValue('name', event.target.value)}
            />
            <InputField
              title={'Опис страви'}
              placeholder={'Мінімум 2, максимум 500 символів'}
              name="description"
              defaultValue={dishStore.dish ? dishStore.dish.description : values.description}
              onBlur={() => setFieldTouched('description')}
              errorMessage={touched.description && errors.description}
              onChange={event => setFieldValue('description', event.target.value)}
            />
            <InputField
              title={'Вартість страви'}
              placeholder={'Вартість'}
              name="price"
              defaultValue={dishStore.dish ? `${dishStore.dish.price}` : `${values.price}`}
              onBlur={() => setFieldTouched('price')}
              errorMessage={touched.price && errors.price}
              onChange={event => setFieldValue('price', event.target.value)}
            />
          </div>
        </div>
        <div className={styles.optionWrap}>
          <CommonSelectField
            title={'Перемістити в категорію'}
            loading={categoryStore.loading}
            options={categoryStore.categories ? categoryStore.categories : []}
            onBlur={() => setFieldTouched('newCategoryId')}
            errorMessage={touched.newCategoryId && errors.newCategoryId}
            value={values.newCategoryId ? values.newCategoryId : ' + Додати '}
            onChange={value => {
              setFieldValue('newCategoryId', value);
            }}
          />
        </div>
        <div className={styles.ingredientWindowWrap}>
          <div className={styles.editWindowTitle}>
            <h2>Інгредієнти</h2>
          </div>
          <ShowIngredientsList data={dishStore.dish ? dishStore.dish : null} />
          <SelectField
            options={removeArrayDuplicates(
              ingridientStore.allIngridients.filter(value => value.type === 'GENERAL'),
              'name'
            )}
            loading={ingridientStore.loading}
            wrapperStyle={{ margin: '0px auto', width: '90%' }}            
            showSearch
            filterOption={filterOptions}
            dropdownRender={menu => (
              <div>
                {menu}
                <Divider className={styles.divider} />
                <div className={styles.iconWrap}>
                  <div className={styles.icon}>
                    <AddIngridients props={props} />
                  </div>
                </div>
              </div>
            )}
          />
        </div>
        <div className={styles.additionIngredienteditWindowWrap}>
          <div className={styles.editWindowTitle}>
            <h2>Додаткові інгредієнти</h2>
          </div>
          <ShowAdditionalIngridientList data={dishStore.dish ? dishStore.dish : null} />
          <SelectField
            options={removeArrayDuplicates(
              ingridientStore.allIngridients.filter(value => value.type === 'ADDITIONAL'),
              'name'
            )}
            loading={ingridientStore.loading}
            wrapperStyle={{ margin: '0px auto', width: '90%' }}
            showSearch
            filterOption={filterOptions}
            dropdownRender={menu => (
              <div>
                {menu}
                <Divider className={styles.divider} />
                <div className={styles.iconWrap}>
                  <div className={styles.icon}>
                    <AddAdditionalIngridients props={props} />
                  </div>
                </div>
              </div>
            )}
          />
        </div>
        <div className={styles.modificatorWindowWrap}>
          <div className={styles.editWindowTitle}>
            <h2>Модифікатори</h2>
          </div>
          <ShowIngredientsModificatorList data={dishStore.dish ? dishStore.dish : null} />
          <SelectField
            options={removeArrayDuplicates(
              ingridientStore.allIngridients.filter(value => value.type === 'MODIFIER'),
              'name'
            )}
            loading={ingridientStore.loading}
            wrapperStyle={{ margin: '0px auto', width: '90%' }}
            showSearch
            filterOption={filterOptions}
            dropdownRender={menu => (
              <div>
                {menu}
                <Divider className={styles.divider} />
                <div className={styles.iconWrap}>
                  <div className={styles.icon}>
                    <AddIngridientsModificator props={props} />
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </form>
  );
};

const EditWithFormik = withFormik<any, UpdateDishValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: dishStore.dish ? dishStore.dish.name : '',
    url: dishStore.dish ? dishStore.dish.url : '',
    description: dishStore.dish ? dishStore.dish.description : '',
    price: dishStore.dish ? dishStore.dish.price : 0,
    specialities: dishStore.dish ? dishStore.dish.specialities : false,
    newCategoryId: '',
    archived: dishStore.dish ? dishStore.dish.archived : false,
    order: dishStore.dish ? dishStore.dish.order : 0
  }),
  handleSubmit: values => {
    if (
      Storage('sessionStorage').get('language') === 'EN' ||
      Storage('sessionStorage').get('language') === 'PL' ||
      Storage('sessionStorage').get('language') === 'RU'
    ) {
      dishStore.updateLanguage(
        { ...values, url: imageStore.storagePath ? imageStore.storagePath : values.url },
        Storage('sessionStorage').get('language') || ''
      );
    } else {
      dishStore.update({ ...values, url: imageStore.storagePath ? imageStore.storagePath : values.url });
    }
  },
  validationSchema: yup.object().shape<UpdateDishValues>({
    name: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(60, 'Назва повинна мати не більше 60 символів'),
    description: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(500, 'Назва повинна мати не більше 500 символів')
      .required('Це поле не може бути порожнім!')
  }),
  displayName: 'Edit'
})(observer(Edit));

export default EditWithFormik;
