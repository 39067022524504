import axios from 'axios';
import { notification } from 'antd';

import Storage from '../utils/Storage';

import settings from '../../../settings';

class RefreshKiosks {
  public refresh = async () => {
    try {
      const { data } = await axios.get(
        `${settings.domain}/api/admin/push/${Storage('sessionStorage').get('menu').locationId}`
      );
      return data;
    } catch (error) {
      if (!Storage('sessionStorage').get('menu').locationId) {
        notification.warning({
          message: 'Оберіть локацію.',
          description: 'Не вдалося оновити кіоски. Оберіть будь-ласка в панелі меню локацію.'
        });
      } else {
        notification.error({
          message: 'Серверна помилка.',
          description: 'Не вдалося оновити кіоски. Спробуйте пізніше.'
        });
        throw error;
      }
    }
  };
}

export default new RefreshKiosks();
