import React from 'react';
import { Button, Switch } from 'antd';

import styles from './saveButton.module.scss';

interface SaveButtonProps {
  isSwich: boolean;
}

const SaveButton = ({ isSwich }: SaveButtonProps) => {
  return (
    <div className={styles.saveWrap}>
      {/* here must be onChange */}
      {isSwich ? <Switch defaultChecked={false} /> : <div />}
      <Button type="primary" htmlType="submit">
        Зберегти
      </Button>
    </div>
  );
};

export default SaveButton;
