import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import Storage from '../../../common/utils/Storage';
import { InputField } from '../../../common/components';
import { UpdateResponse } from '../../types';
import { screenStore } from '../../stores';
import MenuLang from '../MenuLang';

import styles from './feedbackThankForm.module.scss';
import SharedBgImage from '../SharedBgImage';
// import OptionButton from '../../../common/components/OptionsButton';

const FeedbackThankForm = (props: FormikProps<UpdateResponse>) => {
  const [activeLang, setActiveLang] = useState(String(Storage('sessionStorage').get('language')).toLowerCase());
  const { handleSubmit, setFieldTouched, touched, errors, setFieldValue, values } = props;

  const handleLangChange = (language: string) => {
    setActiveLang(language.toLowerCase());
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Відгук</h3>
      <div className={styles.wrap}>
        <div className={styles.fieldsWrap}>
          <InputField
            title={'Повідомлення'}
            placeholder={'До побачення та смачного! Дякуємо за Ваш вибір!'}
            name="thanks"
            value={values.thanks[activeLang]}
            onBlur={() => setFieldTouched(`thanks.${activeLang}`)}
            errorMessage={touched.thanks && touched.thanks[activeLang] && errors.thanks && errors.thanks[activeLang]}
            onChange={event => {
              setFieldValue(`thanks.${activeLang}`, event.target.value);
              setFieldTouched(`thanks.${activeLang}`);
            }}
          />
          <InputField
            title={'Повідомлення'}
            placeholder={'Оцініть зручність....'}
            name="response"
            value={values.response[activeLang]}
            onBlur={() => setFieldTouched(`response.${activeLang}`)}
            errorMessage={
              touched.response && touched.response[activeLang] && errors.response && errors.response[activeLang]
            }
            onChange={event => {
              setFieldValue(`response.${activeLang}`, event.target.value);
              setFieldTouched(`response.${activeLang}`);
            }}
          />
          <InputField
            title={'Кнопка (Так!)'}
            placeholder={'Так!'}
            name="buttonYes"
            value={values.buttonYes[activeLang]}
            onBlur={() => setFieldTouched(`buttonYes.${activeLang}`)}
            errorMessage={
              touched.buttonYes && touched.buttonYes[activeLang] && errors.buttonYes && errors.buttonYes[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonYes.${activeLang}`, event.target.value);
              setFieldTouched(`buttonYes.${activeLang}`);
            }}
          />
          <InputField
            title={'Кнопка (Не зараз)'}
            placeholder={'Не зараз'}
            name="buttonNotNow"
            value={values.buttonNotNow[activeLang]}
            onBlur={() => setFieldTouched(`buttonNotNow.${activeLang}`)}
            errorMessage={
              touched.buttonNotNow &&
              touched.buttonNotNow[activeLang] &&
              errors.buttonNotNow &&
              errors.buttonNotNow[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonNotNow.${activeLang}`, event.target.value);
              setFieldTouched(`buttonNotNow.${activeLang}`);
            }}
          />
          <SharedBgImage screenType="response" />
        </div>
        <div className={styles.buttonsWrap}>
          <div className={styles.wrapTopButtons}>
            <MenuLang onChange={handleLangChange} />
            {/* TODO:here must be OptionButton */}
            {/* <OptionButton /> */}
          </div>
          <div className={styles.wrapBottomButtons}>
            <div />
            <Button className={styles.saveButton} type="primary" htmlType="submit">
              Зберегти
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const FeedbackThankFormWithFormik = withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    imageUrl:
      (screenStore.screens && screenStore.screens.response && screenStore.screens.response.background?.imageUrl) ||
      null,
    response: {
      en: screenStore.screens && screenStore.screens.response && screenStore.screens.response.response.en,
      pl: screenStore.screens && screenStore.screens.response && screenStore.screens.response.response.pl,
      ru: screenStore.screens && screenStore.screens.response && screenStore.screens.response.response.ru,
      uk: screenStore.screens && screenStore.screens.response && screenStore.screens.response.response.uk
    },
    thanks: {
      en: screenStore.screens && screenStore.screens.response && screenStore.screens.response.thanks.en,
      pl: screenStore.screens && screenStore.screens.response && screenStore.screens.response.thanks.pl,
      ru: screenStore.screens && screenStore.screens.response && screenStore.screens.response.thanks.ru,
      uk: screenStore.screens && screenStore.screens.response && screenStore.screens.response.thanks.uk
    },
    buttonYes: {
      en: screenStore.screens && screenStore.screens.response && screenStore.screens.response.buttonYes.name.en,
      pl: screenStore.screens && screenStore.screens.response && screenStore.screens.response.buttonYes.name.pl,
      ru: screenStore.screens && screenStore.screens.response && screenStore.screens.response.buttonYes.name.ru,
      uk: screenStore.screens && screenStore.screens.response && screenStore.screens.response.buttonYes.name.uk
    },
    buttonNotNow: {
      en: screenStore.screens && screenStore.screens.response && screenStore.screens.response.buttonNotNow.name.en,
      pl: screenStore.screens && screenStore.screens.response && screenStore.screens.response.buttonNotNow.name.pl,
      ru: screenStore.screens && screenStore.screens.response && screenStore.screens.response.buttonNotNow.name.ru,
      uk: screenStore.screens && screenStore.screens.response && screenStore.screens.response.buttonNotNow.name.uk
    }
  }),
  handleSubmit: ({ response, thanks, buttonYes, buttonNotNow, imageUrl }) => {
    screenStore.update(
      {
        response: {
          en: response.en,
          pl: response.pl,
          ru: response.ru,
          uk: response.uk
        },
        thanks: {
          en: thanks.en,
          pl: thanks.pl,
          ru: thanks.ru,
          uk: thanks.uk
        },
        buttonYes: {
          description:
            screenStore.screens && screenStore.screens.response && screenStore.screens.response.buttonYes.description,
          name: {
            en: buttonYes.en,
            pl: buttonYes.pl,
            ru: buttonYes.ru,
            uk: buttonYes.uk
          }
        },
        buttonNotNow: {
          description:
            screenStore.screens &&
            screenStore.screens.response &&
            screenStore.screens.response.buttonNotNow.description,
          name: {
            en: buttonNotNow.en,
            pl: buttonNotNow.pl,
            ru: buttonNotNow.ru,
            uk: buttonNotNow.uk
          }
        },
        background: {
          imageUrl
        }
      },
      'response'
    );
  },
  validationSchema: yup.object().shape<any>({
    response: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    thanks: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    buttonYes: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    buttonNotNow: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    })
  }),
  displayName: 'FeedbackThankForm'
})(observer(FeedbackThankForm));

export default FeedbackThankFormWithFormik;
