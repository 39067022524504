import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { authStore } from '../stores';

type AuthRouteProps = {
  component: any;
} & RouteProps;

const AuthRoute = ({ component: Component, ...rest }: AuthRouteProps) => {
  return <Route {...rest} render={props => (authStore.entity ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

export default observer(AuthRoute);
