import React from 'react';
import { Upload, notification, Icon, Spin } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { observer } from 'mobx-react-lite';

import { imageStore } from '../../stores';
import { categoryStore } from '../../../category/stores';
import { dishStore } from '../../../dish/stores';
import './loadImg.global.scss';
import { Ingridient } from '../../../dish/types';
import styles from './loadImg.module.scss';
import { pitstopStore } from '../../../pitstop/stores';
import { userStore } from '../../../authentication/stores';

type LoadImgState = {
    url: string | null;
    loading: boolean;
    originFile: any;
} & UploadProps;

interface LoadImgProps {
    title?: string;
    style?: string;
    type?: string;
    ingridient?: Ingridient;
}

interface File {
    type: string;
    size: number;
}

const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
        onSuccess('ok');
    }, 0);
};

const LoadImg = ({ title, style, type, ingridient }: LoadImgProps) => {
    const [file, setFile] = React.useState<LoadImgState>({
        originFile: null,
        url: null,
        loading: false,
    });

    const getBase64 = (img: Blob, callback: CallableFunction) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (uploadFile: File) => {
        const isJpgOrPng =
            uploadFile.type === 'image/jpeg' || uploadFile.type === 'image/png';
        const isLt2M = uploadFile.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            notification.error({
                message: 'Помилка завантаження файлу',
                description:
                    'У Вас є можливість завантажувати тільки JPG/PNG файли!',
                duration: 0,
            });
        }
        if (!isLt2M) {
            notification.error({
                message: 'Помилка завантаження файлу',
                description: 'Зображення має бути меньше ніж 2MB!',
                duration: 0,
            });
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info: any) => {
        if (info.file.status === 'uploading') {
            setFile({ originFile: null, url: null, loading: true });
            return;
        }

        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, (imgUrl: string) =>
                setFile({
                    originFile: info.file.originFileObj,
                    url: imgUrl,
                    loading: false,
                })
            );
        }
    };

    const uploadButton = (
        <div className={style}>
            <div>
                <Icon
                    className={styles.uploadIcon}
                    type={file.loading ? 'loading' : 'plus'}
                />
                <div>{title}</div>
            </div>
        </div>
    );

    const { url, originFile } = file;
    if (originFile) {
        const formdata = new FormData();
        formdata.append('file', originFile);
        if (type === 'dishes' && dishStore) {
            imageStore.upload(formdata, dishStore.dish, type);
        }
        if (type === 'ingridients' && ingridient) {
            imageStore.upload(formdata, ingridient, type);
        }
        if (type === 'categories' && categoryStore) {
            imageStore.upload(formdata, categoryStore.category, type);
        }
        if (
            (type === 'pitstoplogo' || type === 'pitstopbackground') &&
            pitstopStore &&
            pitstopStore.pitstop
        ) {
            imageStore.upload(
                formdata,
                {
                    id: userStore.user ? userStore.user.locations[0].id : 0,
                    remove: false, //fix this ??????? this is fields from another type interface
                    name: '',
                },
                type
            );
        }
    }

    const getImg = () => {
        if (url) {
            return <img src={url} alt="avatar" className={style} />;
        } else if (
            ingridient &&
            ingridient.imageUrl &&
            type === 'ingridients'
        ) {
            return (
                <img src={ingridient.imageUrl} alt="avatar" className={style} />
            );
        } else if (
            (imageStore.imgUrl && type === 'dishes') ||
            (imageStore.imgUrl && type === 'categories') ||
            (imageStore.imgUrl && type === 'pitstoplogo')
        ) {
            if (imageStore.loading) {
                return <Spin />;
            } else {
                return (
                    <img src={imageStore.imgUrl} alt={type} className={style} />
                );
            }
        } else if (
            imageStore.imgBackgroundUrl &&
            type === 'pitstopbackground'
        ) {
            if (imageStore.loading) {
                return <Spin />;
            } else {
                return (
                    <img
                        src={imageStore.imgBackgroundUrl}
                        alt={type}
                        className={style}
                    />
                );
            }
        } else {
            return uploadButton;
        }
    };

    return (
        <div className="wrap">
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {getImg()}
            </Upload>
        </div>
    );
};

export default observer(LoadImg);
