import * as React from 'react';

import CreateCategoryPage from '../category/pages/CreateCategoryPage';
import EditCategoryPage from '../category/pages/EditCategoryPage';
import Module from '../Connector';
import { AuthRoute } from '../authentication/accessComponents';

export default new Module({
  route: [
    <AuthRoute exact={true} path="/categories/edit/:id" component={EditCategoryPage} />,
    <AuthRoute exact={true} path="/categories/create" component={CreateCategoryPage} />
  ]
});
