import { observable, action, runInAction } from 'mobx';

import { TokenPayload } from '../types';
import { storage } from '../../common/utils';
import { JWT_TOKEN_COOKIES_NAME, PAYLOAD_NAME, STORAGE_TYPE } from '../constants';

class AuthStore {
  @observable
  public token: string | null = null;

  @observable
  public entity: TokenPayload | null = null;

  constructor() {
    this.getTokenFromStore();
  }

  @action
  public removeTokenFromStore = () => {
    storage(STORAGE_TYPE).remove(JWT_TOKEN_COOKIES_NAME);
    storage(STORAGE_TYPE).remove(PAYLOAD_NAME);

    runInAction(() => {
      this.token = null;
      this.entity = null;
    });
  };

  @action
  public saveTokenToStore = (token: string, payload: TokenPayload) => {
    storage(STORAGE_TYPE).set(JWT_TOKEN_COOKIES_NAME, token);
    storage(STORAGE_TYPE).set(PAYLOAD_NAME, payload.userId);

    runInAction(() => {
      this.token = token;
      this.entity = payload;
    });
  };

  @action
  public getTokenFromStore = async () => {
    const token = storage(STORAGE_TYPE).get(JWT_TOKEN_COOKIES_NAME);
    const payload = storage(STORAGE_TYPE).get(PAYLOAD_NAME);

    runInAction(() => {
      this.entity = payload;
      this.token = token;
    });
  };
}

export default new AuthStore();
