import React, { useState } from 'react';
import Button from 'antd/lib/button/button';
import { Modal, DatePicker } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';

import './addArchiveData.global.scss';
import styles from './addArchiveData.module.scss';
import { Dish } from '../../../dish/types';
import { categoryStore } from '../../../category/stores';
import { dishStore } from '../../../dish/stores';

interface AddArchiveDataOwnProps {
  title: string;
  type: string;
  dish?: Dish;
  onlyFrom?: boolean;
}

interface UpdateAddArchiveDataVAlues {
  hideFrom: string;
  hideTo?: string;
}

const AddArchiveData = (props: AddArchiveDataOwnProps & FormikProps<UpdateAddArchiveDataVAlues>) => {
  const { handleSubmit, setFieldTouched, setFieldValue, title, onlyFrom } = props;
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const handleOk = () => {
    handleSubmit();
    setVisible(false);
  };
  const handleCancel = () => setVisible(false);

  return (
    <div>
      <Button className={styles.button} onClick={showModal} type="primary">
        {title}
      </Button>
      <Modal
        className="modalWrap"
        title="Додати період архівування"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {onlyFrom ? (
          <form className={styles.formOnlyFrom}>
            <DatePicker
              className={styles.datePicker}
              placeholder={'Дата початку'}
              format={'YYYY/MM/DD'}
              onOpenChange={() => setFieldTouched('hideFrom')}
              onChange={(_, dateString) => setFieldValue('hideFrom', dateString)}
            />
          </form>
        ) : (
          <form className={styles.form}>
            <DatePicker
              placeholder={'Дата початку'}
              format={'YYYY/MM/DD'}
              onOpenChange={() => setFieldTouched('hideFrom')}
              onChange={(_, dateString) => setFieldValue('hideFrom', dateString)}
            />
            <div>до</div>
            <DatePicker
              placeholder={'Дата кінця'}
              format={'YYYY/MM/DD'}
              onOpenChange={() => setFieldTouched('hideTo')}
              onChange={(_, dateString) => setFieldValue('hideTo', dateString)}
            />
          </form>
        )}
      </Modal>
    </div>
  );
};

const AddIngridientsWithFormik = withFormik<AddArchiveDataOwnProps, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    hideFrom: 0,
    hideTo: 0
  }),
  handleSubmit: (values, { props: { type, dish } }) => {
    if (type === 'category') {
      categoryStore.addToArhiv(Date.parse(values.hideFrom), Date.parse(values.hideTo));
    }
    if (type === 'dish' && !dish) {
      dishStore.addToArhiv(Date.parse(values.hideFrom), Date.parse(values.hideTo));
    }
    if (type === 'dish' && dish) {
      dishStore.addToArhiv(Date.parse(values.hideFrom), Date.parse(values.hideTo), dish);
    }
  },
  displayName: 'Add arhive data'
})(observer(AddArchiveData));

export default AddIngridientsWithFormik;
