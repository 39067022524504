import bcrypt from 'bcryptjs';
import crypto from 'crypto';

class HashingPassword {
  public hashSHA256 = (password: string) => {
    const secret = 'wowtouch';
    return crypto
      .createHmac('sha256', secret)
      .update(password)
      .digest('hex');
  };

  public hash = (password: string, saltRounds: number) => {
    const salt = bcrypt.genSaltSync(saltRounds);
    return bcrypt.hashSync(password, salt);
  };

  public compare = (password: string, hash: string) => {
    return bcrypt.compareSync(password, hash);
  };
}

export default new HashingPassword();
