import * as React from 'react';

import CreateDishPage from '../dish/pages/CreateDishPage';
import EditDishPage from '../dish/pages/EditDishPage';
import Module from '../Connector';
import { AuthRoute } from '../authentication/accessComponents';

export default new Module({
  route: [
    <AuthRoute exact={true} path="/dishes/edit/:id" component={EditDishPage} />,
    <AuthRoute exact={true} path="/dishes/create" component={CreateDishPage} />
  ]
});
