import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Menu, Icon } from 'antd';

import styles from './view.module.scss';
import { leftMenuDrawerStore } from '../../stores';

const viewTitles = [
  { name: 'Стартовий екран (логотип)', link: '/screens/main' },
  { name: 'Екран категорий', link: '/screens/category' },
  // { name: 'Каталог меню', link: '' },
  { name: 'Корзина', link: '/screens/bin' },
  { name: 'Підтвердження замовленя', link: '/screens/confirm' },
  { name: 'Де поїсти: З собою / на місці', link: '/screens/where-eat' },
  { name: 'Вибір столику', link: '/screens/table-selection' },
  { name: 'Спосіб оплати', link: '/screens/payment-method' },
  { name: 'Інструкції до банк. терміналу', link: '/screens/bank-instructions' },
  { name: 'Отримання замовленя на касі', link: '/screens/сashier-order' },
  { name: 'Отримання замовленя за столом', link: '/screens/table-order' },
  { name: 'Подяка та відгук', link: '/screens/feedback-thanks' },
  { name: 'Відлік', link: '/screens/countdown' }
];

const View = () => {
  return (
    <div>
      <Divider className={styles.divider} />
      <div className={styles.title}>
        <div className={styles.titleName}>
          <h4>Екрани</h4>
        </div>
        <div className={styles.titleImg}>
          <Icon type="arrow-left" onClick={() => leftMenuDrawerStore.setMenuType(null)} />
        </div>
      </div>
      <Divider className={styles.divider} />
      <Menu mode="inline">
        {viewTitles.map((value, key) => (
          <Menu.Item key={key}>
            <Link to={value.link} onClick={() => leftMenuDrawerStore.setMenuType(null)}>
              {value.name}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
      <Divider className={styles.divider} />
      <Menu mode="inline">
        <Menu.Item key={'theme'}>
          <Link to={'/screens/theme'} onClick={() => leftMenuDrawerStore.setMenuType(null)}>
            Тема оформлення
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default View;
