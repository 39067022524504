import React, { ReactNode } from 'react';
import { Popover, Button } from 'antd';

import styles from './optionsButtonMenu.module.scss';

interface OptionButtonMenuProps {
  name?: string;
  content?: ReactNode;
  trigger?: any;
  placement?: any;
  style?: string;
  icon?: ReactNode;
}

const OptionButtonMenu = ({ name, content, trigger, placement, icon, style }: OptionButtonMenuProps) => {
  return (
    <Popover placement={placement} trigger={trigger} content={content} className={style}>
      <div className={styles.buttonWrap}>
        <Button className={styles.popverButton} type="primary">
          {icon} {name}
        </Button>
      </div>
    </Popover>
  );
};

export default OptionButtonMenu;
