import { notification } from 'antd';
import axios from 'axios';

import settings from '../../../settings';
import { User } from '../types';

class Users {
  public getUser = async (userId: number): Promise<User> => {
    try {
      const { data } = await axios.get(`${settings.domain}/api/admin/user/${userId}`);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити дані адміністратора. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new Users();
