import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'antd';

import settings from '../../../../settings';

import LoginForm from '../../components/LoginForm';
import { AppLayout, MaterialShadow } from '../../../common/components';

const LoginPage = () => {
  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Логін`}</title>
        <meta name="description" content={`${settings.appName} - Логін`} />
      </Helmet>

      <Row type="flex" justify="center">
        <Col lg={12}>
          <MaterialShadow>
            <LoginForm />
          </MaterialShadow>
        </Col>
      </Row>
    </AppLayout>
  );
};

export default LoginPage;
