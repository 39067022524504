import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { round } from 'lodash';
import { Card, Statistic } from 'antd';
import {
  CalculatorOutlined,
  PlusCircleOutlined,
  OrderedListOutlined,
  ContainerOutlined,
  ShoppingCartOutlined,
  WalletOutlined
} from '@ant-design/icons';

import { statisticStore } from '../../stores';

import styles from './statisticSummary.module.scss';

export const tableTitles = {
  ordersSum: 'Оборот',
  ordersCount: 'К-сть чеків',
  menuItmesCount: 'К-сть проданих позицій',
  additionalsCount: 'Всього додатків',
  avgReceipt: 'Середній чек',
  avgMenuItemsPerOrder: 'Середня к-сть позицій в чеку'
};

export const formatSumValues = (key, value) => {
  let valueFormatted = value ? value : 0;
  if (value && (key === 'avgReceipt' || key === 'avgMenuItemsPerOrder' || key === 'ordersSum')) {
    valueFormatted = round(value, 2) ? round(value, 2) : 0;
  }
  return valueFormatted;
};

const StatisticSummary = () => {
  const pickIcon = key => {
    switch (key) {
      case 'ordersSum':
        return <WalletOutlined />;
      case 'avgReceipt':
        return <ShoppingCartOutlined />;
      case 'ordersCount':
        return <CalculatorOutlined />;
      case 'menuItmesCount':
        return <ContainerOutlined />;
      case 'additionalsCount':
        return <PlusCircleOutlined />;
      default:
        return <OrderedListOutlined />;
    }
  };

  const renderSummary = () => {
    const list = [] as any[];
    Object.entries(statisticStore.statisticSummary).forEach(([key, value]) => {
      const measure = key === 'avgReceipt' || key === 'ordersSum' ? ' грн' : ' од';
      list.push({
        key,
        measure,
        title: tableTitles[key],
        value: formatSumValues(key, value),
        prefixIcon: pickIcon(key)
      });
    });
    return list.map((item, itemIndex) => (
      <Card key={item.key} className={styles.wrapper + ' ' + styles[item.key]}>
        <Statistic title={item.title} value={item.value} precision={2} prefix={item.prefixIcon} suffix={item.measure} />
      </Card>
    ));
  };
  return <div className={styles.wrap}>{renderSummary()}</div>;
};

export default observer(StatisticSummary);
