import * as React from 'react';
import { Col, Layout, Row } from 'antd';

import { Header, Footer } from '../';
import styles from './appLayout.module.scss';
import { AuthComponent } from '../../../authentication/accessComponents';

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  return (
    <Layout>
      <AuthComponent>
        <Header />
      </AuthComponent>
      <Layout className={styles.mainLayout}>
        <Row className={styles.mainContent}>
          <Col span={24}>{children}</Col>
        </Row>
      </Layout>
      <Footer />
    </Layout>
  );
};

export default AppLayout;
