import * as React from 'react';

import PageArea from '../../components/PageArea';
import BankInstructionForm from '../../components/BankInstructionForm';

const BankInstructionPage = () => {
  return (
    <PageArea
      content={<BankInstructionForm />}
      pageName="Інструкції до терміналу"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/bank-instructions' },
        { name: 'Банківська інструкція', url: '/screens/bank-instructions' }
      ]}
    />
  );
};

export default BankInstructionPage;
