import React from 'react';
import { Button, Form } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';

import i18n from '../../../../i18n';

import { categoryStore } from '../../stores';

import MenuLang from '../../../common/components/MenuLang';
import InputField from '../../../common/components/InputField';
import OptionButton from '../../../common/components/OptionsButton';
import { UpdateCategoryValues } from '../../types';
import styles from './create.module.scss';
import './create.global.scss';

const Create = (props: FormikProps<UpdateCategoryValues>) => {
  const { handleSubmit, setFieldTouched, touched, errors, setFieldValue } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <div className="create-category-wrap">
        <div className={styles.wrapTopField}>
          <div className={styles.wrapWithoutImg}>
            <div className={styles.wrapLangMenu}>
              <div className={styles.langMenu}>
                <MenuLang />
                <OptionButton />
              </div>
              <div className={styles.buttonWrap}>
                <Button type="primary" htmlType="submit">
                  {i18n.t('button')}
                </Button>
              </div>
            </div>
            <InputField
              title={'Назва категорії'}
              name="name"
              defaultValue={''}
              onBlur={() => setFieldTouched('name')}
              placeholder={'Мінімум 2, максимум 60 символів'}
              errorMessage={touched.name && errors.name}
              onChange={event => setFieldValue('name', event.target.value)}
            />
            <InputField
              title={'Опис категорії'}
              name="description"
              defaultValue={''}
              onBlur={() => setFieldTouched('description')}
              placeholder={'Мінімум 2, максимум 500 символів'}
              errorMessage={touched.description && errors.description}
              onChange={event => setFieldValue('description', event.target.value)}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

const CreateWithFormik = withFormik<any, UpdateCategoryValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: '',
    description: ''
  }),
  handleSubmit: values => {
    categoryStore.create(values);
  },
  validationSchema: yup.object().shape<UpdateCategoryValues>({
    name: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(60, 'Назва повинна мати не більше 60 символів')
      .required('Це поле не може бути порожнім!'),
    description: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(500, 'Назва повинна мати не більше 500 символів')
      .required('Це поле не може бути порожнім!')
  }),
  displayName: 'Create'
})(observer(Create));

export default CreateWithFormik;
