import { notification, message } from 'antd';
import axios from 'axios';

import Storage from '../../common/utils/Storage';

import settings from '../../../settings';

import { UpdateCategoryValues, Category } from '../types';
import { categoryStore } from '../stores';

class Categories {
  public loadCategory = async (categoryId: number, withoutAcceptLanguage?: boolean): Promise<Category> => {
    try {
      if (!withoutAcceptLanguage) {
        const { data } = await axios.get(`${settings.domain}/api/admin/category/${categoryId}`, {
          headers: { 'Accept-Language': Storage('sessionStorage').get('language') || 'UK' }
        });
        return data.data;
      } else {
        const { data } = await axios.get(`${settings.domain}/api/admin/category/${categoryId}`);
        return data.data;
      }
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити інформацію про категорію. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public loadCategories = async () => {
    try {
      const { data } = await axios.get(
        `${settings.domain}/api/admin/category/by-menu/${Storage('sessionStorage').get('menu').id}`
      );
      return data.data;
    } catch (error) {
      if (!Storage('sessionStorage').get('menu').id) {
        notification.warning({
          message: 'Оберіть локацію з меню.',
          description: 'Не вдалося завантажити список категорій. Оберіть будь-ласка в панелі локацію з меню.'
        });
      } else {
        notification.error({
          message: 'Серверна помилка.',
          description: 'Не вдалося завантажити список категорій до меню. Спробуйте пізніше.'
        });
        throw error;
      }
    }
  };

  public create = async (createValues: UpdateCategoryValues) => {
    const categoryData = {
      menuItems: [],
      parentGroup: null,
      externalId: null,
      tax: 0,
      url: null,
      order: 0,
      ...createValues
    };

    try {
      const { data } = await axios.post(
        `${settings.domain}/api/admin/category/${Storage('sessionStorage').get('menu').id}`,
        categoryData
      );
      if (data.code !== 200) {
        notification.error({
          message: 'Не вдалося створити категорію.',
          description: 'Категорія з такою назвою вже існує. Спробуйте іншу назву.'
        });
        return data.code;
      } else {
        message.success('Категорія успішно створена!', 5);
        return data;
      }
    } catch (error) {
      if (!Storage('sessionStorage').get('menu').id) {
        notification.warning({
          message: 'Оберіть локацію з меню.',
          description: 'Не вдалося створити категорію. Оберіть будь-ласка в панелі локацію з меню.'
        });
      } else {
        notification.error({
          message: 'Серверна помилка.',
          description: 'Не вдалося створити категорію. Спробуйте пізніше.'
        });
        throw error;
      }
    }
  };

  public update = async (categoryId: number, categoryValues: UpdateCategoryValues) => {
    try {
      const { data } = await axios.put(`${settings.domain}/api/admin/category`, {
        ...categoryValues, id:categoryId
      });
      message.success('Категорія успішно оновлена!');
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося редагувати категорію. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public delete = async (categoryId: number) => {
    try {
      if (categoryStore.category && categoryStore.category.menuItems) {
        notification.warning({
          message: 'Увага!',
          description: 'Неможливо видалити категорію, оскільки в ній містяться страви!'
        });
      } else {
        const { data } = await axios.delete(`${settings.domain}/api/admin/category/${categoryId}`);
        message.success('Категорія успішно видалена!');
        return data.message;
      }
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося видалити категорію. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public updateWithoutRedirect = async (categoryId: number, updateObject: Category) => {
    try {
      const { data } = await axios.put(`${settings.domain}/api/admin/category`, {...updateObject, id:categoryId});
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося змінити пріорітет категорії. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public updateLanguage = async (categoryId: number, categoryValues: UpdateCategoryValues, langType: string) => {
    try {
      const { data } = await axios.post(
        `${settings.domain}/api/database/translation-both?entityId=${categoryId}&name=${categoryValues.name}&description=${categoryValues.description}&entity=CATEGORY`,
        {},
        { headers: { 'Accept-Language': langType } }
      );
      message.success('Категорія успішно оновлена!');
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося редагувати категорію. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public addToArhiv = async (categoryId: number, hideFrom: number | null, hideTo: number | null) => {
    try {
      const { data } = await axios.post(
        `${settings.domain}/api/database/archive/to?entityId=${categoryId}&entity=CATEGORY&hideFrom=${hideFrom}&hideTo=${hideTo}`
      );
      message.success('Категорія успішно додана до архіву!');
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося архівувати категорію. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public extractFromArchive = async (categoryId: number) => {
    try {
      await axios.post(`${settings.domain}/api/database/archive/from?entityId=${categoryId}&entity=CATEGORY`);
      message.success('Категорія успішно відновлена!');
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося відновити категорію. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public addSubCategory = async (categoryValues: UpdateCategoryValues, subCategoriesId: number[]) => {
    try {
      await axios.post(
        `${settings.domain}/api/admin/category/sub?rootCategoryId=${categoryValues.rootCategoryId}`,
        subCategoriesId
      );
      message.success('Категорію успішно перенесено як підкатегорію!');
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося додати підкатегорію. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new Categories();
