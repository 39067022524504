import * as ReactDOM from 'react-dom';
import * as React from 'react';
import 'core-js';

import './modules/common/globalStyles/index';
// import './modules/common';
// import './utils/globalErrorHandlers';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
