import * as React from 'react';

import styles from './materialShadow.module.scss';

interface MaterialShadowProps {
  children: React.ReactNode;
}

const MaterialShadow = ({ children, className, ...props }: MaterialShadowProps & React.HTMLProps<HTMLDivElement>) => {
  return (
    <div {...props} className={`${className ? className : ''} ${styles.materialShadow}`}>
      {children}
    </div>
  );
};

export default MaterialShadow;
