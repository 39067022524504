import { observable, action, runInAction } from 'mobx';

import history from '../../../history';
import { categoriesService } from '../services';
import { Category, UpdateCategoryValues } from '../types';
import { changeArrayElementOrder, sortOrder } from '../../common/utils';
import { Dish } from '../../dish/types';
import { dishesService } from '../../dish/services';

class CategoryStore {
  @observable
  public category: Category | null = null;
  @observable
  public categories: Category[] | null = null;
  @observable
  public loading = true;
  @observable
  public updateCategoryOrderLoading = false;
  @observable
  public updateDishOrderLoading = false;
  @observable
  public error: Error | null = null;

  @action public async load(categoryId: number, withoutAcceptLanguage?: boolean) {
    this.loading = true;

    try {
      if (withoutAcceptLanguage) {
        const category = await categoriesService.loadCategory(categoryId, withoutAcceptLanguage);
        runInAction(() => {
          this.category = category;
          this.category.menuItems = sortOrder(category.menuItems, 'order');
        });
      } else {
        const category = await categoriesService.loadCategory(categoryId);
        runInAction(() => {
          this.category = category;
          this.category.menuItems = sortOrder(category.menuItems, 'order');
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async loadList() {
    this.loading = true;

    try {
      const categories = await categoriesService.loadCategories();
      const childCategories = categories.filter((category: any) => !!category.parentGroup);
      const parentCategories = categories
        .filter((category: any) => !category.parentGroup)
        .map((category: any) => {
          return {
            ...category,
            subCategories: childCategories.filter((childCategory: any) => childCategory.parentGroup === category.rootId)
          };
        });
      runInAction(() => {
        this.categories = this.categories = sortOrder(parentCategories, 'order');
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async loadListWithSubcategories() {
    this.loading = true;

    try {
      const categories = await categoriesService.loadCategories();
      const withoutRootId = categories.filter((category: any) => !category.rootId);
      runInAction(() => {
        this.categories = this.categories = sortOrder(withoutRootId, 'order');
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async create(categoryValues: UpdateCategoryValues) {
    this.loading = true;

    try {
      const category = await categoriesService.create(categoryValues);
      if (category === 208) {
        this.category = null;
      } else {
        runInAction(() => {
          this.category = category.data;
        });
        history.push(`/categories/edit/${category.data.id}`);
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async update(categoryValues: UpdateCategoryValues) {
    this.loading = true;
    try {
      if (this.category) {
        await categoriesService.update(this.category.id, categoryValues);
        const category = await categoriesService.loadCategory(this.category && this.category.id);
        runInAction(() => {
          this.category = category;
          this.category.menuItems = sortOrder(category.menuItems, 'order');
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async delete(categoryId: number) {
    this.loading = true;
    try {
      const response = await categoriesService.delete(categoryId);
      if (response === 'Category removed successfully') {
        history.push('/');
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async updateWithoutRedirect(categoryId: number, updateObject: Category) {
    this.loading = true;
    try {
      await categoriesService.updateWithoutRedirect(categoryId, updateObject);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async updateLanguage(categoryValues: UpdateCategoryValues, langType: string) {
    this.loading = true;
    try {
      if (this.category) {
        await categoriesService.updateLanguage(this.category.id, categoryValues, langType);
        const category = await categoriesService.loadCategory(this.category && this.category.id);
        runInAction(() => {
          this.category = category;
          this.category.menuItems = sortOrder(category.menuItems, 'order');
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async changeCategoryOrder(direction: 'UP' | 'DOWN', category: Category) {
    if (!this.categories) {
      return false;
    }

    try {
      this.updateCategoryOrderLoading = true;

      const newArreyOfCategories = changeArrayElementOrder(this.categories, category.id, direction);

      const activeCategory = newArreyOfCategories.find(tempCategory => tempCategory.id === category.id);
      const newCategory = newArreyOfCategories.find(tempCategory => tempCategory.order === category.order);

      await Promise.all([
        categoriesService.updateWithoutRedirect(activeCategory.id, activeCategory),
        categoriesService.updateWithoutRedirect(newCategory.id, newCategory)
      ]);

      runInAction(() => {
        this.categories = sortOrder(newArreyOfCategories, 'order');
      });
    } finally {
      runInAction(() => {
        this.updateCategoryOrderLoading = false;
      });
    }
  }

  @action public async changeDishOrder(direction: 'UP' | 'DOWN', dish: Dish) {
    if (!this.category || !this.category.menuItems) {
      return false;
    }

    try {
      this.updateDishOrderLoading = true;

      const newMenuItems = changeArrayElementOrder(this.category && this.category.menuItems, dish.id, direction);

      const activeDish = newMenuItems.find(tempDish => tempDish.id === dish.id);
      const newDish = newMenuItems.find(tempDish => tempDish.order === dish.order);

      await Promise.all([
        dishesService.updateWithoutRedirect(activeDish.id, activeDish),
        dishesService.updateWithoutRedirect(newDish.id, newDish)
      ]);

      // TODO: fix this
      runInAction(() => {
        this.category = { ...this.category, menuItems: sortOrder(newMenuItems, 'order') } as any;
      });
    } finally {
      runInAction(() => {
        this.updateDishOrderLoading = false;
      });
    }
  }

  @action public async addToArhiv(hideFrom: number | null, hideTo: number | null) {
    this.loading = true;
    try {
      if (this.category) {
        const { data } = await categoriesService.addToArhiv(this.category.id, hideFrom, hideTo);
        const category = { ...this.category, archived: data.archived };
        runInAction(() => {
          this.category = category;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async extractFromArchive() {
    this.loading = true;
    try {
      if (this.category) {
        await categoriesService.extractFromArchive(this.category.id);
        const category = { ...this.category, archived: false };
        runInAction(() => {
          this.category = category;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async addSubCategories(categoryValues: UpdateCategoryValues) {
    this.loading = true;

    const arrSubCategoriesId: number[] = [];

    if (this.category && this.categories && categoryValues.rootCategoryId) {
      arrSubCategoriesId.push(this.category.id);
      const rootCategory = this.categories.filter(category => category.id === categoryValues.rootCategoryId)[0];
      if (rootCategory.subCategories) {
        rootCategory.subCategories.forEach((subCategory: Category) => arrSubCategoriesId.push(subCategory.id));
      }
    }

    try {
      if (this.category) {
        await categoriesService.addSubCategory(categoryValues, arrSubCategoriesId);
        const category = { ...this.category, archived: false };
        runInAction(() => {
          this.category = category;
        });
        history.push('/');
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new CategoryStore();
