import * as React from 'react';

import PageArea from '../../components/PageArea';
import GetOrderTableForm from '../../components/GetOrderTableForm';

const GetOrderTablePage = () => {
  return (
    <PageArea
      content={<GetOrderTableForm />}
      pageName="Отримання замовленя за столом"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/table-order' },
        { name: 'Отримання замовленя', url: '/screens/table-order' }
      ]}
    />
  );
};

export default GetOrderTablePage;
