import * as React from 'react';

import PageArea from '../../components/PageArea';
import TableSelectionForm from '../../components/TableSelectionForm';

const TableSelectionPage = () => {
  return (
    <PageArea
      content={<TableSelectionForm />}
      pageName="Екран Вибору столика"
      breadLinks={[
        { name: 'Вигляд', url: '/screens/table-selection' },
        { name: 'Вибір солику', url: '/screens/table-selection' }
      ]}
    />
  );
};

export default TableSelectionPage;
