import React from 'react';
import { Icon, Input } from 'antd';
import { InputProps } from 'antd/lib/input';

import styles from './inputField.module.scss';
import { InputFieldTitle } from '../';

type InputFieldProps = {
  title?: string;
  iconType?: string;
  errorMessage?: string | boolean;
  suffix?: React.ReactNode;
} & InputProps;

const InputField = ({ title, required, iconType, errorMessage, suffix, ...props }: InputFieldProps) => {
  return (
    <div className={styles.wrapper}>
      <InputFieldTitle required={required} title={title || ''} errorMessage={errorMessage}>
        <Input
          {...props}
          size={'large'}
          suffix={errorMessage ? <Icon className={styles.suffixIco} type="warning" /> : suffix ? suffix : <span />}
        />
      </InputFieldTitle>
    </div>
  );
};

export default InputField;
