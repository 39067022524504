import { observable, action, runInAction } from 'mobx';

import { authStore } from '.';
import { usersService } from '../services';
import { User } from '../types';

class UserStore {
  @observable
  public user: User | null = null;
  @observable
  public loading: boolean = false;
  @observable
  public error: Error | null = null;

  @action
  public getUser = async (userId: number) => {
    this.loading = true;

    try {
      if (authStore.entity) {
        const user = await usersService.getUser(userId);

        runInAction(() => {
          this.user = user;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}

export default new UserStore();
