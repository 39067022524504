import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Radio, Table } from 'antd';
import Title from 'antd/lib/typography/Title';

import styles from '../StatisticCharts/StatisticCharts.module.scss';
import { statisticStore } from '../../stores';
import {
  getRangeOfDates,
  prepareDatesArray,
  prepareDatesLegendTitles,
  prepareHoursArray,
  prepareHoursLegendTitles,
  updateHoursArray
} from '../../services/ChartsPrepare';
import { IntervalChartHorizontalBarBizcharts } from '../StatisticChartsCommon/IntervalChartHorizontalBarBizcharts';
import LineChartBizcharts from '../StatisticChartsCommon/LineChartBizcharts';

const StatisticChartsDynamics = () => {
  const [dayType, setDayType] = useState('price');
  const [monthType, setMonthType] = useState('price');
  const [yearType, setYearType] = useState('price');

  const locations = [] as any[];
  const locationsByMonth = [] as any[];
  const locationsByYear = [] as any[];

  let byDatesHours = [] as any[];
  let byHoursRange = prepareHoursArray();

  const prepareDataForDatesCharts = () =>
    statisticStore?.statistic?.forEach((order: any) => {
      byDatesHours = prepareDatesArray(order.orderDate, order.orderPrice, byDatesHours);
    });

  const prepareDataForHoursCharts = () =>
    statisticStore?.statistic?.forEach((order: any) => {
      byHoursRange = updateHoursArray(order.orderDate, order.orderPrice, byHoursRange);
    });

  const monthsColumns = [
    {
      title: 'Локація',
      dataIndex: 'title',
      key: 'title',
      align: 'left' as 'left'
    },
    {
      title: 'Сума',
      dataIndex: 'total',
      key: 'total',
      align: 'center' as 'center',
      width: '100px',
      sorter: (a: any, b: any) => a.total - b.total
    }
  ] as any[];

  const yearsColumns = [
    {
      title: 'Локація',
      dataIndex: 'title',
      key: 'title',
      align: 'left' as 'left'
    },
    {
      title: 'Сума',
      dataIndex: 'price',
      key: 'price',
      align: 'center' as 'center',
      width: '100px',
      sorter: (a: any, b: any) => a.total - b.total
    }
  ] as any[];

  const daysColumns = [
    {
      title: 'Локація',
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
      align: 'left' as 'left'
    },
    {
      title: 'Сума',
      dataIndex: 'total',
      key: 'total',
      align: 'center' as 'center',
      sorter: (a: any, b: any) => a.total - b.total
    }
  ] as any[];

  const getDaysDataSource = () => {
    if (statisticStore.loading === false && statisticStore && statisticStore.statistic) {
      const table = [] as any[];
      locations.map((record: any, idx) => {
        const location = {
          key: idx + 1,
          title: record.title,
          total: record.count,
          price: record.price
        };
        record.days.forEach(day => (location[day.day] = (dayType === 'price' ? ' ₴ ' : '') + day[dayType]));
        table.push(location);
      });
      return table;
    }
  };

  const prepDaysTable = () => {
    if (statisticStore?.statisticValues && statisticStore.loading === false) {
      const begin = moment(statisticStore.statisticValues.startDateTime);
      const end = moment(statisticStore.statisticValues.endDateTime);

      const daysRange = getRangeOfDates(begin, end, 'day');
      daysRange.map(day =>
        daysColumns.push({
          key: day.format('DD.MM.YYYY'),
          title: day.format('DD.MM.YYYY'),
          dataIndex: day.format('DD.MM.YYYY'),
          align: 'center' as 'center'
        })
      );
      daysColumns.push();

      statisticStore?.statistic?.forEach((order: any) => {
        if (!locations[order.locationId]) {
          locations[order.locationId] = {
            title: order.locationName,
            price: 0,
            count: 0,
            days: daysRange.map(day => {
              return { day: day.format('DD.MM.YYYY'), count: 0, price: 0 };
            })
          };
        }

        const orderDate = moment(new Date(order.orderDate)).format('DD.MM.YYYY');
        locations[order.locationId].days[
          locations[order.locationId].days.findIndex(item => item.day === orderDate)
        ].price += order.orderPrice;
        locations[order.locationId].days[
          locations[order.locationId].days.findIndex(item => item.day === orderDate)
        ].count += 1;

        locations[order.locationId].price += order.orderPrice;
        locations[order.locationId].count += 1;
      });
      return (
        <>
          <Title level={3} style={{ textAlign: 'center' }}>
            Динаміка продажів по дням
          </Title>
          <Radio.Group className={styles.switchButtons} value={dayType} onChange={e => setDayType(e.target.value)}>
            <Radio.Button value="price">Оборот</Radio.Button>
            <Radio.Button value="count">Kількість чеків</Radio.Button>
          </Radio.Group>
          <Table
            className={styles.statisticTableDays}
            locale={{ emptyText: 'Немає даних' }}
            dataSource={getDaysDataSource()}
            columns={daysColumns}
            scroll={{ x: 1000 }}
            pagination={{
              className: styles.tablePagination,
              position: 'top',
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '30', '40', '50', '60', '100'],
              showSizeChanger: true
            }}
          />
        </>
      );
    }
  };
  const prepMonthsTable = () => {
    if (statisticStore?.statisticValues && statisticStore.loading === false) {
      const begin = moment(statisticStore.statisticValues.startDateTime);
      const end = moment(statisticStore.statisticValues.endDateTime);

      const monthsRange = getRangeOfDates(begin, end, 'month');
      monthsRange.map(day =>
        monthsColumns.push({
          title: day.format('MM.YYYY'),
          dataIndex: day.format('MM.YYYY'),
          align: 'center' as 'center'
        })
      );

      statisticStore?.statistic?.forEach((order: any) => {
        if (!locationsByMonth[order.locationId]) {
          locationsByMonth[order.locationId] = {
            title: order.locationName,
            price: 0,
            count: 0,
            months: monthsRange.map(day => {
              return { month: day.format('MM.YYYY'), count: 0, price: 0 };
            })
          };
        }

        const orderDate = moment(new Date(order.orderDate)).format('MM.YYYY');
        locationsByMonth[order.locationId].months[
          locationsByMonth[order.locationId].months.findIndex(item => item.month === orderDate)
        ].price += order.orderPrice;
        locationsByMonth[order.locationId].months[
          locationsByMonth[order.locationId].months.findIndex(item => item.month === orderDate)
        ].count += 1;

        locationsByMonth[order.locationId].price += order.orderPrice;
        locationsByMonth[order.locationId].count += 1;
      });
      return (
        <>
          <Title level={3} style={{ textAlign: 'center' }}>
            Динаміка продажів по місяцям
          </Title>
          <Radio.Group className={styles.switchButtons} value={monthType} onChange={e => setMonthType(e.target.value)}>
            <Radio.Button value="price">Оборот</Radio.Button>
            <Radio.Button value="count">Kількість чеків</Radio.Button>
          </Radio.Group>
          <Table
            className={styles.statisticTableMonths}
            locale={{ emptyText: 'Немає даних' }}
            dataSource={getMonthsDataSource()}
            columns={monthsColumns}
            pagination={{
              className: styles.tablePagination,
              position: 'top',
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '30', '40', '50', '60', '100'],
              showSizeChanger: true
            }}
          />
        </>
      );
    }
  };

  const prepYearsTable = () => {
    if (statisticStore?.statisticValues && statisticStore.loading === false) {
      const begin = moment(statisticStore.statisticValues.startDateTime);
      const end = moment(statisticStore.statisticValues.endDateTime);

      const yearsRange = getRangeOfDates(begin, end, 'year');
      yearsRange.map(day =>
        yearsColumns.push({
          title: day.format('YYYY'),
          dataIndex: day.format('YYYY'),
          align: 'center' as 'center',
          width: '100px'
        })
      );

      statisticStore?.statistic?.forEach((order: any) => {
        if (!locationsByYear[order.locationId]) {
          locationsByYear[order.locationId] = {
            title: order.locationName,
            price: 0,
            count: 0,
            years: yearsRange.map(day => {
              return { year: day.format('YYYY'), count: 0, price: 0 };
            })
          };
        }

        const orderDate = moment(new Date(order.orderDate)).format('YYYY');
        locationsByYear[order.locationId].years[
          locationsByYear[order.locationId].years.findIndex(item => item.year === orderDate)
        ].price += order.orderPrice;
        locationsByYear[order.locationId].years[
          locationsByYear[order.locationId].years.findIndex(item => item.year === orderDate)
        ].count += 1;

        locationsByYear[order.locationId].price += order.orderPrice;
        locationsByYear[order.locationId].count += 1;
      });
      return (
        <>
          <Title level={3} style={{ textAlign: 'center' }}>
            Динаміка продажів по рокам
          </Title>
          <Radio.Group className={styles.switchButtons} value={yearType} onChange={e => setYearType(e.target.value)}>
            <Radio.Button value="price">Оборот</Radio.Button>
            <Radio.Button value="count">Kількість чеків</Radio.Button>
          </Radio.Group>
          <Table
            className={styles.statisticTableYearly}
            locale={{ emptyText: 'Немає даних' }}
            dataSource={getYearsDataSource()}
            columns={yearsColumns}
            pagination={{
              className: styles.tablePagination,
              position: 'top',
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '30', '40', '50', '60', '100'],
              showSizeChanger: true
            }}
          />
        </>
      );
    }
  };

  const getMonthsDataSource = () => {
    if (statisticStore.loading === false && statisticStore && statisticStore.statistic) {
      const table = [] as any[];
      locationsByMonth.map((record: any, idx) => {
        const location = {
          key: idx + 1,
          title: record.title,
          total: record.count,
          price: record.price
        };
        record.months.forEach(
          month => (location[month.month] = (monthType === 'price' ? ' ₴ ' : '') + month[monthType])
        );
        table.push(location);
      });
      return table;
    }
  };

  const getYearsDataSource = () => {
    if (statisticStore.loading === false && statisticStore && statisticStore.statistic) {
      const table = [] as any[];
      locationsByYear.map((record: any, idx) => {
        const location = {
          key: idx + 1,
          title: record.title,
          total: record.count,
          price: record.price
        };
        record.years.forEach(day => (location[day.year] = (yearType === 'price' ? ' ₴ ' : '') + day[yearType]));
        table.push(location);
      });
      return table;
    }
  };

  const renderByDaysCharts = () => {
    prepareDataForDatesCharts();
    return (
      <section className={styles.oneLineWithTwo}>
        <section className={styles.chartWrapper}>
          <h2>Кількість замовлень по дням</h2>
          <IntervalChartHorizontalBarBizcharts
            list={prepareDatesLegendTitles(byDatesHours)}
            config={{
              count: 'ordersCount',
              color: 'ordersLegendTitle',
              sort: 'day'
            }}
          />
        </section>
        <section className={styles.chartWrapper}>
          <h2>Вартість замовлень по дням, ₴</h2>
          <IntervalChartHorizontalBarBizcharts
            list={byDatesHours}
            config={{
              count: 'priceCount',
              color: 'ordersLegendTitle',
              sort: 'day'
            }}
          />
        </section>
      </section>
    );
  };
  const renderByHoursCharts = () => {
    prepareDataForHoursCharts();
    return (
      <section className={styles.oneLineWithTwo}>
        <section className={styles.chartWrapper}>
          <h2>Кількість замовлень по годинам</h2>
          <IntervalChartHorizontalBarBizcharts
            list={prepareHoursLegendTitles(byHoursRange)}
            config={{
              count: 'ordersCount',
              color: 'ordersLegendTitle'
            }}
          />
        </section>
        <section className={styles.chartWrapper}>
          <h2>Вартість замовлень по годинам, ₴</h2>
          <IntervalChartHorizontalBarBizcharts
            list={byHoursRange}
            config={{
              count: 'priceCount',
              color: 'ordersLegendTitle'
            }}
          />
        </section>
      </section>
    );
  };
  const renderLinearByDays = () => {
    const newLocations = [] as any[];
    locations.forEach(location => location && newLocations.push(location));
    return (
      locations &&
      locations.length > 0 &&
      newLocations.map(loc => (
        <section className={styles.oneLineWithOne} key={loc.title + '-days'}>
          <section className={styles.chartWrapper}>
            <h2>
              {dayType === 'price' ? 'Вартість ' : 'Кількість '} замовлень по дням, {loc.title}
            </h2>
            <LineChartBizcharts list={loc.days} config={{ key: 'day', count: dayType }} />
          </section>
        </section>
      ))
    );
  };

  const renderLinearByMonths = () => {
    const newLocations = [] as any[];
    locationsByMonth.forEach(location => location && newLocations.push(location));
    return (
      locations &&
      locations.length > 0 &&
      newLocations.map(loc => (
        <section className={styles.oneLineWithOne} key={loc.title + '-months'}>
          <section className={styles.chartWrapper}>
            <h2>
              {monthType === 'price' ? 'Вартість ' : 'Кількість '} замовлень по місяцям, {loc.title}
            </h2>
            <LineChartBizcharts list={loc.months} config={{ key: 'month', count: monthType }} />
          </section>
        </section>
      ))
    );
  };
  const renderLinearByYears = () => {
    const newLocations = [] as any[];
    locationsByYear.forEach(location => location && newLocations.push(location));
    return (
      locations &&
      locations.length > 0 &&
      newLocations.map(loc => (
        <section className={styles.oneLineWithOne} key={loc.title + '-years'}>
          <section className={styles.chartWrapper}>
            <h2>
              {yearType === 'price' ? 'Вартість ' : 'Кількість '} замовлень по роках, {loc.title}
            </h2>
            <LineChartBizcharts list={loc.years} config={{ key: 'year', count: yearType }} />
          </section>
        </section>
      ))
    );
  };
  return (
    <>
      {prepDaysTable()}
      <section className={styles.chartsWrapper}>{renderLinearByDays()}</section>
      <section className={styles.chartsWrapper}>
        {renderByDaysCharts()}
        {renderByHoursCharts()}
      </section>
      {prepMonthsTable()}
      <section className={styles.chartsWrapper}>{renderLinearByMonths()}</section>
      {prepYearsTable()}
      <section className={styles.chartsWrapper}>{renderLinearByYears()}</section>
    </>
  );
};

export default observer(StatisticChartsDynamics);
