import * as React from 'react';

import PageArea from '../../components/PageArea';
import Create from '../../components/Create';

const CreateCategoryPage = () => {
  return (
    <PageArea
      content={<Create />}
      pageName="Створення нової категорії"
      breadLinks={[{ name: 'Меню', url: '/' }, { name: '# Створити категорію', url: '/category/edit' }]}
    />
  );
};

export default CreateCategoryPage;
