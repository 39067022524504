import { observable, action, runInAction } from 'mobx';

import { StatisticCategory, StatisticSummary, StatisticValues } from '../types';
import { statisticsService } from '../services';

class StatisticStore {
  @observable
  public statistic: StatisticCategory[] | null = null;
  @observable
  public statisticSummary: StatisticSummary | {} = {
    ordersSum: 0,
    ordersCount: 0,
    menuItmesCount: 0,
    additionalsCount: 0,
    avgReceipt: 0,
    avgMenuItemsPerOrder: 0
  };
  @observable
  public loading = false;
  @observable
  public error: Error | null = null;
  @observable
  public statisticValues;

  @action
  public load = async (statisticValues: StatisticValues) => {
    this.loading = true;
    this.statisticValues = statisticValues;

    try {
      const statistic = await statisticsService.load(statisticValues);

      runInAction(() => {
        this.statistic = statistic.orderDtos;
        this.statisticSummary = {
          ordersSum: statistic.ordersSum,
          ordersCount: statistic.ordersCount,
          menuItmesCount: statistic.menuItmesCount,
          additionalsCount: statistic.additionalsCount,
          avgReceipt: statistic.avgReceipt,
          avgMenuItemsPerOrder: statistic.avgMenuItemsPerOrder
        };
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}

export default new StatisticStore();
