import axios from 'axios';
import { notification } from 'antd';

import settings from '../../../settings';

class Images {
  public getImageUrlByPath = async (storagePath: string) => {
    try {
      const { data } = await axios.post(
        `${settings.domain}/api/admin/image/storage/download?storagePath=${storagePath || ''}`
      );
      return data;
    } catch (error) {
      return { code: 0, message: null };
    }
  };
  public uploadScreenLogo = async (file: FormData) => {
    try {
      const menuInfo = JSON.parse(sessionStorage.getItem('menu') || '');
      const {
        data
      } = await axios.post(
        `${settings.domain}/api/admin/image/storage/upload-screens/${menuInfo.organizationId}/LOGO/IMAGE`,
        file,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося завантажити зображення. Спробуйте пізніше.'
      });
      throw error;
    }
  };
  public uploadScreenBackground = async (file: FormData, contentType: string, screenType = 'start') => {
    try {
      const menuInfo = JSON.parse(sessionStorage.getItem('menu') || '');
      const {
        data
      } = await axios.post(
        `${settings.domain}/api/admin/image/storage/upload-screens/${menuInfo.organizationId}/BACKGROUND/${contentType}?screenType=${screenType}`,
        file,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося завантажити зображення. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new Images();
